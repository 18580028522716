//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { useTheme, makeStyles, Typography, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import GlobalContext from '../../../GlobalContext';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import gql from 'graphql-tag';

import { useTools } from '../../../hooks/useTools';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function MoveOffer({ move, rerenderParent }) {
  const history = useHistory();
  const ctx = React.useContext(GlobalContext);
  const theme = useTheme();
  const cls = useStyles();

  const { getFormattedVehicleFromMove, formatUSD } = useTools();

  const getMoveColor = move => {
    if (move.consumer_type === `customer` || move.consumer_type === `loaner`) return theme.palette.concierge.main;
    return theme.palette.ops.main;
  };

  const getMoveIcon = move => {
    if (move.consumer_type === `customer`) return `person`;
    if (move.consumer_type === `loaner`) return `emoji_transportation`;
    return `drive_eta`;
  };

  const acceptMove = async () => {
    const variables = {
      move_id: move.id,
      driver_status: 'accepted',
    };

    log && console.log(`acceptMove - variables:`, variables);

    try {
      const res = await ctx.apolloClient.mutate({
        mutation: UPDATE_DRIVER_STATUS,
        variables: variables,
      });

      const returnedDriverStatus = getPropValue(res, `data.update_moves_by_pk.driver_status`);
      if (returnedDriverStatus !== 'accepted') {
        throw new Error(`Failed to accept the move.`);
      }
    } catch (err) {
      console.error(`Error accepting the move:`, err);
      toast.error(`Failed to accept the move. Please contact support. ${err.message}}`);
    }

    //if (rerenderParent) rerenderParent();
    history.push(`/moves/${move.id}`);
  };

  const declineMove = async () => {
    const variables = {
      move_id: move.id,
      driver_status: 'declined',
    };

    log && console.log(`declineMove - variables:`, variables);

    try {
      const res = await ctx.apolloClient.mutate({
        mutation: UPDATE_DRIVER_STATUS,
        variables: variables,
      });

      const returnedDriverStatus = getPropValue(res, `data.update_moves_by_pk.driver_status`);
      if (returnedDriverStatus !== 'declined') {
        throw new Error(`Failed to decline the move.`);
      }
    } catch (err) {
      console.error(`Error declining the move:`, err);
      toast.error(`Failed to decline the move. Please contact support. ${err.message}}`);
    }

    //if (rerenderParent) rerenderParent();
    history.push(`/moves`);
  };

  return (
    <div className={cls.move}>
      <div className={cls.detailsLeft}>
        <div className={cls.flex}>
          <Icon className={cls.icon} style={{ color: getMoveColor(move) }}>
            {getMoveIcon(move)}
          </Icon>
          <Typography className={cls.moveIdTxt}>#{move.id}</Typography>
        </div>

        <Spacer size='xs' />

        <Typography className={cls.vehicleTxt}>{getFormattedVehicleFromMove(move)}</Typography>

        <Spacer size='xs' />

        <Typography className={cls.locationTxt}>{getPropValue(move, `lane.pickup.name`)}</Typography>
        <Typography className={cls.locationTxt}>to {getPropValue(move, `lane.delivery.name`)}</Typography>

        <Spacer size='xs' />

        <div className={cls.status}>
          <Typography className={cls.statusTxt}>{move.status}</Typography>
        </div>
      </div>

      <div className={cls.detailsRight}>
        <Typography className={cls.timeTxt} style={{ color: getMoveColor(move) }}>
          {dayjs(move.pickup_time).format(`MMM D h:mm A`)}
        </Typography>

        <Spacer />

        <Typography className={cls.additionalTxt}>{getPropValue(move, `lane.distance_miles`) || 0} mi</Typography>

        <Typography className={cls.additionalTxt}>
          {formatUSD(getPropValue(move, `totalAPPaymentAmounts.aggregate.sum.amount`))}
        </Typography>
      </div>

      <div className={cls.buttons}>
        <Button
          fullWidth
          disabled={false}
          loading={false}
          className={cls.button}
          color='secondary'
          size='small'
          onClick={declineMove}
        >
          DECLINE
        </Button>

        <Spacer />

        <Button
          fullWidth
          disabled={false}
          loading={false}
          className={cls.button}
          color='primary'
          size='small'
          onClick={acceptMove}
        >
          ACCEPT
        </Button>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  move: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    background: theme.palette.background.paper,
    height: 'calc(100vh - 100px)',
  },
  button: {
    borderRadius: 4,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },

  buttons: {
    marginTop: theme.spacing(1.5),
    flex: 12,
    marginRight: theme.spacing(1.5),
    textAlign: 'left',
    flexBasis: '100%',
    width: 0,
    alignSelf: 'flex-end',
  },

  detailsLeft: {
    flex: 9,
    marginRight: theme.spacing(1.5),
    textAlign: 'left',
  },
  detailsRight: {
    flex: 3,
    textAlign: 'right',
  },

  icon: {
    fontSize: 32,
    marginRight: theme.spacing(1),
  },
  moveIdTxt: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
  },

  vehicleTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 500,
  },

  locationTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  timeTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 600,
  },

  additionalTxt: {
    lineHeight: 1.5,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  status: {
    width: 'fit-content',
    padding: theme.spacing(0.5),
    background: theme.palette.text.disabled,
  },
  statusTxt: {
    marginTop: 1,
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.contrast,
  },
}));

const UPDATE_DRIVER_STATUS = gql`
  mutation updateMoveStatusById($move_id: bigint!, $driver_status: String!) {
    update_moves_by_pk(pk_columns: { id: $move_id }, _set: { driver_status: $driver_status }) {
      id
      driver_status
    }
  }
`;
