//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function PayoutsTableHead({ headers, order, orderBy, setOrder, setOrderBy }) {
  const cls = useStyles();

  // Handle sorting state
  const handleChangeSort = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map(header => {
          if (!header.hide)
            return (
              <TableCell
                key={header.id}
                align={header.align || `left`}
                padding={header.padding || `normal`}
                sortDirection={orderBy === header.id ? order : false}
              >
                {header.component || (
                  <TableSortLabel
                    className={cls.headTxt}
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : `asc`}
                    onClick={() => handleChangeSort(header.id)}
                  >
                    {header.label || `Label`}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          else return null;
        })}
      </TableRow>
    </TableHead>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  headTxt: {
    fontSize: 12,
    fontWeight: 600,
  },
}));
