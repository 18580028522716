import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuth } from 'firebase/auth'

const log = false;

//////////////////////// HOOK ////////////////////////

export function useWallet() {
  const {getIdTokenClaims, user} = useAuth0()
  const firebase = getAuth()

  const getAuthToken = async () => {
    try {
      let token
      if (user) {
        //Auth0
        const tokenClaims = await getIdTokenClaims()
        token = tokenClaims ? tokenClaims.__raw : null
      } else if (firebase.currentUser) {
        //Firebase
        token = await firebase.currentUser?.getIdToken()
      }
      return token
    } catch (err) {
      console.log('Error retrieving token claims', err)
    }
  };

  /** Create wallet and bank account using the form variables */
  const getWalletInfo = async (variables = {}) => {
    try {
      const token = await getAuthToken();
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/handleGetWalletInfo`,
        data: {
          ...variables,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          log && console.log(res);
          if (res.status === 200) {
            return { success: true, data: res.data.data };
          } else {
            console.error(`Failed to retrieve wallet info!`);
            return { success: false };
          }
        })
        .catch(err => {
          console.error(`Failed to retrieve wallet info:`, err);
          return { success: false };
        });
    } catch (err) {
      console.error(`Failed to retrieve wallet info - Global catch:`, err);
      return { success: false };
    }
  };

  /** Create wallet and bank account using the form variables */
  const runDirectDepositSetup = async (variables = {}) => {
    try {
      const token = await getAuthToken();
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/handleDirectDeposit`,
        data: {
          ...variables,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          log && console.log(res);
          if (res.status === 200) {
            return { success: true, data: res.data.data };
          } else {
            console.error(`Failed to create wallet!`);
            return { success: false };
          }
        })
        .catch(err => {
          console.error(`Failed to create wallet:`, err);
          return { success: false };
        });
    } catch (err) {
      console.error(`Failed to create wallet - Global catch:`, err);
      return { success: false };
    }
  };

  /** Remove the bank account associated with a driver */
  const removeBankAccount = async (variables = {}) => {
    try {
      const token = await getAuthToken();
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/handleRemoveBankAccount`,
        data: {
          ...variables,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          log && console.log(res);
          if (res.status === 200) {
            return { success: true, data: res.data.data };
          } else {
            console.error(`Failed to remove bank account!`);
            return { success: false };
          }
        })
        .catch(err => {
          console.error(`Failed to remove bank account:`, err);
          return { success: false };
        });
    } catch (err) {
      console.error(`Failed to remove bank account - Global catch:`, err);
      return { success: false };
    }
  };

  // Return hook logic
  return {
    getWalletInfo,
    runDirectDepositSetup,
    removeBankAccount,
  };
}
