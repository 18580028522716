import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Grid, Tabs, Tab } from '@material-ui/core';

let log = false;

export default function ScheduleHeader(props) {
  const cls = useStyles();

  const { tabValue, setTabValue } = props;

  const handleTabChange = (event, newValue) => {
    log && console.log('Tabvalue', newValue);
    setTabValue(newValue);
  };

  return (
    <Grid container justify='space-evenly' alignItems='center' style={{ width: '100%' }}>
      <Tabs
        className={cls.tabs}
        value={tabValue}
        indicatorColor='primary'
        textColor='primary'
        onChange={handleTabChange}
        aria-label='week tabs'
      >
        <Tab
          className={cls.tab}
          label={`Week ${moment().startOf('week').format('MM/DD')}`}
          value={moment().format('W') % 2}
        />
        <Tab
          className={cls.tab}
          label={`Week ${moment().startOf('week').add(1, 'week').format('MM/DD')}`}
          value={moment().add(1, 'week').format('W') % 2}
        />
      </Tabs>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  tab: {
    width: '40vw',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dropdown: {
    minWidth: 200,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));
