import React from 'react';
import moment from 'moment';
import { useTheme, makeStyles, TableContainer, Table, Icon, Typography, Chip } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import StaticAlert from '../../utils/StaticAlert';
import PayoutsTableHead from './PayoutsTableHead';
import PayoutsTableBody from './PayoutsTableBody';

const log = false;

/** Capitalize the first letter of the first word */
const capFirst = (str = null) => {
  if (str && typeof str === `string`) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

/** Get a cleansed monetary value from a number or string number */
const formatUSD = (value = null, options = {}) => {
  const { returnNull = false, removeSign = false, removeCommas = false, removeCents = false } = options;

  // Check for value and return null
  if (!value && returnNull) return null;

  // Remove spaces and commas so the value can be converted to a number
  // Build the cleansed monetary string from the numeric value
  const replacedValue = `${value}`.replace(/[,]/g, ``);
  const numValue = Number(replacedValue) || 0;
  let cleansedValue = '$' + numValue.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, `$1,`);

  // Additional options
  if (removeSign) cleansedValue = cleansedValue.replace(/[$]/g, ``);
  if (removeCommas) cleansedValue = cleansedValue.replace(/[,]/g, ``);
  if (removeCents) cleansedValue = cleansedValue.split(`.`)[0];

  // Return cleansed monetary value
  return cleansedValue;
};

/** Get a cleansed text string (remove underscores and cap all) */
const getReadableText = (str = null, nbsp = false) => {
  if (str) {
    let newStr = str.split(/[.\s_-]/g);
    newStr = newStr.map(val => capFirst(val).trim());
    return newStr.join(nbsp ? `\xa0` : ` `);
  }
  return str;
};

const defaultOrder = `desc`;
const defaultOrderBy = `created_at`;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function PayoutsTable({ driver }) {
  const theme = useTheme();
  const cls = useStyles();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);

  return (
    <Query query={GET_DRIVER_PAYOUTS} variables={{ driverId: driver.id }}>
      {({ loading, error, data, refetch }) => {
        const refetchPayouts = () => refetch();

        // LOADING STATE //
        if (loading) return <Loading position='relative' />;

        // ERROR STATE //
        if (error) {
          console.error('Failed to load payouts:', error);
          return <StaticAlert type='error' message='Failed to load payouts.' />;
        }

        // EMPTY STATE //
        if (!data || !data.driverpayouts || !data.driverpayouts.length) {
          return <StaticAlert type='info' message='No payouts available.' />;
        }

        // DATA STATE //

        let payouts = data.driverpayouts;
        log && console.log(`Payouts:`, payouts);

        const headers = [
          { id: 'created_at', align: 'left', label: 'Date' },
          { id: 'transfer_token', align: 'left', label: 'Transfer\xa0ID' },
          { id: 'type', align: 'left', label: 'Type' },
          { id: 'status', align: 'left', label: 'Status' },
          { id: 'total', align: 'right', label: 'Total' },
        ];

        const rows = payouts.map((payout, i) => {
          return {
            created_at: moment(payout.created_at).format(`MM/DD/YYYY h:mm A`) || moment().format(`MM/DD/YYYY h:mm A`),
            transfer_token: payout.transfer_token || null,
            type: getReadableText(payout.type) || null,
            status: (
              <div className={cls.statusBox}>
                {payout.status.includes(`new`) && (
                  <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
                    new_releases
                  </Icon>
                )}
                {payout.status.includes(`process`) && (
                  <Icon className={cls.statusIcon} style={{ color: theme.palette.warning.main }}>
                    pending
                  </Icon>
                )}
                {payout.status.includes(`success`) && (
                  <Icon className={cls.statusIcon} style={{ color: theme.palette.success.main }}>
                    check_circle
                  </Icon>
                )}
                {payout.status.includes(`fail`) && (
                  <Icon className={cls.statusIcon} style={{ color: theme.palette.error.main }}>
                    cancel
                  </Icon>
                )}
                {payout.status.includes(`cancel`) && (
                  <Icon className={cls.statusIcon} style={{ color: theme.palette.default.main }}>
                    block
                  </Icon>
                )}
                <Typography className={cls.statusTxt}>{capFirst(payout.status)}</Typography>
              </div>
            ),
            total: (
              <Chip
                label={formatUSD(payout.amount, { removeSign: true })}
                icon={<Icon>attach_money</Icon>}
                classes={{
                  label: cls.chipLabel,
                }}
              />
            ),

            payout: payout,
            index: i,
          };
        });

        return (
          <div className={cls.paper}>
            <TableContainer>
              <Table size='small' aria-label='payouts-table'>
                <PayoutsTableHead
                  headers={headers}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                />
                <PayoutsTableBody rows={rows} order={order} orderBy={orderBy} />
              </Table>
            </TableContainer>
          </div>
        );
      }}
    </Query>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },

  statusBox: {
    position: 'relative',
    display: 'flex',
  },
  statusIcon: {
    fontSize: 20,
    marginRight: 8,
  },
  statusTxt: {
    fontSize: 14,
  },

  chipLabel: {
    marginTop: 2,
    marginLeft: -4,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const GET_DRIVER_PAYOUTS = gql`
  query get_driver_payouts($driverId: bigint!) {
    driverpayouts(
      limit: 50
      where: {
        driver_id: { _eq: $driverId }
        _or: [{ status: { _eq: "processing" } }, { status: { _eq: "successful" } }]
      }
    ) {
      id
      amount
      created_at
      status
      transfer_token
      type
    }
  }
`;
