import * as NetworkUtils from './network';
import { todayAs_YYYYMMDD_withSlashes } from './date';
import { ENV, S3_VEHICLE_PHOTOS_BUCKET_NAME } from './constants';

export const generateStepName = (stepId, sequenceNum) => {
  return `${stepId}${sequenceNum != undefined ? `-${sequenceNum}` : ''}`;
};

export const generatePhotoFileName = (moveId, workflowType, stepId, sequenceNum, hash = undefined) => {
  return `${moveId}.${workflowType}.${stepId}${sequenceNum != undefined ? `-${sequenceNum}` : ''}${
    hash ? `.${hash}` : ''
  }.jpg`;
};

export const uploadPhoto = async (file, dataUrl, token, s3PhotoName, progressCb) => {
  let dateString = todayAs_YYYYMMDD_withSlashes();

  const base64 = dataUrl.split(',')[1];
  const fileType = dataUrl.replace('data:', '').split(';')[0];

  //TODO: Remove the driver portal prefix
  let fileFullName = `driver_portal/${ENV}/${dateString}/${s3PhotoName}`;

  const uploadURL = await NetworkUtils.getS3PresignedUrl(
    S3_VEHICLE_PHOTOS_BUCKET_NAME,
    token,
    '',
    fileFullName,
    fileType
  );

  const { uploadSpeedMbps } = await NetworkUtils.uploadBase64File(
    base64,
    file.name,
    uploadURL,
    {
      'Content-Type': fileType,
    },
    progressCb
  );

  let uploadedPhotoPublicUrl = uploadURL.split('?')[0];

  // console.log(
  //   `S3 upload complete for ${file} to ${uploadedPhotoPublicUrl}\n`,
  //   JSON.stringify({ uploadSpeedMbps }, null, 2)
  // );

  return uploadedPhotoPublicUrl;
};
