//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Tooltip, IconButton, Icon } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function IconRefetch({
  className = null,
  refetch = null,
  message = null,
  tooltip = null,
  tooltipPlacement = null,
  toastLevel = 'info',
  ...rest
}) {
  const cls = useStyles();
  const [refetching, setRefetching] = React.useState(false);

  const handleRefetch = async () => {
    setRefetching(true);
    try {
      await refetch();
      if (toastLevel === 'info')
        toast.info(message || `Data refetched!`, { autoClose: 2000 });
    } catch (err) {
      if (toastLevel === 'error' || toastLevel === 'info')
        toast.error(`Error refetching data!`);
      console.error(`Error refetching data:`, err);
    }
    setRefetching(false);
  };

  const getClassNames = () => {
    let classNames = [cls.iconBtn];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <Tooltip placement={tooltipPlacement || `top`} title={tooltip || `Refetch Data`}>
      <IconButton className={getClassNames()} onClick={() => handleRefetch()} {...rest}>
        {refetching ? <Icon className={cls.loading}>hourglass_empty</Icon> : <Icon>refresh</Icon>}
      </IconButton>
    </Tooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(1),
  },
  loading: {
    animation: 'hourglass 1s infinite',
    animationTimingFunction: 'ease-in-out',
  },
}));
