import React, { useEffect, useState } from 'react';
import { makeStyles, Icon, LinearProgress, Typography } from '@material-ui/core';
import { useCapturePhoto } from './useCapturePhoto';
import { CapturedPhotoThumbs } from './CapturedPhotoThumbs';

const CameraView = ({ stepName, onCapture, onClose = () => {} }) => {
  const cls = useStyles();
  const { captureImage, imageData, switchCameraFacingMode } = useCapturePhoto(); // customHook that contains logics
  const [canvasOpacity] = useState(0);
  const [images, setImages] = useState([]); // an array of base64 strings [string, string, ...

  useEffect(() => {
    if (imageData.length < 10) return;

    // A photo was captured!
    const base64Image = imageData.split(',')[1];
    setImages([...images, imageData]);
    if (onCapture) onCapture(base64Image);
  }, [imageData]);

  return (
    <div id='CameraView' className={cls.cameraView}>
      <div className='VideoAndCanvas'>
        <video className={cls.video} />
        <canvas style={{ opacity: canvasOpacity }} />
      </div>
      <Typography
        color='secondary'
        variant='caption'
        className={cls.stepName}
        style={{ position: 'absolute', top: '15px', right: '15px' }}
      >
        <b>{stepName}</b>
      </Typography>
      <Icon id='BackButton' className={cls.closeButton} fontSize='small' color='secondary' onClick={onClose}>
        arrow_back
      </Icon>
      <Icon
        id='CaptureImageButton'
        className={cls.cameraButton}
        fontSize='large'
        color='secondary'
        onClick={captureImage}
      >
        camera
      </Icon>
      <Icon
        id='SwitchCameraButton'
        fontSize='large'
        color='secondary'
        style={{ position: 'absolute', top: '40px', bottom: '40px', transform: 'translate(-50%, -50%)' }}
        onClick={switchCameraFacingMode}
      >
        recycle
      </Icon>
      {images.length > 0 && (
        <>
          <Typography
            id='imageDataCounter'
            align='center'
            style={{ color: 'white', position: 'absolute', bottom: '15px', right: '15px', zIndex: 1000 }}
          >
            <b>{images.length}</b>
          </Typography>
          <CapturedPhotoThumbs images={images} imageWidth={60} bottom={10} right={15} />
        </>
      )}
    </div>
  );
};

export default CameraView;

const useStyles = makeStyles(theme => ({
  main: {},
  cameraView: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    zIndex: 2000,
    backgroundColor: 'black',
  },
  video: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    fontSize: 16,
    fontWeight: 600,
    height: 35,
    width: 35,
    borderRadius: 100,
    color: 'black',
    backgroundColor: 'white',
    padding: 10,
    boxShadow: theme.shadow.harsh,
  },
  cameraButton: {
    position: 'absolute',
    right: '5%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    height: 65,
    width: 65,
    borderRadius: 100,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: 15,
    boxShadow: theme.shadow.harsh,
  },
  stepName: {
    position: 'absolute',
    top: 15,
    right: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    //backgroundColor: theme.palette.background.paper,
    padding: 5,
    borderRadius: 5,
    textShadow: `1px 1px 4px rgba(0,0,0,0.8)`,
  },
}));
