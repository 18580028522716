import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/utils/PrivateRoute';

import LoginRedirect from './components/utils/LoginRedirect';
import Onboarding from './components/pages/Onboarding/Onboarding';

import Contact from './components/pages/Contact';
import Dashboard from './components/pages/Dashboard';
import DriverPay from './components/pages/DriverPay/DriverPay';
import Faq from './components/pages/Faq';
import MoveDetails from './components/pages/MoveDetails';
import MoveWorkflow from './components/pages/MoveWorkflow';
import Schedule from './components/pages/Schedule';
import Wallet from './components/pages/Wallet/Wallet';
import Moves from './components/pages/Moves/Moves';

export default function Routes() {
  return (
    <Switch>
      <Route exact path='/login' component={LoginRedirect} />
      <Route path='/onboarding' component={Onboarding} />

      <PrivateRoute exact path='/' component={Dashboard} />
      <PrivateRoute exact path='/contact' component={Contact} />
      <PrivateRoute exact path='/faq' component={Faq} />
      <PrivateRoute exact path='/moves' component={Moves} />
      <PrivateRoute exact path='/moves/:id/' component={MoveDetails} />
      <PrivateRoute exact path='/moves/:id/workflow' component={MoveWorkflow} />
      <PrivateRoute exact path='/pay' component={DriverPay} />
      <PrivateRoute exact path='/schedule' component={Schedule} />
      <PrivateRoute exact path='/wallet' component={Wallet} />
    </Switch>
  );
}
