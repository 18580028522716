//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import StaticAlert from '../../utils/StaticAlert';

import MoveTile from './MoveTile';
import MoveTileLinked from './MoveTileLinked';

//////////////////////// COMPONENT ////////////////////////

export default function MovesTable({ moves, search }) {
  const cls = useStyles();

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    let filteredMoves = [...moves];

    // Filter moves by search
    if (search) {
      filteredMoves = moves.filter(move => {
        if (move) {
          const searchEdit = search.toLowerCase();

          if (`${move.id}`.toString().includes(searchEdit)) return true;
          if (`${move.lane.description}`.toLowerCase().includes(searchEdit)) return true;
          if (`${move.reference_num}`.toLowerCase().includes(searchEdit)) return true;
          if (`${move.status}`.toLowerCase().includes(searchEdit)) return true;
          if (`${move.vehicle_color}`.toLowerCase().includes(searchEdit)) return true;
          if (`${move.vehicle_make}`.toLowerCase().includes(searchEdit)) return true;
          if (`${move.vehicle_model}`.toLowerCase().includes(searchEdit)) return true;
          if (`${move.vehicle_stock}`.toLowerCase().includes(searchEdit)) return true;
          if (`${move.vehicle_vin}`.toLowerCase().includes(searchEdit)) return true;
          if (`${move.vehicle_year}`.toLowerCase().includes(searchEdit)) return true;
        }

        return false;
      });
    }

    // Build a list of rows to render based on the filtered moves and their children
    let rowsEdit = [];

    filteredMoves.forEach(move => {
      const rowIds = rowsEdit.map(row => row.id);
      if (!rowIds?.includes(move?.parentMove?.id)) rowsEdit.push(move);
    });

    setRows(rowsEdit);
  }, [moves, search]);

  return (
    <div className={cls.table}>
      {rows.length ? (
        rows.map((move, i) => (
          <React.Fragment key={`move-${move.id}`}>
            <div className={cls.rowSpacer} />

            <Link to={`/moves/${move.id}`}>
              <div className={cls.row}>
                {move?.childMoves?.length ? <MoveTileLinked move={move} /> : <MoveTile move={move} />}
              </div>
            </Link>
          </React.Fragment>
        ))
      ) : (
        <>
          <div className={cls.rowSpacer} />
          <StaticAlert type='info' message='No moves found.' />
        </>
      )}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  table: {},

  rowSpacer: {
    width: '100%',
    height: 16,
  },

  row: {
    boxShadow: theme.shadow.medium,
  },
}));
