// @ts-nocheck
import React, { useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import GlobalContext from '../../GlobalContext';
import * as Workflows from '@hopdrive/workflows';

import * as Sentry from '@sentry/react';
import gql from 'graphql-tag';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, Container, Grid, Typography, IconButton, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import Loading from '../utils/Loading';
import { Query } from 'react-apollo';
import { useTools } from '../hooks/useTools';
import {
  getStage,
  statuses,
  getNextStatus,
  getStatusButtonText,
  stages,
  updateMoveWithWorkflowOutput,
  updateVehiclePhotoOnMove,
} from '../utils/move';
import { stepTypes } from '../utils/workflow';

import * as PhotoUtils from '../utils/photo';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuth } from 'firebase/auth';
import useWorkflow from '../reusable/Workflow/Workflow';
import MoveTileHangtag from '../reusable/MoveTileHangtag';

const log = true;

////////// COMPONENT //////////

function MoveWorkflow(props) {
  const cls = useStyles();

  const moveId = props.match.params.id;

  const { handlePage, apolloClient } = useContext(GlobalContext);
  handlePage();

  const lastClicked = useRef(null);
  const { getIdTokenClaims, user } = useAuth0();
  const firebase = getAuth();
  const {
    capFirst,
    getFormattedCombinedStatusFromMove,
    getFormattedVehicleFromMove,
    getFormattedStatusFromHangtag,
    getReadableText,
    getNonBreakingWord,
  } = useTools();

  const { workflowStepsRef, Workflow, submit } = useWorkflow({
    getAuthToken: async () => {
      try {
        let token;
        if (user) {
          //Auth0
          const tokenClaims = await getIdTokenClaims();
          token = tokenClaims ? tokenClaims.__raw : null;
        } else if (firebase.currentUser) {
          //Firebase
          token = await firebase.currentUser?.getIdToken();
        }
        return token;
      } catch (err) {
        console.log('Error retrieving token claims', err);
      }
    },
    onSubmit: validationResult => {
      console.log('[onSubmit] Workflow submitted with the following validation result: ', validationResult);
    },
  });

  const goBack = () => {
    props.history.goBack();
  };

  const getWorkflowDefaultValues = (move, steps) => {
    let values = {};
    if (!move || !steps) return values;
    steps.forEach(step => {
      switch (step.type) {
        case stepTypes.GROUP:
          const groupValues = getWorkflowDefaultValues(move, step.steps);
          if (groupValues) values = { ...values, ...groupValues };
          break;
        case stepTypes.PHOTO:
        case stepTypes.SIGNATURE:
          const url =
            move?.[`${getStage(move.status)}vehiclephotos`]?.find(photo => photo.step_id === step.id)?.url || null;
          if (url) values[step.id] = url;
          break;
        case stepTypes.PHOTOS:
          // JS Arrays are ordered collections and as such the order of items in the
          // array is retained. As long as we add the existing photos to the value array
          // in the order of the photo.name from the database, then the photos will be
          // displayed in the correct order in the UI and the photo.name can be reliably
          // deduced from the position of a photo in the array.

          // FYI the vehiclephotos array sort is not performed in JS because it is
          // done in the GQL query: vehiclephotos(order_by: { name: asc })
          const urls =
            move?.[`${getStage(move.status)}vehiclephotos`]
              ?.filter(photo => photo.step_id === step.id)
              ?.map(photo => {
                return photo.url;
              }) || null;
          if (urls) values[step.id] = urls;
          break;
        case stepTypes.TEXT:
        case stepTypes.AUTOCOMPLETE:
        case stepTypes.YEAR:
        case stepTypes.BARCODE:
        default:
          const defaultValue = move[`${getStage(move.status)}_workflow_data`]?.[step.id] || null;
          if (defaultValue) values[step.id] = defaultValue;
          break;
      }
    });

    // Handle custom mappings
    values['vehicle-vin'] = move.vehicle_vin;
    values['vehicle-year'] = move.vehicle_year;
    values['vehicle-make'] = move.vehicle_make;
    values['vehicle-model'] = move.vehicle_model;
    values['vehicle-color'] = move.vehicle_color;
    values['vehicle-stock'] = move.vehicle_stock;

    return values;
  };

  const translateStatusToColumn = status => {
    switch (status) {
      case statuses.PICKUP_STARTED:
        return 'pickup_started';
      case statuses.PICKUP_ARRIVED:
        return 'pickup_arrived';
      case statuses.PICKUP_SUCCESSFUL:
        return 'pickup_successful';
      case statuses.DELIVERY_STARTED:
        return 'delivery_started';
      case statuses.DELIVERY_ARRIVED:
        return 'delivery_arrived';
      case statuses.DELIVERY_SUCCESSFUL:
        return 'delivery_successful';
      default:
        return 'pickup_started';
    }
  };

  const advanceMoveStatus = async (move, oldStatus, status, refetch) => {
    if (lastClicked && new Date() - lastClicked.current < 1000) {
      toast.warning(`Please wait at least 10 seconds before advancing!`);
      return;
    }

    lastClicked.current = new Date();

    // Check if we are submitting the form or not
    if (oldStatus === statuses.PICKUP_ARRIVED || oldStatus === statuses.DELIVERY_ARRIVED) {
      const validationResult = submit();
      //console.log(`Validation result: ${JSON.stringify(validationResult, null, 2)}`);
      const { isValid, invalidStepIds } = validationResult;
      if (!isValid) {
        const StepErrorMessage = () => (
          <>
            <p>Please fill out all required fields!</p>
            {invalidStepIds.map(id => {
              const step = Workflows.getStepByStepId(workflowStepsRef.current, id);
              return (
                <React.Fragment key={id}>
                  <p className={cls.paperTitleTxt}>{step?.label}</p>
                  <span className={cls.paperKeyTxt}>{step?.description}</span>
                </React.Fragment>
              );
            })}
          </>
        );
        toast.warn(<StepErrorMessage />);
        return;
      }

      const res = await updateMoveWithWorkflowOutput({
        mutateFn: apolloClient.mutate,
        move,
        steps: workflowStepsRef.current,
      });
    }

    // Here I will perform a mutation to change the status of the move
    await apolloClient.mutate({
      mutation: UPDATE_MOVE_STATUS_AND_TIME(translateStatusToColumn(status)),
      variables: {
        id: move.id,
        status: status,
        eventTime: new Date().toISOString(),
      },
    });
    if (refetch) await refetch();

    if (status === statuses.DELIVERY_SUCCESSFUL && move?.childMoves?.length) {
      props.history.push(`/moves/${move?.childMoves?.[0]?.id}/workflow`);
    }
  };

  const handlePhotoCapture = move => async (step, url, multiPhotoIndex) => {
    const name = PhotoUtils.generateStepName(step.id, multiPhotoIndex);
    const row = {
      mutateFn: apolloClient.mutate,
      move_id: move.id,
      step_id: step.id,
      name,
      url,
      workflow_id: move?.[`${getStage(move.status)}_workflow`]?.id,
      status: 'done',
      location: {},
      createdBy: user?.email || firebase.currentUser?.email || 'driver portal',
    };
    const res = await updateVehiclePhotoOnMove(row);
  };

  const handleNameCapturedPhoto = move => async (step, multiPhotoIndex) => {
    return PhotoUtils.generatePhotoFileName(
      move?.id,
      getStage(move.status),
      step?.id,
      multiPhotoIndex,
      new Date().getTime()
    );
  };

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Query query={GET_MOVE} variables={{ moveId: moveId }}>
            {({ loading, error, data, refetch }) => {
              if (loading) return <Loading fixed />;
              if (error) {
                console.log(`Failed to retrieve move:`, error);
                Sentry.captureException(error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>ERROR FINDING MOVE RECORD</Typography>
                  </div>
                );
              }
              if (data && data.moves && data.moves.length > 0) {
                const move = data.moves[0];
                const workflowSteps = getPropValue(move, `${getStage(move.status)}_workflow.steps`);
                const defaultStepValues = getWorkflowDefaultValues(move, workflowSteps);

                log && console.log(`[MoveWorkflow] Move:`, move);
                log && console.log(`[MoveWorkflow] Workflow Steps (${getStage(move.status)}):`, workflowSteps);
                log && console.log(`[MoveWorkflow] Default step values:`, defaultStepValues);

                return (
                  <>
                    <Grid container alignItems='center'>
                      <Grid item>
                        <IconButton className={cls.iconBtn} onClick={() => goBack()}>
                          <Icon>arrow_back</Icon>
                        </IconButton>
                      </Grid>

                      <Grid item>
                        <Typography className={cls.head}>Move Workflow</Typography>
                      </Grid>

                      <Grid item xs />
                    </Grid>

                    <Spacer />

                    <div className={cls.paper}>
                      <Grid container spacing={1}>
                        <Grid item xs>
                          <Typography className={cls.paperTitleTxt}>Move #{move.id}</Typography>
                        </Grid>

                        <Grid item>
                          <Typography className={cls.paperTitleTxt}>
                            {getFormattedCombinedStatusFromMove(move)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Spacer />

                      <div className={cls.infoBox}>
                        <div className={cls.infoBoxOverlay}>
                          <Typography className={cls.infoBoxTitle}>
                            {capFirst(getStage(move.status))} Location
                          </Typography>
                        </div>

                        <Typography className={cls.infoBoxTxt}>{move.lane[getStage(move.status)].address}</Typography>
                      </div>

                      <Spacer />

                      {move.customer && move.customer.name ? (
                        <div className={cls.flex}>
                          <Typography className={cls.paperKeyTxt}>Customer:&nbsp;</Typography>
                          <Typography className={cls.paperValTxt}>{move.customer.name}</Typography>
                        </div>
                      ) : null}

                      {move.vehicle_model ? (
                        <div className={cls.flex}>
                          <Typography className={cls.paperKeyTxt}>Vehicle:&nbsp;</Typography>
                          <Typography className={cls.paperValTxt}>{getFormattedVehicleFromMove(move)}</Typography>
                        </div>
                      ) : null}

                      {move.manual_flag === true || move.manual_flag === false ? (
                        <div className={cls.flex}>
                          <Typography className={cls.paperKeyTxt}>Transmission:&nbsp;</Typography>
                          <Typography className={cls.paperValTxt}>
                            {move.manual_flag ? `Manual` : `Automatic`}
                          </Typography>
                        </div>
                      ) : null}

                      {move.vehicle_vin ? (
                        <div className={cls.flex}>
                          <Typography className={cls.paperKeyTxt}>VIN:&nbsp;</Typography>
                          <Typography className={cls.paperValTxt}>{move.vehicle_vin}</Typography>
                        </div>
                      ) : null}

                      {move.vehicle_stock ? (
                        <div className={cls.flex}>
                          <Typography className={cls.paperKeyTxt}>Stock:&nbsp;</Typography>
                          <Typography className={cls.paperValTxt}>{move.vehicle_stock}</Typography>
                        </div>
                      ) : null}

                      {move.move_details ? (
                        <>
                          <Typography className={cls.paperKeyTxt}>Notes/Instructions:&nbsp;</Typography>
                          <Typography className={cls.notesTxt}>{move.move_details}</Typography>
                        </>
                      ) : null}
                    </div>

                    <Spacer size='lg' />

                    {move.hangtags && move.hangtags.length && move.hangtags[0].type === `yard` ? (
                      <div className={cls.paper}>
                        <MoveTileHangtag hangtag={move.hangtags[0]} />

                        <Grid container spacing={1}>
                          <Grid item xs>
                            <Typography className={cls.paperTitleTxt}>Hangtag</Typography>
                          </Grid>

                          <Grid item>
                            <Typography className={cls.paperTitleTxt}>
                              {getFormattedStatusFromHangtag(move.hangtags[0])}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Spacer />

                        {move.hangtags[0].rear_code ? (
                          <div className={cls.flex}>
                            <Typography className={cls.paperKeyTxt}>Rear&nbsp;Code:&nbsp;</Typography>
                            <Typography className={cls.paperValTxt}>{move.hangtags[0].rear_code}</Typography>
                          </div>
                        ) : null}

                        {move.hangtags[0].workflow_data && Object.keys(move.hangtags[0].workflow_data).length ? (
                          <>
                            {Object.keys(move.hangtags[0].workflow_data).map(key => (
                              <div key={`hangtag-workflow-${key}`} className={cls.flex}>
                                <Typography className={cls.paperKeyTxt}>
                                  {getNonBreakingWord(getReadableText(key))}:&nbsp;
                                </Typography>
                                <Typography className={cls.paperValTxt}>
                                  {move.hangtags[0].workflow_data[key]}
                                </Typography>
                              </div>
                            ))}
                          </>
                        ) : null}
                      </div>
                    ) : null}

                    {(move?.status === statuses.PICKUP_ARRIVED || move?.status === statuses.DELIVERY_ARRIVED) && (
                      <Workflow
                        onPhotoCapture={handlePhotoCapture(move)}
                        onNameCapturedPhoto={handleNameCapturedPhoto(move)}
                        workflowSteps={workflowSteps}
                        defaultStepValues={defaultStepValues}
                      />
                    )}

                    {move?.status !== `delivery successful` && (
                      <>
                        <div className={cls.footerSpacer} />

                        <div className={cls.footer}>
                          <Button
                            className={cls.button}
                            fullWidth
                            color='primary'
                            size='large'
                            onClick={() => advanceMoveStatus(move, move.status, getNextStatus(move.status), refetch)}
                          >
                            {getStatusButtonText(move.status)}
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                );
              } else
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>NO MOVE RECORD FOUND</Typography>
                  </div>
                );
            }}
          </Query>
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  iconBtn: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },

  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  paperTitleTxt: {
    fontSize: 18,
    fontWeight: 600,
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  paperKeyTxt: {
    lineHeight: 1.333,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  paperValTxt: {
    lineHeight: 1.333,
    width: '100%',
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(2),
    borderTop: '1px solid #ddd',
    background: theme.palette.background.paper,
  },
  footerSpacer: {
    width: '100%',
    height: theme.spacing(10),
  },
  button: {
    borderRadius: 24,
  },

  infoBox: {
    position: 'relative',
    padding: theme.spacing(1.5, 2),
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
  },
  infoBoxOverlay: {
    position: 'absolute',
    top: -7,
    left: 8,
    padding: theme.spacing(0, 1),
    background: theme.palette.background.paper,
  },
  infoBoxTitle: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  infoBoxTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
  },

  notesTxt: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 500,
  },
}));

////////// GRAPHQL //////////

const GET_MOVE = gql`
  query get_moves($moveId: bigint!) {
    moves(where: { id: { _eq: $moveId }, active: { _eq: 1 } }) {
      id
      cancel_status
      class
      consumer_at_pickup
      consumer_name
      consumer_phone
      consumer_pickup
      deliver_by
      delivery_arrived
      delivery_started
      delivery_stop_id
      delivery_successful
      delivery_time
      delivery_workflow_data
      driver_id
      driver_name
      manual_flag
      move_details
      move_type
      pickup_arrived
      pickup_started
      pickup_stop_id
      pickup_successful
      pickup_time
      pickup_workflow_data
      plan_id
      ready_by
      reference_num
      return_ride_id
      ride_type
      status
      trip_id
      vehicle_color
      vehicle_image
      vehicle_make
      vehicle_model
      vehicle_odometer
      vehicle_stock
      vehicle_vin
      vehicle_year
      accessorials {
        id
        ap_amount
        code
        notes
        status
      }
      appayments {
        id
        amount
        notes
        status
        type
        accessorial {
          id
          code
          notes
        }
        move {
          id
          move_type
        }
      }
      customer {
        id
        name
      }
      delivery_workflow {
        id
        name
        steps
        type
      }
      driver {
        id
        user {
          id
          avatar_url
          display_name
        }
      }
      hangtags(order_by: { updated_at: desc }) {
        id
        hash_id
        rear_code
        status
        type
        updated_at
        workflow_data
      }
      lane {
        id
        average_drive_speed_mph
        description
        distance_miles
        duration_sec
        delivery {
          id
          address
          name
          type
        }
        pickup {
          id
          address
          name
          type
        }
      }
      pickup_workflow {
        id
        name
        steps
        type
      }
      pickupvehiclephotos: vehiclephotos(where: { workflow: { type: { _eq: "pickup" } } }, order_by: { name: asc }) {
        id
        name
        step_id
        url
      }
      deliveryvehiclephotos: vehiclephotos(
        where: { workflow: { type: { _eq: "delivery" } } }
        order_by: { name: asc }
      ) {
        id
        name
        step_id
        url
      }
      childMoves {
        id
      }
    }
  }
`;

const UPDATE_MOVE_STATUS_AND_TIME = timestampColumn => gql`
  mutation updateMoveStatusById(
    $id: bigint!
    $status: String!
    $eventTime: timestamptz
  ) {
    update_moves_by_pk(
      pk_columns: { id: $id }
      _set: {
        status: $status
        ${timestampColumn}: $eventTime
      }
    ) {
      id
    }
  }
`;

////////// EXPORT //////////
export default withRouter(MoveWorkflow);
