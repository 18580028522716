import React from 'react';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import GlobalContext from '../../../GlobalContext';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import gql from 'graphql-tag';

import { useTools } from '../../../hooks/useTools';
import useFormat from '../../../hooks/useFormat';
import useOnboarding from './useOnboarding';

import WorkflowBar from './WorkflowBar';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function FormAddressInfo({ driver = {}, flowGroup, flowSection, onClose, refetch }) {
  const ctx = React.useContext(GlobalContext);
  const cls = useStyles();

  // Hook functions
  const { genListOfStates } = useTools();
  const { formatString } = useFormat();
  const { proceedToPrevWorkflow, proceedToNextWorkflow } = useOnboarding();

  const listOfStates = genListOfStates(true);

  // General form state
  const [editMode, setEditMode] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [validation, setValidation] = React.useState({});

  // Value form State
  const [homeAddressOne, setHomeAddressOne] = React.useState(``);
  const [homeAddressTwo, setHomeAddressTwo] = React.useState(``);
  const [homeCity, setHomeCity] = React.useState(``);
  const [homeState, setHomeState] = React.useState(`none`);
  const [homeZip, setHomeZip] = React.useState(``);

  const [matchAddress, setMatchAddress] = React.useState(true);

  const [mailAddressOne, setMailAddressOne] = React.useState(``);
  const [mailAddressTwo, setMailAddressTwo] = React.useState(``);
  const [mailCity, setMailCity] = React.useState(``);
  const [mailState, setMailState] = React.useState(`none`);
  const [mailZip, setMailZip] = React.useState(``);

  // Checks if all inputs are valid
  const handleValidateForm = () => {
    let valid = true;

    const newValidation = {
      homeAddressOne: homeAddressOne ? true : false,
      homeAddressTwo: true,
      homeCity: homeCity ? true : false,
      homeState: homeState !== `none`,
      homeZip: homeZip && homeZip.length === 5 ? true : false,

      matchAddress: matchAddress === true || matchAddress === false,

      mailAddressOne: matchAddress !== false || mailAddressOne ? true : false,
      mailAddressTwo: true,
      mailCity: matchAddress !== false || mailCity ? true : false,
      mailState: matchAddress !== false || mailState !== `none`,
      mailZip: matchAddress !== false || (mailZip && homeZip.length === 5) ? true : false,
    };
    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return valid;
  };

  // Update state with driver fields (when driver changes)
  React.useEffect(() => {
    if (driver) {
      setHomeAddressOne(driver.address_one || ``);
      setHomeAddressTwo(driver.address_two || ``);
      setHomeCity(driver.address_city || ``);
      setHomeState(driver.address_state || `none`);
      setHomeZip(driver.address_zip || ``);

      if (
        driver.address_one === driver.mail_address_one &&
        driver.address_two === driver.mail_address_two &&
        driver.address_city === driver.mail_address_city &&
        driver.address_state === driver.mail_address_state &&
        driver.address_zip === driver.mail_address_zip
      ) {
        setMatchAddress(true);
      } else if (
        driver.mail_address_one ||
        driver.mail_address_two ||
        driver.mail_address_city ||
        driver.mail_address_state ||
        driver.mail_address_zip
      ) {
        setMatchAddress(false);

        setMailAddressOne(driver.mail_address_one || ``);
        setMailAddressTwo(driver.mail_address_two || ``);
        setMailCity(driver.mail_address_city || ``);
        setMailState(driver.mail_address_state || `none`);
        setMailZip(driver.mail_address_zip || ``);
      } else setMatchAddress(null);
    }
  }, [driver]);

  // Mutation handler
  const saveAddress = async () => {
    const variables = {
      driverId: driver.id,
      driverdetailInput: {
        address_city: homeCity || null,
        address_one: homeAddressOne || null,
        address_state: homeState !== `none` ? homeState : null,
        address_two: homeAddressTwo || null,
        address_zip: homeZip || null,

        mail_address_city: matchAddress ? homeCity : mailCity ? mailCity : null,
        mail_address_one: matchAddress ? homeAddressOne : mailAddressOne ? mailAddressOne : null,
        mail_address_state: matchAddress ? homeState : mailState !== `none` ? mailState : null,
        mail_address_two: matchAddress ? homeAddressTwo : mailAddressTwo ? mailAddressTwo : null,
        mail_address_zip: matchAddress ? homeZip : mailZip ? mailZip : null,
      },
    };

    log && console.log(`saveAddress - Variables:`, variables);

    try {
      const res = await ctx.apolloClient.mutate({
        mutation: SAVE_ADDRESS,
        variables: variables,
      });

      if (getPropValue(res, `data.update_driverdetails.affected_rows`)) {
        if (refetch) refetch();
        return { success: true };
      }

      const errStr = `Failed to update information. Please contact support.`;
      console.error(errStr);
      return { success: false, error: errStr };
    } catch (err) {
      const errStr = `Failed to update information. Please contact support.`;
      console.error(`Error updating information:`, err);
      return { success: false, error: errStr };
    }
  };

  // Handle saving the form
  const handleSave = async (proceed = false) => {
    const res = await saveAddress();
    if (res && res.success === true) {
      setIsSaving(false);
      toast.success(`Information saved!`, { autoClose: 2500 });
      if (proceed) {
        proceedToNextWorkflow(flowGroup, flowSection.id, onClose);
        return;
      }
      if (onClose) {
        onClose();
        return;
      }
    }
    if (res && res.error) {
      toast.error(res.error);
    }
  };

  // List of button actions in the workflow bar
  const actions = [
    {
      label: `Save`,
      icon: `save`,
      color: `secondary`,
      tip: `Save changes & return to the LaunchPad.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave();
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Save & Proceed`,
      icon: `double_arrow`,
      color: `primary`,
      tip: `Save changes & proceed to the next section.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave(true);
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Back`,
      icon: `arrow_back`,
      variant: `outlined`,
      color: `secondary`,
      tip: `Jump to the previous section.`,
      handler: () => proceedToPrevWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
    {
      label: `Forward`,
      icon: `arrow_forward`,
      variant: `outlined`,
      color: `primary`,
      tip: `Jump to the next section.`,
      handler: () => proceedToNextWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
  ];

  return (
    <>
      <WorkflowBar
        groupTitle={flowGroup.label}
        sectionTitle={flowSection.label}
        actions={actions}
        isSaving={isSaving}
        onClose={onClose}
        // editMode={editMode}
        // setEditMode={setEditMode}
      />

      <div className={cls.workflowPad} />

      <Spacer size='xxl' />

      <Container maxWidth='md'>
        <div className={cls.workflow}>
          {/* HOME ADDRESS INFORMATION */}

          <Typography className={cls.title}>Home Address</Typography>
          <Typography className={cls.subtitle}>Please fill out your home address below.</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Address Line One&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter street address...'
                variant='outlined'
                size='small'
                value={homeAddressOne}
                onChange={e => setHomeAddressOne(e.target.value)}
                onBlur={() => setHomeAddressOne(formatString(homeAddressOne))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        home
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.homeAddressOne === false}
                helperText={validation.homeAddressOne === false ? `Street address is invalid` : null}
              />
            </Grid>

            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Address Line Two&nbsp;</Typography>
              </div>
              <TextField
                disabled={!editMode}
                fullWidth
                placeholder='Enter auxilary info (apt, suite, unit, etc.)...'
                variant='outlined'
                size='small'
                value={homeAddressTwo}
                onChange={e => setHomeAddressTwo(e.target.value)}
                onBlur={() => setHomeAddressTwo(formatString(homeAddressTwo))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        home_work
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.homeAddressTwo === false}
                helperText={validation.homeAddressTwo === false ? `Auxilary address info is invalid` : null}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>City&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter city...'
                variant='outlined'
                size='small'
                value={homeCity}
                onChange={e => setHomeCity(e.target.value)}
                onBlur={() => setHomeCity(formatString(homeCity))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        location_city
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.homeCity === false}
                helperText={validation.homeCity === false ? `City is invalid` : null}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>State&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                select
                fullWidth
                placeholder='Select state...'
                variant='outlined'
                size='small'
                value={homeState}
                onChange={e => setHomeState(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        flag
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.homeState === false}
                helperText={validation.homeState === false ? `State is invalid` : null}
              >
                <MenuItem value='none'>
                  <div className={cls.placeholder}>Select state...</div>
                </MenuItem>
                {listOfStates && listOfStates.length
                  ? listOfStates.map(s => (
                      <MenuItem key={`home-address-state-${s.initials}`} value={s.initials}>
                        {s.initials} ({s.full})
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>

            <Grid item md={3} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>ZIP Code&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter ZIP code...'
                variant='outlined'
                size='small'
                value={homeZip}
                onChange={e => setHomeZip(e.target.value)}
                onBlur={() => setHomeZip(formatString(homeZip, { removeSpaces: true }))}
                inputProps={{ maxLength: 5 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        tag
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.homeZip === false}
                helperText={validation.homeZip === false ? `ZIP code is invalid` : null}
              />
            </Grid>
          </Grid>
          <Spacer size='xl' />

          {/* MAILING ADDRESS INFORMATION */}

          <Typography className={cls.title}>Mailing Address</Typography>
          <Typography className={cls.subtitle}>
            Please specify if your home address and mailing address are identical. If not, please fill out your mailing
            address below.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Are your home address and mailing address identical?&nbsp;
                </Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              {validation.matchAddress === false ? (
                <Typography className={cls.errorMessage}>Please select an option below</Typography>
              ) : null}
              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={matchAddress === true}
                  onChange={() => setMatchAddress(true)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>Yes, my home address and mailing address are the same.</Typography>
              </div>
              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={matchAddress === false}
                  onChange={() => setMatchAddress(false)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  No, my home address is different from my mailing address.
                </Typography>
              </div>
            </Grid>

            {matchAddress === false ? (
              <>
                <Grid item xs={12}>
                  <div className={cls.label}>
                    <Typography className={cls.labelTxt}>Address Line One&nbsp;</Typography>
                    <Typography className={cls.labelRequired}>*</Typography>
                  </div>
                  <TextField
                    disabled={!editMode}
                    required
                    fullWidth
                    placeholder='Enter street address...'
                    variant='outlined'
                    size='small'
                    value={mailAddressOne}
                    onChange={e => setMailAddressOne(e.target.value)}
                    onBlur={() => setMailAddressOne(formatString(mailAddressOne))}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className={cls.adornmentOutlined} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            home
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    error={validation.mailAddressOne === false}
                    helperText={validation.mailAddressOne === false ? `Street address is invalid` : null}
                  />
                </Grid>

                <Grid item xs={12}>
                  <div className={cls.label}>
                    <Typography className={cls.labelTxt}>Address Line Two&nbsp;</Typography>
                  </div>
                  <TextField
                    disabled={!editMode}
                    fullWidth
                    placeholder='Enter auxilary info (apt, suite, unit, etc.)...'
                    variant='outlined'
                    size='small'
                    value={mailAddressTwo}
                    onChange={e => setMailAddressTwo(e.target.value)}
                    onBlur={() => setMailAddressTwo(formatString(mailAddressTwo))}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className={cls.adornmentOutlined} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            home_work
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    error={validation.mailAddressTwo === false}
                    helperText={validation.mailAddressTwo === false ? `Auxilary address info is invalid` : null}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <div className={cls.label}>
                    <Typography className={cls.labelTxt}>City&nbsp;</Typography>
                    <Typography className={cls.labelRequired}>*</Typography>
                  </div>
                  <TextField
                    disabled={!editMode}
                    required
                    fullWidth
                    placeholder='Enter city...'
                    variant='outlined'
                    size='small'
                    value={mailCity}
                    onChange={e => setMailCity(e.target.value)}
                    onBlur={() => setMailCity(formatString(mailCity))}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className={cls.adornmentOutlined} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            location_city
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    error={validation.mailCity === false}
                    helperText={validation.mailCity === false ? `City is invalid` : null}
                  />
                </Grid>

                <Grid item md={3} xs={12}>
                  <div className={cls.label}>
                    <Typography className={cls.labelTxt}>State&nbsp;</Typography>
                    <Typography className={cls.labelRequired}>*</Typography>
                  </div>
                  <TextField
                    disabled={!editMode}
                    required
                    select
                    fullWidth
                    placeholder='Select state...'
                    variant='outlined'
                    size='small'
                    value={mailState}
                    onChange={e => setMailState(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className={cls.adornmentOutlined} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            flag
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    error={validation.mailState === false}
                    helperText={validation.mailState === false ? `State is invalid` : null}
                  >
                    <MenuItem value='none'>
                      <div className={cls.placeholder}>Select state...</div>
                    </MenuItem>
                    {listOfStates && listOfStates.length
                      ? listOfStates.map(s => (
                          <MenuItem key={`mail-address-state-${s.initials}`} value={s.initials}>
                            {s.initials} ({s.full})
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </Grid>

                <Grid item md={3} xs={12}>
                  <div className={cls.label}>
                    <Typography className={cls.labelTxt}>ZIP Code&nbsp;</Typography>
                    <Typography className={cls.labelRequired}>*</Typography>
                  </div>
                  <TextField
                    disabled={!editMode}
                    required
                    fullWidth
                    placeholder='Enter ZIP code...'
                    variant='outlined'
                    size='small'
                    value={mailZip}
                    onChange={e => setMailZip(e.target.value)}
                    onBlur={() => setMailZip(formatString(mailZip, { removeSpaces: true }))}
                    inputProps={{ maxLength: 5 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className={cls.adornmentOutlined} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            tag
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    error={validation.mailZip === false}
                    helperText={validation.mailZip === false ? `ZIP code is invalid` : null}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </div>
      </Container>

      <Spacer size='xl' />
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  workflowPad: {
    width: '100%',
    height: 56,
  },

  workflow: {
    position: 'relative',
    width: '100%',
    // padding: theme.spacing(2),
    // borderRadius: theme.shape.paperRadius,
    // background: theme.palette.background.paper,
    // boxShadow: theme.shadow.medium,
  },

  title: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: 14,
    // color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  label: {
    display: 'flex',
  },
  labelTxt: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      margin: '3px 0',
      fontSize: 12,
    },
  },
  labelRequired: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.error.main,
    [theme.breakpoints.down('xs')]: {
      margin: '2px 0',
      fontSize: 12,
    },
  },
  labelHelp: {
    display: 'block',
    verticalAlign: 'top',
    marginLeft: 'auto',
    lineHeight: 1,
    fontSize: 20,
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },

  adornment: {
    verticalAlign: 'top',
    marginBottom: 6,
  },
  adornmentOutlined: {
    verticalAlign: 'top',
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },

  errorMessage: {
    marginLeft: 14,
    lineHeight: 1.25,
    fontSize: 12,
    color: theme.palette.error.main,
  },

  checkDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    padding: theme.spacing(1),
  },
  checkTxt: {
    marginLeft: 4,
    lineHeight: 1.25,
    fontSize: 14,
  },

  spacer: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const SAVE_ADDRESS = gql`
  mutation driver_saveAddress($driverId: bigint!, $driverdetailInput: driverdetails_set_input!) {
    update_driverdetails(where: { driver_id: { _eq: $driverId } }, _set: $driverdetailInput) {
      affected_rows
      returning {
        driver_id
        address_city
        address_one
        address_state
        address_two
        address_zip
        mail_address_city
        mail_address_one
        mail_address_state
        mail_address_two
        mail_address_zip
        updated_at
      }
    }
  }
`;
