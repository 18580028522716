import React from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { makeStyles, Grid, Typography, Tooltip, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useWallet } from './useWallet';

import IconAction from '../../reusable/IconAction';

/** Capitalize the first letter of the first word */
const capFirst = (str = null) => {
  if (str && typeof str === `string`) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function WalletBadge({ driver, wallet, bankAccount, refetchDriver }) {
  const cls = useStyles();

  const { removeBankAccount } = useWallet();

  const actions = [
    {
      label: `Remove Bank Account`,
      handler: async () => {
        const res = await removeBankAccount({ driverId: driver.id, bankAccountToken: driver.bank_account_token });
        if (res && res.success) {
          toast.success(`Successfully removed bank account!`, { autoClose: 2500 });
          if (refetchDriver) refetchDriver();
        } else {
          toast.error(`Failed to remove bank account!`);
        }
      },
    },
  ];

  return (
    <div className={cls.wallet}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <Icon className={cls.walletIco}>wallet</Icon>

          {bankAccount.status === `verified` ? (
            <Tooltip title={`Bank account has been verified`}>
              <Icon className={cls.verifiedIco}>check_circle_outline</Icon>
            </Tooltip>
          ) : (
            <Tooltip title={`Bank account has NOT been verified yet`}>
              <Icon className={cls.unverifiedIco}>remove_circle_outline</Icon>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs>
          <Typography className={cls.nameTxt}>{bankAccount.name}</Typography>
          <Typography className={cls.bankNameTxt}>{bankAccount.bankName}</Typography>

          <Spacer size='xs' />

          <Typography className={cls.subTxt}>{capFirst(bankAccount.bankAccountType) || `Checking`}</Typography>
          <Typography className={cls.subTxt}>
            Added {moment(bankAccount.created).format(`MM/DD/YYYY`)} at {moment(bankAccount.created).format(`h:mm A`)}
          </Typography>
        </Grid>
      </Grid>

      <Spacer />

      <div className={cls.walletSubBox}>
        <Typography className={cls.walletSubTxt}>
          <b>Wallet&nbsp;ID:&nbsp;&nbsp;</b>
          {wallet.id || `Null`}
        </Typography>
        <Typography className={cls.walletSubTxt}>
          <b>Account&nbsp;ID:&nbsp;&nbsp;</b>
          {bankAccount.id || `Null`}
        </Typography>
      </div>

      <div className={cls.gear}>
        <IconAction actions={actions} />
      </div>
    </div>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  wallet: {
    position: 'relative',
    minWidth: 240,
    maxWidth: 480,
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  walletIco: {
    display: 'block',
    marginTop: -4,
    marginBottom: -4,
    fontSize: 48,
    color: theme.palette.text.secondary,
  },
  verifiedIco: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.success.main,
  },
  unverifiedIco: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.text.disabled,
  },

  nameTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 600,
  },
  bankNameTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
  },
  subTxt: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
  },

  walletSubBox: {
    position: 'relative',
    width: 'fit-content',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.dark,
  },
  walletSubTxt: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  gear: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
}));
