import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { useTheme, makeStyles, Container, Typography, Icon } from '@material-ui/core';

import PageRoot from '../../reusable/PageRoot';
import Divide from '../../reusable/Divide';
import GlobalContext from '../../../GlobalContext';

////////// COMPONENT //////////
function DashboardContent(props) {
  const theme = useTheme();
  const cls = useStyles();
  const ctx = useContext(GlobalContext);

  const { driver } = props;

  const goToMyPay = range => {
    localStorage.setItem(`pay-range`, range);
    props.history.push(`/pay`);
  };

  React.useEffect(() => {
    ctx.handlePage()
  }, [])

  return (
    <>
      <PageRoot>
        <Container maxWidth='md'>
          <div className={cls.break} />
          {driver.pic ? (
            <img className={cls.pic} src={driver.pic} alt='profile' />
          ) : (
            <div className={cls.pic}>
              <Typography className={cls.picInitials}>{driver.initials}</Typography>
            </div>
          )}
          <div className={cls.smallBreak} />
          <Typography className={cls.name}>{driver.name}</Typography>
          <div className={cls.break} />

          <Divide color='white' spacer>
            This Week
          </Divide>

          <div className={cls.paperLink} onClick={() => goToMyPay(`this-week`)}>
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.key}>EARNINGS</Typography>
                <Typography className={cls.bigVal}>{driver.twPay}</Typography>
              </div>
            </div>
            <Divide top='16px' bottom='16px' />
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.key}>MOVES</Typography>
                <Typography className={cls.val}>{driver.twMoves}</Typography>
              </div>
              <div className={cls.box}>
                <Typography className={cls.key}>DRIVE&nbsp;TIME</Typography>
                <Typography className={cls.val}>{driver.twTime}</Typography>
              </div>
              <div className={cls.box}>
                <Typography className={cls.key}>DISTANCE</Typography>
                <Typography className={cls.val}>{driver.twDistance}</Typography>
              </div>
            </div>
          </div>

          <Divide spacer>Last Week</Divide>

          <div className={cls.paperLink} onClick={() => goToMyPay(`last-week`)}>
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.key}>EARNINGS</Typography>
                <Typography className={cls.bigVal}>{driver.lwPay}</Typography>
              </div>
            </div>
            <Divide top='16px' bottom='16px' />
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.key}>MOVES</Typography>
                <Typography className={cls.val}>{driver.lwMoves}</Typography>
              </div>
              <div className={cls.box}>
                <Typography className={cls.key}>DRIVE&nbsp;TIME</Typography>
                <Typography className={cls.val}>{driver.lwTime}</Typography>
              </div>
              <div className={cls.box}>
                <Typography className={cls.key}>DISTANCE</Typography>
                <Typography className={cls.val}>{driver.lwDistance}</Typography>
              </div>
            </div>
          </div>

          <Divide spacer>Year To Date</Divide>

          <div className={cls.paperLink} onClick={() => goToMyPay(`year-to-date`)}>
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.key}>EARNINGS</Typography>
                <Typography className={cls.bigVal}>{driver.ytdPay}</Typography>
              </div>
            </div>
            <Divide top='16px' bottom='16px' />
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.key}>MOVES</Typography>
                <Typography className={cls.val}>{driver.ytdMoves}</Typography>
              </div>
              <div className={cls.box}>
                <Typography className={cls.key}>DRIVE&nbsp;TIME</Typography>
                <Typography className={cls.val}>{driver.ytdTime}</Typography>
              </div>
              <div className={cls.box}>
                <Typography className={cls.key}>DISTANCE</Typography>
                <Typography className={cls.val}>{driver.ytdDistance}</Typography>
              </div>
            </div>
            <Divide top='16px' bottom='16px' />
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.key}>MOST&nbsp;VISITED&nbsp;LOCATION</Typography>
                <Typography className={cls.val}>{driver.ytdLoc}</Typography>
              </div>
            </div>
            <Divide top='16px' bottom='16px' />
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.key}>MOST&nbsp;DRIVEN&nbsp;LANE</Typography>
                <Typography className={cls.val}>{driver.ytdLane1}</Typography>
              </div>
            </div>
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.val} style={{ margin: '4px 0' }}>
                  <Icon>import_export</Icon>
                </Typography>
              </div>
            </div>
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.val}>{driver.ytdLane2}</Typography>
              </div>
            </div>
          </div>

          <Divide spacer>Badges</Divide>

          <div className={cls.paper}>
            <div className={cls.flex}>
              <div className={cls.box}>
                <Typography className={cls.soon}>Your achievements appear here</Typography>
              </div>
            </div>
          </div>

          <Divide spacer>Links</Divide>

          <div className={cls.paper} style={{ padding: 0 }}>
            <Link to='/moves'>
              <div
                className={cls.button}
                style={{ borderRadius: `${theme.shape.paperRadius} ${theme.shape.paperRadius} 0 0` }}
              >
                <Typography className={cls.val}>My&nbsp;Moves</Typography>
              </div>
            </Link>
            <Divide top='0' bottom='0' />
            <Link to='/pay'>
              <div className={cls.button}>
                <Typography className={cls.val}>My&nbsp;Pay</Typography>
              </div>
            </Link>
            <Divide top='0' bottom='0' />
            <Link to='/schedule'>
              <div className={cls.button}>
                <Typography className={cls.val}>My&nbsp;Schedule</Typography>
              </div>
            </Link>
            <Divide top='0' bottom='0' />
            <Link to='/wallet'>
              <div className={cls.button}>
                <Typography className={cls.val}>My&nbsp;Wallet</Typography>
              </div>
            </Link>
            <Divide top='0' bottom='0' />
            <Link to='/contact'>
              <div className={cls.button}>
                <Typography className={cls.val}>Contact</Typography>
              </div>
            </Link>
            <Divide top='0' bottom='0' />
            <Link to='/faq'>
              <div
                className={cls.button}
                style={{ borderRadius: `0 0 ${theme.shape.paperRadius} ${theme.shape.paperRadius}` }}
              >
                <Typography className={cls.val}>FAQ</Typography>
              </div>
            </Link>
          </div>
        </Container>
      </PageRoot>
    </>
  );
}
export default withRouter(DashboardContent);

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  paperLink: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
    '&:hover': {
      backgroundColor: theme.palette.background.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  pic: {
    display: 'block',
    position: 'relative',
    maxWidth: '160px',
    maxHeight: '160px',
    borderRadius: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.common.white,
    verticalAlign: 'top',
    boxShadow: theme.shadow.main,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '128px',
      maxHeight: '128px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '96px',
      maxHeight: '96px',
    },
  },
  picInitials: {
    transform: 'translateY(25%)',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: 36,
    fontWeight: 700,
  },
  name: {
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
    textShadow: theme.shadow.main,
  },
  flex: {
    display: 'flex',
    flex: 1,
    flexFlow: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    width: '100%',
    textAlign: 'center',
  },
  box: {
    width: '100%',
    padding: `0 ${theme.spacing(2)}`,
  },
  key: {
    marginBottom: '4px',
    color: theme.palette.text.secondary,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1,
  },
  val: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1,
  },
  bigVal: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1,
  },
  soon: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1,
  },
  button: {
    padding: theme.spacing(2),
    textAlign: 'center',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(4),
  },
}));
