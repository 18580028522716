import React, { useReducer } from 'react';
import { makeStyles, Typography, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import * as Workflows from '@hopdrive/workflows';
import Photo from '../Photo';

export default function MultiPhotoCapture({
  workflowStepsRef,
  step,
  index,
  value,
  isValid,
  inGroup,
  isTakingPhoto = false,
  setIsTakingPhoto,
  onPhotoCapture,
  onNameCapturedPhoto,
  getAuthToken,
}) {
  const cls = useStyles();
  const forceUpdate = useReducer(x => x + 1, 0)[1];

  const handleAdditionalPhotoCapture = async (step, photoUrl, multiPhotoIndex) => {
    console.log(`[MultiPhotoCapture] Multi-capture step ${step.name}: ${photoUrl}`, multiPhotoIndex, step);
    forceUpdate();
    if (onPhotoCapture) onPhotoCapture(step, photoUrl, multiPhotoIndex);
  };

  // For normal photo capture steps, the following returns a single string of the url. However
  // for multi-photo capture steps, the following returns an array of strings of the urls.
  const publicPhotoUrls = Workflows.getStepByStepId(workflowStepsRef.current, step.id)?.value || [];

  return (
    <>
      {inGroup && index > 0 ? <Spacer size='lg' /> : null}

      {/* {!inGroup && (
        <div className={cls.cameraButton} onClick={() => setIsTakingPhoto(true)}>
          <Icon>photo_camera</Icon>
        </div>
      )} */}

      <Typography className={isValid ? cls.headTxt : cls.headTxtError}>
        {step.label}
        <span className={cls.required}>{step?.required ? `*` : ``}</span>
      </Typography>

      <Typography className={cls.subheadTxt}>{step.description}</Typography>

      {publicPhotoUrls.length ? (
        publicPhotoUrls.map((url, i) => (
          <React.Fragment key={`url-${url}-${i}`}>
            <Photo
              step={step}
              url={url}
              multiPhotoUpload
              onPhotoCapture={onPhotoCapture}
              onNameCapturedPhoto={onNameCapturedPhoto}
              workflowStepsRef={workflowStepsRef}
              getAuthToken={getAuthToken}
            />
            {i < publicPhotoUrls.length && <Spacer />}
          </React.Fragment>
        ))
      ) : (
        <Photo
          step={step}
          multiPhotoUpload
          onPhotoCapture={handleAdditionalPhotoCapture}
          onNameCapturedPhoto={onNameCapturedPhoto}
          workflowStepsRef={workflowStepsRef}
          getAuthToken={getAuthToken}
        />
      )}

      {publicPhotoUrls.length > 0 && (
        <Photo
          key={`url-add`}
          step={step}
          multiPhotoUpload
          onPhotoCapture={handleAdditionalPhotoCapture}
          onNameCapturedPhoto={onNameCapturedPhoto}
          workflowStepsRef={workflowStepsRef}
          getAuthToken={getAuthToken}
        />
      )}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    minHeight: 32,
    padding: '4px 8px',
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
    '&:focus': {
      padding: '3px 7px',
      border: `2px solid ${theme.palette.text.primary}`,
    },
  },
  cameraButton: {
    position: 'absolute',
    right: 15,
    top: -10,
    height: 54,
    borderRadius: 100,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: 15,
    boxShadow: theme.shadow.harsh,
  },
  headTxt: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  headTxtError: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subheadTxt: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  inputError: {
    width: '100%',
    minHeight: 32,
    padding: '3px 7px',
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
  },
  required: {
    display: `inline-block`,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
}));
