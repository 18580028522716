import React, { useEffect } from 'react';
import { makeStyles, Typography, LinearProgress } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import * as PhotoUtils from '../../utils/photo';
import * as NetworkUtils from '../../utils/network';
import * as Workflows from '@hopdrive/workflows';

export default function Photo({
  workflowStepsRef,
  step,
  url,
  multiPhotoUpload = false,
  onPhotoCapture,
  onNameCapturedPhoto,
  getAuthToken,
}) {
  const cls = useStyles();

  const fileChooser = React.useRef(null);
  const [file, setFile] = React.useState(null);
  const [photoDataUrl, setPhotoDataUrl] = React.useState(''); // data url version of local file
  const [publicPhotoUrl, setPublicPhotoUrl] = React.useState(url);
  const [progress, setProgress] = React.useState(100);
  const [loading, setLoading] = React.useState(false);
  const [failed, setFailed] = React.useState(false);

  const handleChoosePhoto = async e => {
    Object.keys(e.target.files).forEach(async key => {
      if (key === 'length') return;
      const currentPhotosArr = Workflows.getStepByStepId(workflowStepsRef.current, step.id)?.value;

      let keyAsInt;
      try {
        keyAsInt = parseInt(key);
      } catch (err) {
        console.error(`Error parsing int:`, err);
        keyAsInt = 0;
      }
      const file = e.target.files[key];
      if (multiPhotoUpload) {
        await readFileAndUpload(file, currentPhotosArr.length + keyAsInt);
      } else {
        await readFileAndUpload(file);
      }
    });
  };

  const readFileAndUpload = async (localFile, multiPhotoIndex = undefined) => {
    setFile(localFile);
    const reader = new FileReader();
    reader.addEventListener('load', async event => {
      setPhotoDataUrl(event.target.result);
      await uploadPhoto(localFile, event.target.result, multiPhotoIndex);
    });
    reader.readAsDataURL(localFile);
  };

  const uploadPhoto = async (fileParam, dataUrlParam, multiPhotoIndex = undefined) => {
    const fileToUpload = fileParam || file;
    const dataUrlToUpload = dataUrlParam || photoDataUrl;

    // console.log(`Uploading photo:`, {
    //   fileToUpload,
    //   file,
    //   photoDataUrl,
    //   progress,
    //   loading,
    //   failed,
    //   publicPhotoUrl,
    //   multiPhotoIndex,
    // });

    try {
      setLoading(true);
      setFailed(false);

      if (!fileToUpload) throw new Error('No file chosen');

      let filename = `${step?.id}.${new Date().getTime()}.jpg`;
      if (onNameCapturedPhoto && typeof onNameCapturedPhoto === 'function') {
        const ret = await onNameCapturedPhoto(step, multiPhotoIndex);
        if (ret) filename = ret;
      }

      //console.log(`Upload using ${filename} as photo filename`);

      // TODO: Figure out how to remove this external dependency
      const token = await getAuthToken();
      const publicPhotoUrl = await PhotoUtils.uploadPhoto(
        fileToUpload,
        dataUrlToUpload,
        token,
        filename,
        newProgress => {
          setProgress(oldProgress => newProgress);
        }
      );
      const didUploadSucceed = await NetworkUtils.urlExists(publicPhotoUrl);

      //console.log(`Photo upload succeeded: ${didUploadSucceed} at ${publicPhotoUrl}`);

      if (!didUploadSucceed) throw new Error(`Failed to upload photo to S3.`);

      if (onPhotoCapture) await onPhotoCapture(step, publicPhotoUrl, multiPhotoIndex);

      setPublicPhotoUrl(publicPhotoUrl);
      if (multiPhotoUpload) {
        // The value we are setting in the workflow step should be an array of
        // strings of the urls of the photos. So let's get the current array,
        // push onto it the newly captured URL, and set the value to the new array.
        const currentPhotosArr = Workflows.getStepByStepId(workflowStepsRef.current, step.id)?.value;
        currentPhotosArr.push(publicPhotoUrl);
        Workflows.setValueByStepId(workflowStepsRef.current, step.id, currentPhotosArr);
      } else {
        Workflows.setValueByStepId(workflowStepsRef.current, step.id, publicPhotoUrl);
      }
    } catch (error) {
      console.error(`Failed to upload photo:`, error);
      setFailed(true);
    } finally {
      setLoading(false);
      setProgress(100);
    }
  };

  return (
    <>
      <input
        type='file'
        accept='image/png, image/jpg, image/jpeg'
        onChange={handleChoosePhoto}
        placeholder='Choose Photo'
        ref={fileChooser}
        style={{ display: 'none' }}
        multiple={multiPhotoUpload}
      />

      <div className={cls.photoStep}>
        {(publicPhotoUrl || photoDataUrl) && (
          <>
            <div className={cls.photoOverlay}>
              <img className={cls.img} src={publicPhotoUrl || photoDataUrl} alt='Photo' />

              {failed && (
                <>
                  <div className={cls.failedOverlay} />

                  <Typography className={cls.failedTxt}>Failed to Upload Photo!</Typography>

                  <Button
                    className={cls.failedBtn}
                    disabled={loading}
                    loading={loading}
                    color='error'
                    size='small'
                    onClick={uploadPhoto}
                  >
                    Retry
                  </Button>
                </>
              )}
            </div>

            <Spacer size='xs' />
          </>
        )}

        {progress < 100 && (
          <>
            <LinearProgress variant='determinate' value={progress} />
            <Spacer size='xs' />
          </>
        )}

        <Button
          fullWidth
          disabled={loading}
          loading={loading}
          className={cls.buttonPreview}
          color='secondary'
          size='small'
          onClick={() => {
            fileChooser.current.value = null;
            fileChooser.current.click();
          }}
        >
          {publicPhotoUrl || file ? `Reupload` : `Upload`}
        </Button>
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  paperTitleTxt: {
    marginBottom: theme.spacing(1),
    fontSize: 18,
    fontWeight: 600,
  },
  paperKeyTxt: {
    lineHeight: 1.333,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  cameraButton: {
    position: 'absolute',
    right: 15,
    top: -10,
    height: 54,
    borderRadius: 100,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: 15,
    boxShadow: theme.shadow.harsh,
  },
  photoStep: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  photoOverlay: {
    position: 'relative',
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 360,
    padding: 4,
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 16,
    overflow: 'hidden',
  },
  failedOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 16,
    background: theme.palette.default.light,
    backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 10px, #ffffff40 10px, #ffffff40 20px)`,
    opacity: 0.4,
    overflow: 'hidden',
  },
  failedTxt: {
    position: 'absolute',
    textAlign: 'center',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)',
    lineHeight: 1.25,
    fontSize: 24,
    fontWeight: 900,
    color: 'white',
    textShadow: '0px 0px 4px rgba(0,0,0,0.5)',
  },
  failedBtn: {
    position: 'absolute',
    top: '72%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4,
  },
  button: {
    borderRadius: 24,
  },
  buttonClear: {
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
  buttonPreview: {
    borderRadius: 4,
  },
  buttonRetake: {
    borderRadius: 4,
  },
}));
