//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Tooltip } from '@material-ui/core';

import { useTools } from '../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTileHangtagTooltip({ children, hangtag }) {
  const cls = useStyles();

  const { getFormattedStatusFromHangtag, getReadableText } = useTools();

  return (
    <Tooltip
      title={
        <div className={cls.ttBox}>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item className={cls.ttTitle}>
              Hangtag Status:
            </Grid>
            <Grid item className={cls.ttTitle}>
              {getFormattedStatusFromHangtag(hangtag) || `-`}
            </Grid>
          </Grid>

          <hr className={cls.ttDivider} />

          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Rear Code:</Grid>
            <Grid item>{hangtag.rear_code || `-`}</Grid>
          </Grid>

          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Hash Code:</Grid>
            <Grid item>{hangtag.hash_id || `-`}</Grid>
          </Grid>

          {hangtag.workflow_data && Object.keys(hangtag.workflow_data).length ? (
            <>
              <hr className={cls.ttDivider} />

              {Object.keys(hangtag.workflow_data).map(key => (
                <Grid key={`hangtag-workflow-${key}`} container justifyContent='space-between' alignItems='flex-start'>
                  <Grid item>{getReadableText(key)}:</Grid>
                  <Grid item>{hangtag.workflow_data[key] || `-`}</Grid>
                </Grid>
              ))}
            </>
          ) : null}
        </div>
      }
      placement='bottom'
      width='fit-content'
    >
      {children}
    </Tooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  ttBox: {
    display: 'flex',
    flexDirection: 'column',
    width: 192,
  },
  ttTitle: {
    fontWeight: 900,
  },
  ttDivider: {
    width: '100%',
    opacity: 0.5,
  },
}));
