import React from 'react';
import { useTheme, makeStyles, Grid, Typography, Icon } from '@material-ui/core';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function LaunchPadSection({ index, group, section }) {
  const theme = useTheme();
  const cls = useStyles();

  return (
    <>
      {section.hide ? null : (
        <div
          className={section.disabled ? cls.sectionBarDisabled : cls.sectionBar}
          onClick={() => {
            if (section.handler && !section.disabled) section.handler(group, section);
          }}
        >
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              <Typography className={section.disabled ? cls.sectionTxtDisabled : cls.sectionTxt}>
                {section.label || `LaunchPad Section ${index + 1}`}&nbsp;
              </Typography>
            </Grid>

            <Grid item xs={4}>
              {!section.status || section.status === `not started` ? (
                <div className={cls.status}>
                  <Icon className={cls.statusIcon} style={{ color: theme.palette.text.disabled }}>
                    radio_button_unchecked
                  </Icon>
                  <Typography className={cls.statusTxt}>Not Started</Typography>
                </div>
              ) : null}

              {section.status === `in progress` ? (
                <div className={cls.status}>
                  <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
                    adjust
                  </Icon>
                  <Typography className={cls.statusTxt}>In Progress</Typography>
                </div>
              ) : null}

              {section.status === `done` ? (
                <div className={cls.status}>
                  <Icon className={cls.statusIcon} style={{ color: theme.palette.success.main }}>
                    circle
                  </Icon>
                  <Typography className={cls.statusTxt}>Done</Typography>
                </div>
              ) : null}
            </Grid>

            <Grid item>
              {section.disabled ? <div className={cls.spacerIcon} /> : <Icon className={cls.editIcon}>edit</Icon>}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  sectionBar: {
    position: 'relative',
    padding: theme.spacing(2),
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },
  sectionBarDisabled: {
    position: 'relative',
    padding: theme.spacing(2),
  },
  sectionTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  sectionTxtDisabled: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },

  status: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    display: 'block',
    marginBottom: 2,
    marginRight: theme.spacing(1),
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  statusTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  editIcon: {
    display: 'block',
    color: theme.palette.text.disabled,
  },
  spacerIcon: {
    display: 'block',
    width: 24,
    height: 24,
  },
}));
