import React from 'react';
import { Alert }  from '@material-ui/lab';

/**
 * @prop {TypeFormat} type - "success" | "warning" | "error" | "info"
 * @prop {String} message - String to display to user
 * @return {JSX} An Alert component of designated type displaying designated message
 */
export default function StaticAlert(props) {
  return <Alert variant="filled" severity={props.type} style={{ width: '100%', borderRadius: '0' }}>{props.message}</Alert>
}