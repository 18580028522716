import React from 'react';
import axios from 'axios';
import { matchPath } from 'react-router';
import GlobalContext from './GlobalContext';
import {useHistory} from 'react-router-dom'

import ApolloClient from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Nav from './components/Nav';
import Content from './components/Content';
import Routes from './Routes';
import NotificationPopup from './components/reusable/NotificationPopup';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './components/utils/Loading';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

let log = false; // Toggle developer console logging
var https = require('https');

const version = require('./version.js');
const jwt = require('jsonwebtoken');

const theme = createMuiTheme({
  palette: {
    default: {
      main: `#323640`,
      light: `#646c80`,
      lighter: `#a0a8b4`,
      dark: `#16181c`,
      contrastText: `#ffffff`,
    },

    primary: {
      main: `#f44232`,
      light: `#f88876`,
      lighter: `#ffdad8`,
      dark: `#b43220`,
      darker: `#a02416`,
      contrastText: `#ffffff`,
    },
    secondary: {
      main: `#486496`,
      light: `#7696c0`,
      lighter: `#dae8ff`,
      dark: `#2b3d59`,
      contrastText: `#ffffff`,
    },
    tertiary: {
      main: `#509090`,
      light: `#88b4b4`,
      dark: `#406464`,
      contrastText: `#ffffff`,
    },
    quaternary: {
      main: `#ffc050`,
      light: `#ffda99`,
      dark: `#e8a020`,
      contrastText: `#ffffff`,
    },

    info: {
      main: `#2080ff`,
      light: `#64b5f6`,
      dark: `#1976d2`,
      contrastText: `#ffffff`,
    },
    error: {
      main: `#ff2050`,
      light: `#ffa0a8`,
      dark: `#d41025`,
      contrastText: `#ffffff`,
    },
    warning: {
      main: `#ffa040`,
      light: `#ffb74d`,
      dark: `#f57c00`,
      contrastText: `#ffffff`,
    },
    success: {
      main: `#20c820`,
      light: `#81c784`,
      dark: `#388e3c`,
      contrastText: `#ffffff`,
    },

    nav: {
      prod: `#303844`,
      test: `#606874`,
    },

    ops: {
      main: `#f05020`,
    },
    concierge: {
      main: `#4080c8`,
    },
    lyft: {
      main: `#ea0b8c`,
    },
    uber: {
      main: `#000000`,
    },
    auto: {
      main: `#20c880`,
    },
    android: {
      main: `#00de7a`,
    },
    ios: {
      main: `#b3b3b3`,
    },

    text: {
      primary: `#323640`,
      secondary: `#32364096`,
      disabled: `#32364072`,
      hint: `#32364072`,
      contrast: `#ffffff`,
      contrastFade: `#ffffff96`,
    },

    background: {
      paper: `#ffffff`,
      default: `#fafafa`,
      light: `#f8f8f8`,
      main: `#f4f4f4`,
      dark: `#f0f0f0`,
    },

    action: {
      hover: `#00000010`,
      selected: `#00000020`,
      focus: `#00000030`,
      active: `#00000080`,
      disabled: `#00000060`,
      disabledBackground: `#00000030`,
    },

    divider: `#32323224`,
    dividerStrong: `#e0e0e0`,
    drop: `#32364010`,
    softDivider: `#32323216`,
    hardDivider: `#32323232`,

    border: `#ddd`,

    fade: [
      `#00000010`,
      `#00000020`,
      `#00000030`,
      `#00000040`,
      `#00000050`,
      `#00000060`,
      `#00000080`,
      `#000000a0`,
      `#000000b4`,
      `#000000c8`,
    ],
  },

  border: [`1px solid #00000020`, `1px solid #00000040`, `1px solid #00000060`, `1px solid #e0e0e0`],

  shape: {
    borderRadius: `4px`,
    paperRadius: `8px`,
  },

  shadow: {
    none: `none`,
    soft: `0 0 16px #00000016`,
    medium: `0 0 16px #00000024`,
    harsh: `0 0 16px #00000040`,
    nav: `0 0 16px #00000040`,

    main: `0 0 12px #00000024`,
  },

  typography: { useNextVariants: true },
});

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function App(props) {
  const firebaseAuth = getAuth();
  const auth0 = useAuth0();
  const history = useHistory()

  const [onboardingId, setOnboardingId] = React.useState(null);
  const [driverId, setDriverId] = React.useState(null);
  const [page, setPage] = React.useState(``);
  const [apolloClient, setApolloClient] = React.useState(null);
  const [redirectPath, setRedirectPath] = React.useState(null);
  const [userAuth, setUserAuth] = React.useState(null);
  const [notificationShow, setNotificationShow] = React.useState(false);
  const [notificationVariant, setNotificationVariant] = React.useState('');
  const [notificationMessage, setNotificationMessage] = React.useState('');
  const [apolloInitialized, setApolloInitialized] = React.useState(false);
  const [userId, setUserId] = React.useState(null)
  const [role, setRole] = React.useState(null)
  const [firebaseJwt, setFirebaseJwt] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [showNav, setShowNav] = React.useState(true)

  const getFirebaseLoginStatus = async () => {
    let firebaseToken = await firebaseAuth.currentUser?.getIdToken();
    if (firebaseToken) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  };

    onAuthStateChanged(firebaseAuth, user => {
      getFirebaseLoginStatus()
      if (user) {
        getFirebaseLoginStatus()
        setFirebaseJwt(user.accessToken)
      } else {
        setFirebaseJwt(null)
      }
    })

React.useEffect(() => {
if (firebaseJwt){
  setDriverId(Number(jwt.decode(firebaseJwt)['https://hasura.io/jwt/claims']['x-hasura-driver-id']))
  setUserId(jwt.decode(firebaseJwt)['https://hasura.io/jwt/claims']['x-hasura-user-id'])
  setRole(jwt.decode(firebaseJwt)['https://hasura.io/jwt/claims']['x-hasura-default-role'])

  handleApolloClient(null, firebaseJwt)
  handlePage()
} 
}, [firebaseJwt])

  function handleNotifications(show, variant = notificationVariant, message = notificationMessage) {
    setNotificationShow(show);
    setNotificationVariant(variant);
    setNotificationMessage(message);
  }

  const handlePage = () => {
    log && console.log(window.location.pathname);
      if (!firebaseJwt && !auth0.isAuthenticated) {
        setShowNav(false)
        setPage(`Sign\xa0In`)
      } else {
        setShowNav(true)
        if (window.location.pathname === `/`) setPage(`My\xa0Dashboard`);
        else if (window.location.pathname === `/contact`) setPage(`Contact`);
        else if (window.location.pathname === `/faq`) setPage(`FAQ`);
        else if (window.location.pathname === `/login`) setPage(`Sign\xa0In`);
        else if (window.location.pathname === `/pay`) setPage(`My\xa0Pay`);
        else if (window.location.pathname === `/schedule`) setPage(`My\xa0Schedule`);
        else if (window.location.pathname.includes(`/moves/`)) setPage(`Move\xa0Details`);
        else if (window.location.pathname.includes(`/moves`)) setPage(`My\xa0Moves`);
        else if (window.location.pathname.includes(`/onboarding`)) setPage(`Onboarding`);
        else if (window.location.pathname === `/wallet`) setPage(`My\xa0Wallet`);
        else if (window.location.pathname === `/login`) setPage(`Sign\xa0In`)
        else setPage(`Page Not Found`);
      }
  };

  const handleApolloClient = async (auth0Token, firebaseToken) => {
    if (!auth0Token && !firebaseToken && auth0.isAuthenticated) {
      try {
        let { __raw: JWT } = await auth0.getIdTokenClaims()
        auth0Token = JWT;
      } catch (err) {
        console.error('Failed to fetch user JWT:', err);
        auth0Token = null;
      }
    }
    // Pass in (token) if using a JWT or other auth token (retrieved after login and redirect)
    if (log) console.log('Setting up the Apollo Client...');
    const authLink = setContext(async () => {
        const clientName = `driver-portal-${version.tag || 'vX.X.X'}`;
        if (firebaseToken || auth0Token) {
          return {
            headers: {
              authorization: `Bearer ${firebaseToken ? firebaseToken : auth0Token}`, // Pass in token if using JWT/auth token
              'hasura-client-name': clientName,
            },
          };
        }
      }),
      wsurl = process.env.REACT_APP_GRAPHQL_WSS_URL, // GraphQL URL endpoint
      httpurl = process.env.REACT_APP_GRAPHQL_URL, // GraphQL websocket endpoint
      wsLink = new WebSocketLink({
        uri: wsurl,
        options: {
          lazy: true,
          reconnect: true,
          timeout: 30000,
          connectionParams: async () => {
            const clientName = `driver-portal-${version.tag || 'vX.X.X'}`;
            return {
              headers: {
                Authorization: `Bearer ${firebaseToken ? firebaseToken : auth0Token}`, // Pass in token if using JWT/auth token
                'hasura-client-name': clientName,
              },
            };
          },
        },
      }),
      httpLink = new HttpLink({
        uri: httpurl,
      }),
      link = split(
        // split based on operation type
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query);
          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        authLink.concat(httpLink)
      ),
      client = new ApolloClient({
        link,
        cache: new InMemoryCache(),
      });
    if (log) {
      console.log('Apollo Client Initialized! ', client);
    }
    setApolloClient(client);
    if (auth0.isAuthenticated){
      setUserAuth(auth0.user);
    } 
    if (firebaseAuth.currentUser){
      setUserAuth(firebaseAuth.currentUser)
    }
    setApolloInitialized(true);
  };

  React.useEffect(() => {
    const paths = matchPath(window.location.pathname, { path: '/onboarding/:id' });
    if (paths && paths.params && paths.params.id) setOnboardingId(paths.params.id);
    log && console.log(`PATHS:`, paths);
  }, []);

  React.useEffect(() => {
    if (onboardingId) {
      try {
        axios
          .post(`https://${process.env.REACT_APP_A0_SD}.auth0.com/oauth/token`, {
            grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
            username: onboardingId,
            password: onboardingId,
            client_id: process.env.REACT_APP_A0_ID,
            realm: process.env.REACT_APP_A0_REALM,
          })
          .then(async res => {
            log && console.log(`AUTH RES:`, res);
            const token = res.data.id_token;
            await handleApolloClient(token, null);

            const agent = new https.Agent({ rejectUnauthorized: false });
            axios({
              method: `POST`,
              url: `https://${process.env.REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`,
              data: { query: QUERY_DRIVER_ID },
              headers: { authorization: `Bearer ${token}` },
              httpsAgent: agent,
            })
              .then(res => {
                log && console.log(`DRIVER RES`, res);
                if (res && res.data && res.data.data && res.data.data.drivers && res.data.data.drivers.length) {
                  const id = res.data.data.drivers[0].id;
                  log && console.log(`Driver ID:`, id);
                  setDriverId(id);
                }
              })
              .catch(err => {
                console.error(`Failed to retrieve driver ID:`, err);
              });
          })
          .catch(err => {
            console.error('Error granting authorization:', err);
          });
      } catch (err) {
        console.error('Error granting authorization:', err);
      }
    }
  }, [onboardingId]);

  React.useEffect(() => {
    handlePage();
    handleApolloClient();
  }, [auth0]);

  React.useEffect(() => {
    if (userAuth && userAuth[`https://hasura.io/jwt/claims`]){
      //Auth0
      setDriverId(Number(auth0.user[`https://hasura.io/jwt/claims`][`x-hasura-driver-id`]));
    } else if (userAuth && userAuth.accessToken){
      //Firebase
      console.log(Number(jwt.decode(userAuth.accessToken)['https://hasura.io/jwt/claims']['x-hasura-driver-id']))
      setDriverId(Number(jwt.decode(userAuth.accessToken)['https://hasura.io/jwt/claims']['x-hasura-driver-id']))
    }
  }, [userAuth]);

  function logout() {
    if (firebaseJwt){ 
      signOut(firebaseAuth)
    } else {
      auth0.logout({ returnTo: process.env.REACT_APP_A0_CB_SD });
    } 
  }

  const context = {
    driverId: driverId,
    page: page,
    handlePage: handlePage,
    redirectPath: redirectPath,
    setRedirectPath: setRedirectPath,
    apolloClient: apolloClient,
    userAuth: userAuth,
    notificationShow: notificationShow,
    notificationVariant: notificationVariant,
    notificationMessage: notificationMessage,
    handleNotifications: handleNotifications,
    auth0: auth0,
    firebaseAuth: firebaseAuth,
    logout: logout,
    showNav: showNav
  };

  const buildSentryUserObj = userProfile => ({
    email: userProfile.email,
    name: userProfile.name,
    nickname: userProfile.nickname,
    user: userProfile['https://hasura.io/jwt/claims'] ? userProfile['https://hasura.io/jwt/claims']['x-hasura-user-id'] : userId,
    role: userProfile['https://hasura.io/jwt/claims'] ? userProfile['https://hasura.io/jwt/claims']['x-hasura-default-role'] : role
  });

  if (auth0.isLoading || !apolloInitialized || loading) return <Loading />;
  else if (apolloInitialized) {
    userAuth && Sentry.setContext('user', buildSentryUserObj(userAuth));
    return (
      <MuiThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <GlobalContext.Provider value={context}>
            <NotificationPopup
              show={notificationShow}
              variant={notificationVariant ? notificationVariant : 'info'}
              message={notificationMessage}
              handleClose={handleNotifications}
            />
            <CssBaseline />
            <Nav />
            <Content>
              <ToastContainer position='top-center' />
              <Routes />
            </Content>
          </GlobalContext.Provider>
        </ApolloProvider>
      </MuiThemeProvider>
    );
  } else return null;
}

const QUERY_DRIVER_ID = `
  query driver_getDriverId {
    drivers {
      id
    }
  }
`;
