//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import GlobalContext from '../../../GlobalContext';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loading from '../../utils/Loading';
import StaticAlert from '../../utils/StaticAlert';

import MovesContent from './MovesContent';

//////////////////////// COMPONENT ////////////////////////

export default function Moves(props) {
  const ctx = React.useContext(GlobalContext);
  ctx.handlePage();

  const driverId = ctx.driverId;

  return (
    <>
      {ctx && ctx.userAuth && (
        <Query
          query={GET_MOVES}
          variables={{
            driverId: driverId,
            start: dayjs().endOf(`week`).subtract(2, `weeks`),
            end: dayjs().endOf(`day`).format(),
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) {
              console.error('Failed to load moves:', error);
              return <StaticAlert type='error' message='Failed to load moves.' />;
            }

            const moves = data.moves;
            return <MovesContent moves={moves} />;
          }}
        </Query>
      )}
    </>
  );
}

//////////////////////// GRAPHQL ////////////////////////

const GET_MOVES = gql`
  query driver_getMoves($driverId: bigint!, $start: timestamptz!, $end: timestamptz!) {
    moves(
      order_by: { pickup_time: asc }
      where: {
        active: { _eq: 1 }
        cancel_status: { _is_null: true }
        driver_status: { 
          _nin: [
            "declined"
            "draft"
          ]
        }
        driver_id: { _eq: $driverId }
        move_type: { _ilike: "drive" }
        pickup_time: { _gte: $start, _lte: $end }
        status: {
          _in: [
            "dispatched"
            "pickup started"
            "pickup arrived"
            "pickup successful"
            "delivery started"
            "delivery arrived"
          ]
        }
      }
    ) {
      id
      consumer_name
      consumer_phone
      consumer_pickup
      consumer_type
      pickup_time
      reference_num
      status
      vehicle_color
      vehicle_make
      vehicle_model
      vehicle_stock
      vehicle_vin
      vehicle_year
      customer {
        id
        name
      }
      hangtags(order_by: { updated_at: desc }) {
        id
        hash_id
        rear_code
        status
        type
        updated_at
        workflow_data
      }
      lane {
        id
        delivery_inspection_sec
        description
        distance_miles
        duration_sec
        pickup_inspection_sec
        return_ride_wait_sec
        pickup {
          id
          name
          address
        }
        delivery {
          id
          name
          address
        }
      }
      totalAPPaymentAmounts: appayments_aggregate(where: { active: { _eq: "1" } }) {
        aggregate {
          sum {
            amount
          }
        }
      }
      parentMove {
        id
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
        pickup_time
        reference_num
        status
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_stock
        vehicle_vin
        vehicle_year
        customer {
          id
          name
        }
        lane {
          id
          delivery_inspection_sec
          description
          distance_miles
          duration_sec
          pickup_inspection_sec
          return_ride_wait_sec
          pickup {
            id
            name
            address
          }
          delivery {
            id
            name
            address
          }
        }
        totalAPPaymentAmounts: appayments_aggregate(where: { active: { _eq: "1" } }) {
          aggregate {
            sum {
              amount
            }
          }
        }
      }
      childMoves(where: { active: { _eq: 1 } }) {
        id
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
        pickup_time
        reference_num
        status
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_stock
        vehicle_vin
        vehicle_year
        customer {
          id
          name
        }
        lane {
          id
          delivery_inspection_sec
          description
          distance_miles
          duration_sec
          pickup_inspection_sec
          return_ride_wait_sec
          pickup {
            id
            name
            address
          }
          delivery {
            id
            name
            address
          }
        }
        totalAPPaymentAmounts: appayments_aggregate(where: { active: { _eq: "1" } }) {
          aggregate {
            sum {
              amount
            }
          }
        }
      }
    }
  }
`;
