import React from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import GlobalContext from '../../../GlobalContext';

import gql from 'graphql-tag';

const log = false;

//////////////////////////////////////// HOOK ////////////////////////////////////////

export default function useOnboarding() {
  const { apolloClient } = React.useContext(GlobalContext);

  /** Proceed to the prev workflow based on current workflow */
  const proceedToPrevWorkflow = async (group, sectionId, close) => {
    if (group && group.sections && group.sections.length) {
      // Find the prev section in the group by looking at the next section
      const prevSection = group.sections.find((g, i) => {
        const nextSection = group.sections[i + 1];
        if (nextSection && nextSection.id === sectionId) return true;
        return false;
      });

      // If no prev section is found, just return to the LaunchPad
      if (!prevSection) {
        log && console.log(`No prev section found, returning to LaunchPad.`);
        if (close) close();
        return;
      }

      // Proceed to prev section otherwise
      log && console.log(`Proceeding to '${prevSection.id}'...`);
      prevSection.handler(group, prevSection);
    } else {
      console.error(`Cannot proceed to prev section! No group found.`);
    }
  };

  /** Proceed to the next workflow based on current workflow */
  const proceedToNextWorkflow = async (group, sectionId, close) => {
    if (group && group.sections && group.sections.length) {
      // Find the next section in the group by looking at the prev section
      const nextSection = group.sections.find((g, i) => {
        const prevSection = group.sections[i - 1];
        if (prevSection && prevSection.id === sectionId) return true;
        return false;
      });

      // If no next section is found, just return to the LaunchPad
      if (!nextSection) {
        log && console.log(`No next section found, returning to LaunchPad.`);
        if (close) close();
        return;
      }

      // Proceed to next section otherwise
      log && console.log(`Proceeding to '${nextSection.id}'...`);
      nextSection.handler(group, nextSection);
    } else {
      console.error(`Cannot proceed to next section! No group found.`);
    }
  };

  /** Get status of personal information */
  const getPersonalInfoStatus = driver => {
    if (
      driver.date_of_birth &&
      driver.display_name &&
      driver.email &&
      driver.first_name &&
      driver.gender &&
      driver.last_name &&
      driver.middle_name &&
      driver.phone &&
      driver.phone_type &&
      driver.primary_phone &&
      (driver.social_security || driver.employer_identifier) &&
      (driver.config.attributes.manual === true || driver.config.attributes.manual === false) &&
      (driver.veteran.status === true || driver.veteran.status === false)
    ) {
      return `done`;
    }

    if (
      driver.date_of_birth ||
      driver.display_name ||
      driver.email ||
      driver.first_name ||
      driver.gender ||
      driver.last_name ||
      driver.middle_name ||
      driver.phone ||
      driver.phone_type ||
      driver.primary_phone ||
      driver.social_security ||
      driver.employer_identifier ||
      driver.config.attributes.manual === true ||
      driver.config.attributes.manual === false ||
      driver.veteran.status === true ||
      driver.veteran.status === false
    ) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Get status of address information */
  const getAddressInfoStatus = driver => {
    if (
      (driver.address_one &&
        driver.address_city &&
        driver.address_state &&
        driver.address_zip &&
        !driver.mail_address_one &&
        !driver.mail_address_city &&
        !driver.mail_address_state &&
        !driver.mail_address_zip) ||
      (driver.address_one &&
        driver.address_city &&
        driver.address_state &&
        driver.address_zip &&
        driver.mail_address_one &&
        driver.mail_address_city &&
        driver.mail_address_state &&
        driver.mail_address_zip)
    ) {
      return `done`;
    }

    if (
      driver.address_one ||
      driver.address_two ||
      driver.address_city ||
      driver.address_state ||
      driver.address_zip ||
      driver.mail_address_one ||
      driver.mail_address_two ||
      driver.mail_address_city ||
      driver.mail_address_state ||
      driver.mail_address_zip
    ) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Get status of vehicle information */
  const getVehicleInfoStatus = driver => {
    if (
      driver.vehicle_year &&
      driver.vehicle_make &&
      driver.vehicle_model &&
      driver.vehicle_color &&
      driver.vehicle_vin &&
      driver.vehicle_license_plate &&
      driver.vehicle_license_plate_state
    ) {
      return `done`;
    }

    if (
      driver.vehicle_year ||
      driver.vehicle_make ||
      driver.vehicle_model ||
      driver.vehicle_color ||
      driver.vehicle_vin ||
      driver.vehicle_license_plate ||
      driver.vehicle_license_plate_state
    ) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Get status of drivers license information */
  const getDriversLicenseInfoStatus = driver => {
    if (driver.license_number && driver.license_state) {
      return `done`;
    }

    if (driver.license_number || driver.license_state || driver.license_photo_url) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Get status of insurance information */
  const getInsuranceInfoStatus = driver => {
    if (
      driver.insurance_provider &&
      driver.insurance_policy_id &&
      driver.insurance_coverage_amount &&
      (driver.insurance_rideshare_rider === true || driver.insurance_rideshare_rider === false)
    ) {
      return `done`;
    }

    if (
      driver.insurance_provider ||
      driver.insurance_policy_id ||
      driver.insurance_coverage_amount ||
      driver.insurance_rideshare_rider === true ||
      driver.insurance_rideshare_rider === false
    ) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Check if information forms group has been completed */
  const checkInformationalFormsGroupComplete = driver => {
    if (driver && driver.verification && driver.verification.info_completed_datetime) return true;
    return false;
  };

  /** Build a list of launchpad groups and sections */
  const buildLaunchpadGroups = (driver, handleWorkflowOpen) => {
    const groups = [
      // INFORMATIONAL FORMS
      {
        id: `informational-forms`,
        label: `Informational Forms`,
        sections: [
          {
            id: `personal-information`,
            label: `Personal Information`,
            status: getPersonalInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
            disabled: checkInformationalFormsGroupComplete(driver),
          },
          {
            id: `address-information`,
            label: `Address Information`,
            status: getAddressInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
            disabled: checkInformationalFormsGroupComplete(driver),
          },
          {
            id: `vehicle-information`,
            label: `Vehicle Information`,
            status: getVehicleInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
            disabled: checkInformationalFormsGroupComplete(driver),
          },
          {
            id: `drivers-license-information`,
            label: `Drivers License Information`,
            status: getDriversLicenseInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
            disabled: checkInformationalFormsGroupComplete(driver),
          },
          {
            id: `insurance-information`,
            label: `Insurance Information`,
            status: getInsuranceInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
            disabled: checkInformationalFormsGroupComplete(driver),
          },
        ],
        canFinalize: () => {
          if (
            getPersonalInfoStatus(driver) === `done` &&
            getAddressInfoStatus(driver) === `done` &&
            getVehicleInfoStatus(driver) === `done` &&
            getDriversLicenseInfoStatus(driver) === `done` &&
            getInsuranceInfoStatus(driver) === `done` &&
            !checkInformationalFormsGroupComplete(driver)
          ) {
            return true;
          }
          return false;
        },
        isFinalized: () => checkInformationalFormsGroupComplete(driver),
        finalize: async callback => {
          const success = await finalizeInformationalFormsGroup(driver, callback);
          if (success === true) toast.success(`Informational forms were finalized!`);
          else toast.error(`An error occured while finalizing, please contact your dispatcher!`);
        },
      },

      // LEGAL DOCUMENTATION
      // {
      //   id: `legal-documentation`,
      //   label: `Legal Documentation`,
      //   sections: [
      //     {
      //       id: `employee-verification`,
      //       label: `Employee Verification`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `driver-agreement`,
      //       label: `Driver Agreement`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `code-of-conduct`,
      //       label: `Code of Conduct`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `drug-and-alcohol-policy`,
      //       label: `Drug & Alcohol Policy`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `employee-w-4`,
      //       label: `Employee W-4`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //       hide: driver.tax_class !== `W-2`,
      //     },
      //     {
      //       id: `part-time-employment-agreement`,
      //       label: `Part-Time Employment Agreement`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //       hide: driver.tax_class !== `W-2`,
      //     },
      //     {
      //       id: `offer-letter`,
      //       label: `Offer Letter`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //       hide: driver.tax_class !== `W-2`,
      //     },
      //   ],
      //   canFinalize: () => false,
      //   finalize: () => {
      //     console.log(`FINALIZE`);
      //   },
      // },

      // ONBOARDING FEES
      // {
      //   id: `onboarding-fees`,
      //   label: `Onboarding Fees`,
      //   sections: [
      //     {
      //       id: `payment-method`,
      //       label: `Payment Method`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `process-payment`,
      //       label: `Process Payment`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //       disable: !driver.payment_method,
      //     },
      //   ],
      //   canFinalize: () => false,
      //   finalize: () => {
      //     console.log(`FINALIZE`);
      //   },
      // },
    ];
    return groups;
  };

  /** Finalize the informational-forms group */
  const finalizeInformationalFormsGroup = async (driver, callback) => {
    const verification = { info_completed_datetime: dayjs().utc().format() };
    return await updateDriverVerification(driver, verification, callback);
  };

  /** Update driver status to string passed in */
  const updateDriverStatus = async (driver, status, callback) => {
    try {
      const res = await apolloClient.mutate({
        mutation: gql`
          mutation updateDriverStatus($driverId: bigint!, $status: String!) {
            update_drivers(where: { id: { _eq: $driverId } }, _set: { status: $status }) {
              affected_rows
              returning {
                id
                status
              }
            }
          }
        `,
        variables: { driverId: driver.id, status },
      });

      if (getPropValue(res, `data.update_drivers.affected_rows`) > 0) {
        log && console.log(`Driver #${driver.id} status set to "${status}"!`);
        if (callback) callback();
        return true;
      }
      console.error(`Failed to update driver status!`);
      return false;
    } catch (err) {
      console.error(`Failed to update driver status:`, err);
      return false;
    }
  };

  /** Update driver verification to object passed in */
  const updateDriverVerification = async (driver, verificationAddition, callback) => {
    try {
      let verification = driver.verification || {};
      verification = { ...verification, ...verificationAddition };

      const res = await apolloClient.mutate({
        mutation: gql`
          mutation updateDriverVerification($driverId: bigint!, $verification: jsonb!) {
            update_drivers(where: { id: { _eq: $driverId } }, _set: { verification: $verification }) {
              affected_rows
              returning {
                id
                verification
              }
            }
          }
        `,
        variables: { driverId: driver.id, verification },
      });

      if (getPropValue(res, `data.update_drivers.affected_rows`) > 0) {
        log && console.log(`Driver #${driver.id} verification updated!`);
        if (callback) callback();
        return true;
      }
      console.error(`Failed to update driver verification!`);
      return false;
    } catch (err) {
      console.error(`Failed to update driver verification:`, err);
      return false;
    }
  };

  /** Update driver status string and verification object */
  const updateDriverStatusAndVerification = async (driver, status, verificationAddition, callback) => {
    try {
      let verification = driver.verification || {};
      verification = { ...verification, ...verificationAddition };

      const res = await apolloClient.mutate({
        mutation: gql`
          mutation updateDriverStatusAndVerification($driverId: bigint!, $status: String!, $verification: jsonb!) {
            update_drivers(where: { id: { _eq: $driverId } }, _set: { status: $status, verification: $verification }) {
              affected_rows
              returning {
                id
                status
                verification
              }
            }
          }
        `,
        variables: { driverId: driver.id, status, verification },
      });

      if (getPropValue(res, `data.update_drivers.affected_rows`) > 0) {
        log && console.log(`Driver #${driver.id} status set to "${status}" and verification updated!`);
        if (callback) callback();
        return true;
      }
      console.error(`Failed to update driver status and verification!`);
      return false;
    } catch (err) {
      console.error(`Failed to update driver status and verification:`, err);
      return false;
    }
  };

  // Return hook logic
  return {
    proceedToPrevWorkflow,
    proceedToNextWorkflow,
    buildLaunchpadGroups,
    updateDriverStatus,
    updateDriverVerification,
    updateDriverStatusAndVerification,
  };
}
