import React, {useEffect, useContext, useState} from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'
import { getAuth } from 'firebase/auth'
import  Loading  from './Loading'
import LoginRedirect from './LoginRedirect'

import { onAuthStateChanged } from 'firebase/auth'

const PrivateRoute = ({ component, match, ...args }) => {
  const firebase = getAuth();
  const auth0 = useAuth0();

  let log = false;

  useEffect(() => {
    onAuthStateChanged(firebase, user => {
      if (user) log && console.log('Firebase user found', user);
    });
    if (auth0.user) {
      log && console.log('Auth0 user found');
    }
  }, []);

  return firebase.currentUser === 'undefined' || auth0.user === 'undefined' ? (
    <Loading />
  ) : firebase.currentUser || auth0.user ? (
    <Route component={component} match={match} {...args} />
  ) : (
    <Route component={LoginRedirect} />
  );
};

export default PrivateRoute

