//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';

import { makeStyles, Icon } from '@material-ui/core';

import MoveTile from './MoveTile';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTileLinked({ move }) {
  const cls = useStyles();

  return (
    <div className={cls.moves}>
      <MoveTile move={move} />

      {move?.childMoves
        ? move?.childMoves?.map((childMove, i) => (
            <div key={`child-move-${childMove.id}`} className={cls.link}>
              <Icon className={cls.linkIcon}>link</Icon>
              <MoveTile move={childMove} />
            </div>
          ))
        : null}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  moves: {},
  link: {
    position: 'relative',
  },
  linkIcon: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 32,
    color: theme.palette.text.disabled,
  },
}));
