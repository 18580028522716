import React from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';

////////// COMPONENT //////////
export default function MoveDetailsInfo(props) {
  const cls = useStyles();
  const { move } = props;

  const { getFormattedCombinedStatusFromMove, getFormattedVehicleFromMove } = useTools();

  function isConsumer() {
    if (
      (move.consumer_name || move.consumer_phone) &&
      ['delivery successful', 'canceled', 'cancelled'].indexOf(move.status) >= 0
    )
      return true;
    else return false;
  }

  return (
    <>
      <div className={cls.main}>
        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                Move #{move.id}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Tooltip placement='top' title='Move Date'>
                <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>
                  {moment(move.pickup_started ? move.pickup_started : move.pickup_time).format(`MM/DD/YYYY`)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip placement='top-end' title='Move ID'>
                <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>
                  {getFormattedCombinedStatusFromMove(move)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <div className={cls.break} />

          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {!isConsumer() ? getFormattedVehicleFromMove(move) : 'Info expired'}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Transmission:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {!isConsumer() ? (move.manual_flag ? `Manual` : `Automatic`) : 'Info expired'}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>VIN:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {!isConsumer() ? (move.vehicle_vin ? move.vehicle_vin : `-`) : 'Info expired'}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Stock:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {!isConsumer() ? (move.vehicle_stock ? move.vehicle_stock : `-`) : 'Info expired'}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Reference&nbsp;#:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {!isConsumer() ? (move.reference_num ? move.reference_num : `-`) : 'Info expired'}
                </Typography>
              </div>
            </Grid>

            <div className={cls.hiddenLineBreak} />

            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.driver_name ? move.driver_name : `HopDriver`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Dealer:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {move.customer.name ? move.customer.name : `Unknown Dealer`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Dealer&nbsp;Contact:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.dealer_contact ? move.dealer_contact : `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Drive&nbsp;Distance:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {move.lane.distance_miles ? `${move.lane.distance_miles} miles` : `-`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Notes/Instructions:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.move_details ? move.move_details : `-`}</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.divider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));
