export const stepTypes = Object.freeze({
  GROUP: 'group',
  PHOTO: 'photo',
  PHOTOS: 'photos',
  SIGNATURE: 'signature',
  YEAR: 'year',
  AUTOCOMPLETE: 'autocomplete',
  TEXT: 'text',
  BARCODE: 'barcode',
});
