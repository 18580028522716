export const todayAs_DDMMYYYY_withSlashes = () => {
  let date = new Date();
  let dateString = date.toLocaleDateString('en-GB', {
    // you can use undefined as first argument
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return dateString;
};

export const todayAs_YYYYMMDD_withDashes = () => {
  let date = new Date();
  return date.toISOString().split('T')[0];
};

export const todayAs_YYYYMMDD_withSlashes = () => {
  let dateString = todayAs_YYYYMMDD_withDashes();
  return dateString.replace(/-/g, '/');
};

export const todayAs_DDMMYYYY_withDashes = () => {
  let dateString = todayAs_DDMMYYYY_withSlashes();
  return dateString.replace(/\//g, '-');
};

export const todayAs_DDMMYYYY_withNoSeparator = () => {
  let dateString = todayAs_DDMMYYYY_withSlashes();
  return dateString.replace(/\//g, '');
};
