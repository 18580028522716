import React, { useEffect } from 'react';
import { makeStyles, Typography, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import { stepTypes } from '../../utils/workflow';
import Step from './Step';

export default function Group({
  workflowStepsRef,
  getAuthToken,
  step,
  isTakingPhoto = false,
  setIsTakingPhoto,
  onPhotoCapture,
  onNameCapturedPhoto,
  invalidStepIds = [],
}) {
  const cls = useStyles();

  const doesContainPhotoSteps = step.steps.some(
    step => step.type === stepTypes.PHOTO || step.type === stepTypes.PHOTOS
  );

  return (
    <>
      <Spacer size='lg' />

      <div className={cls.paper}>
        <Typography className={cls.paperTitleTxt}>{step.label}</Typography>

        {/* {doesContainPhotoSteps && (
          <div className={cls.cameraButton} onClick={() => setIsTakingPhoto(true)}>
            <Icon>photo_camera</Icon>
          </div>
        )} */}

        <Spacer />

        {step.steps.map((step, i) => (
          <Step
            key={`workflow-step-${step.id}`}
            workflowStepsRef={workflowStepsRef}
            getAuthToken={getAuthToken}
            isTakingPhoto={isTakingPhoto}
            setIsTakingPhoto={setIsTakingPhoto}
            onPhotoCapture={onPhotoCapture}
            onNameCapturedPhoto={onNameCapturedPhoto}
            invalidStepIds={invalidStepIds}
            step={step}
            index={i}
            inGroup={true}
          />
        ))}
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  paperTitleTxt: {
    marginBottom: theme.spacing(1),
    fontSize: 18,
    fontWeight: 600,
  },
  paperKeyTxt: {
    lineHeight: 1.333,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  cameraButton: {
    position: 'absolute',
    right: 15,
    top: -10,
    height: 54,
    borderRadius: 100,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: 15,
    boxShadow: theme.shadow.harsh,
  },
}));
