//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TextField, InputAdornment, Icon } from '@material-ui/core';

import MovesTable from './MovesTable';

//////////////////////// COMPONENT ////////////////////////

export default function MovesContent({ moves }) {
  const cls = useStyles();

  const [search, setSearch] = React.useState(``);

  return (
    <div className={cls.root}>
      <div className={cls.toolbar}>
        <TextField
          fullWidth
          label={`Search`}
          placeholder={`Search for workable moves...`}
          variant='outlined'
          size='small'
          value={search}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  search
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <MovesTable moves={moves} search={search} />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
  toolbar: {
    zIndex: 10,
    position: 'sticky',
    top: 0,
    padding: theme.spacing(2, 1.5),
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
}));
