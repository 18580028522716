import React from 'react';
import { makeStyles, Typography, Icon } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Spacer } from '@hopdrive/storybook';
import * as Workflows from '@hopdrive/workflows';
import { todayAs_YYYYMMDD_withSlashes } from '../../../utils/date';
import { ENV, S3_VEHICLE_PHOTOS_BUCKET_NAME } from '../../../utils/constants';
import * as NetworkUtils from '../../../utils/network';

export default function SignatureCapture({
  workflowStepsRef,
  step,
  index,
  value,
  isValid,
  inGroup,
  onPhotoCapture,
  onNameCapturedPhoto,
  getAuthToken,
}) {
  const cls = useStyles();

  const [photoDataUrl, setPhotoDataUrl] = React.useState(''); // data url version of local file
  const [publicPhotoUrl, setPublicPhotoUrl] = React.useState(value);
  const [progress, setProgress] = React.useState(100);
  const [loading, setLoading] = React.useState(false);
  const [failed, setFailed] = React.useState(false);

  let sigPad;

  const save = async () => {
    try {
      const fileType = 'image/jpg';
      const dataUrl = sigPad.getTrimmedCanvas().toDataURL(fileType);
      const base64 = dataUrl.split(',')[1];

      console.log(`Uploading signature:`, {
        photoDataUrl,
        progress,
        loading,
        failed,
        publicPhotoUrl,
      });

      setLoading(true);
      setFailed(false);

      let filename = `${step?.id}.${new Date().getTime()}.png`;
      if (onNameCapturedPhoto && typeof onNameCapturedPhoto === 'function') {
        const ret = await onNameCapturedPhoto(step);
        if (ret) filename = ret;
      }
      console.log(`Upload using ${filename} as signature filename`);

      let dateString = todayAs_YYYYMMDD_withSlashes();

      //TODO: Remove the driver portal prefix
      let fileFullName = `driver_portal/${ENV}/${dateString}/${filename}`;

      const token = await getAuthToken();
      console.log(`[SignatureCapture] Token: ${token}`);

      const uploadURL = await NetworkUtils.getS3PresignedUrl(
        S3_VEHICLE_PHOTOS_BUCKET_NAME,
        token,
        '',
        fileFullName,
        fileType
      );

      const { uploadSpeedMbps } = await NetworkUtils.uploadBase64File(base64, filename, uploadURL, {
        'Content-Type': fileType,
      });

      let uploadedPhotoPublicUrl = uploadURL.split('?')[0];

      console.log(
        `S3 upload complete for signature to ${uploadedPhotoPublicUrl}\n`,
        JSON.stringify({ uploadSpeedMbps }, null, 2)
      );

      const didUploadSucceed = await NetworkUtils.urlExists(uploadedPhotoPublicUrl);
      console.log(`Signature upload succeeded: ${didUploadSucceed} at ${uploadedPhotoPublicUrl}`);
      if (!didUploadSucceed) throw new Error(`Failed to upload signature to S3.`);
      if (onPhotoCapture) await onPhotoCapture(step, uploadedPhotoPublicUrl);

      setPublicPhotoUrl(uploadedPhotoPublicUrl);

      Workflows.setValueByStepId(workflowStepsRef.current, step.id, uploadedPhotoPublicUrl);
      console.log(`[SignatureCapture] Setting workflow step value for step ${step.id} to ${uploadedPhotoPublicUrl}`);
    } catch (error) {
      console.error(`Failed to upload photo:`, error);
      setFailed(true);
    } finally {
      setLoading(false);
      setProgress(100);
    }
  };

  return (
    <>
      {inGroup && index > 0 ? <Spacer size='lg' /> : null}

      <Typography className={isValid ? cls.headTxt : cls.headTxtError}>
        {step?.label}
        <span className={cls.required}>{step?.required ? `*` : ``}</span>
      </Typography>

      <Typography className={cls.subheadTxt}>{step.description}</Typography>

      {publicPhotoUrl ? (
        <div style={{ display: 'flex', height: 200, flexDirection: 'column', alignItems: 'left' }}>
          <img
            src={publicPhotoUrl}
            alt='Signature'
            style={{
              maxWidth: '100%',
              borderRadius: 20,
              marginBottom: 10,
              border: '1px solid #ccc',
              padding: 4,
            }}
          />
        </div>
      ) : (
        <SignatureCanvas
          backgroundColor='black'
          penColor='white'
          canvasProps={{ className: cls.signatureCanvas }}
          ref={ref => {
            sigPad = ref;
          }}
        />
      )}

      <Spacer />

      {publicPhotoUrl ? (
        <Button
          fullWidth
          className={cls.buttonPreview}
          color='secondary'
          size='small'
          onClick={() => {
            setPublicPhotoUrl('');
          }}
        >
          Resign
        </Button>
      ) : (
        <Button fullWidth className={cls.buttonPreview} color='secondary' size='small' onClick={save}>
          Save
        </Button>
      )}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 24,
  },
  buttonClear: {
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
  buttonPreview: {
    borderRadius: 4,
  },
  buttonRetake: {
    borderRadius: 4,
  },
  signatureCanvas: {
    width: '100%',
    minHeight: 200,
    borderRadius: 8,
  },
  input: {
    width: '100%',
    minHeight: 32,
    padding: '4px 8px',
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
    '&:focus': {
      padding: '3px 7px',
      border: `2px solid ${theme.palette.text.primary}`,
    },
  },
  headTxt: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  headTxtError: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subheadTxt: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  inputError: {
    width: '100%',
    minHeight: 32,
    padding: '3px 7px',
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
  },
  required: {
    display: `inline-block`,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
}));
