import React from 'react';
import dayjs from 'dayjs';
import {
  makeStyles,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Icon,
  Divider,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../../hooks/useTools';

export default function APDetailTable({ driverPay }) {
  const cls = useStyles();

  const { getRegionNameFromMove, formatUSD } = useTools();

  if (driverPay && driverPay.appayments && driverPay.appayments.length) {
    const appayments = driverPay.appayments.sort((a, b) => {
      if (a.move.pickup_time > b.move.pickup_time) return 1;
      if (a.move.pickup_time < b.move.pickup_time) return -1;
      return 0;
    });

    const getBorderStyle = index => {
      return index === appayments.length - 1 ? { borderBottom: `none` } : null;
    };

    const getTotalTime = () => {
      const dur = dayjs.duration(driverPay.total_duration_sec, `seconds`);
      let durStr = ``;

      if (dur.asDays() > 1) durStr += `${dur.$d.days} days, `;
      if (dur.asHours() > 1) durStr += `${dur.$d.hours} hrs, `;
      if (dur.asMinutes() > 1) durStr += `${dur.$d.minutes} mins, `;
      if (dur.asSeconds() > 1) durStr += `${dur.$d.seconds} secs`;

      return durStr;
    };

    return (
      <Grid container>
        <Grid item xs>
          <div className={cls.root}>
            <Grid container direction='row' justifyContent='space-between' spacing={2}>
              <Grid item md={4} xs={12}>
                <Typography className={cls.reducedFont} style={{ fontWeight: 'bold' }} variant='h6'>
                  FROM
                </Typography>
                <Typography className={cls.reducedFont}>1717 East Cary St, Suite 2120</Typography>
                <Typography className={cls.reducedFont}>Richmond, VA 23223</Typography>
                <Typography className={cls.reducedFont}>1-800-913-7674</Typography>
                <Typography className={cls.reducedFont}>
                  <a href='https://www.hopdrive.com'>hopdrive.com</a>
                </Typography>
              </Grid>

              <Grid item md={4} xs={12}>
                <Typography display='inline' className={cls.detailItem}>
                  Total Drive Count
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {driverPay.drive_move_count}
                </Typography>

                <div className={cls.line} />

                <Typography display='inline' className={cls.detailItem}>
                  Total Ride Count
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {driverPay.ride_move_count}
                </Typography>

                <div className={cls.line} />

                <Typography display='inline' className={cls.detailItem}>
                  Total Accessorial Count
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {driverPay.accessorial_count}
                </Typography>

                <div className={cls.line} />

                <Typography display='inline' className={cls.detailItem}>
                  Total Miles
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {driverPay.total_miles.toFixed(1)} mi
                </Typography>

                <div className={cls.line} />

                <Typography display='inline' className={cls.detailItem}>
                  Total Time
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {getTotalTime()}
                </Typography>
              </Grid>

              <Grid item md={4} xs={12}>
                <Typography display='inline' className={cls.detailItem}>
                  Driver
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {driverPay.driver_name}
                </Typography>

                <div className={cls.line} />

                <Typography display='inline' className={cls.detailItem}>
                  Earnings Date Range
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {dayjs(driverPay.start_time).format('MM/DD/YYYY')} - {dayjs(driverPay.end_time).format('MM/DD/YYYY')}
                </Typography>

                <div className={cls.line} />

                <Typography display='inline' className={cls.detailItem}>
                  Average Per Move Rate
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {formatUSD(driverPay.pay_per_move)}/move
                </Typography>

                <div className={cls.line} />

                <Typography display='inline' className={cls.detailItem}>
                  Average Per Hour Rate
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {formatUSD(driverPay.pay_per_hour)}/hr
                </Typography>

                <div className={cls.line} />

                <Typography display='inline' className={cls.detailItem}>
                  Average Per Mile Rate
                </Typography>
                <Typography display='inline' className={cls.detailValue}>
                  {formatUSD(driverPay.pay_per_mile)}/mi
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider className={cls.horLine} />
                <Typography display='inline' className={cls.earningsTotal}>
                  TOTAL
                </Typography>
                <Typography display='inline' className={cls.earningsSum}>
                  {formatUSD(driverPay.total)}
                </Typography>
                <Divider className={cls.horLine} />
              </Grid>
            </Grid>
          </div>

          <Spacer />

          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Paid</TableCell>
                  <TableCell align='left'>Move&nbsp;ID</TableCell>
                  <TableCell align='left'>Accessorial&nbsp;ID</TableCell>
                  <TableCell align='left'>Pickup Time</TableCell>
                  <TableCell align='left'>Type</TableCell>
                  <TableCell align='left'>Region</TableCell>
                  <TableCell align='left'>Description</TableCell>
                  <TableCell align='right'>Distance</TableCell>
                  <TableCell align='right'>Total&nbsp;Pay</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appayments.map((appayment, i) => {
                  return (
                    <React.Fragment key={`appayment-${appayment.id}-${appayment.move.id}-row`}>
                      <TableRow key={`table-row-${appayment.id}`}>
                        <TableCell align='left' style={getBorderStyle(i)}>
                          {appayment.status === `paid` ? (
                            <Tooltip title='Paid'>
                              <Icon className={cls.paidIcon}>check_circle</Icon>
                            </Tooltip>
                          ) : (
                            <Tooltip title='Unpaid'>
                              <Icon className={cls.unpaidIcon}>do_not_disturb_on</Icon>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align='left' style={getBorderStyle(i)}>
                          {appayment.move.id}
                        </TableCell>
                        <TableCell align='left' style={getBorderStyle(i)}>
                          {appayment.accessorial ? appayment.accessorial.id : `-`}
                        </TableCell>
                        <TableCell align='left' style={getBorderStyle(i)}>
                          {dayjs(appayment.move.pickup_time).format('M/DD/YYYY hh:mm A')}
                        </TableCell>
                        <TableCell align='left' style={getBorderStyle(i)}>
                          <Chip
                            label={
                              appayment.type === 'move pay'
                                ? appayment.move.move_type
                                : appayment.type === 'accessorial'
                                ? `accessorial`
                                : appayment.notes
                            }
                            variant={appayment.type === 'move pay' ? 'default' : 'outlined'}
                            icon={
                              <Icon fontSize='small'>
                                {appayment.type === 'move pay'
                                  ? appayment.move.move_type === 'drive'
                                    ? 'drive_eta'
                                    : 'local_taxi'
                                  : 'monetization_on'}
                              </Icon>
                            }
                            size='small'
                            color={
                              appayment.type === 'move pay' && appayment.move.move_type === 'drive'
                                ? 'primary'
                                : appayment.type === 'move pay' && appayment.move.move_type === 'ride'
                                ? 'secondary'
                                : 'default'
                            }
                          />
                        </TableCell>
                        <TableCell align='left' style={getBorderStyle(i)}>
                          {getRegionNameFromMove(appayment.move)}
                        </TableCell>
                        <TableCell align='left' style={getBorderStyle(i)}>
                          {appayment.type === 'move pay'
                            ? appayment.move.lane.description
                            : appayment.type === 'accessorial'
                            ? `(${appayment.accessorial.code}) ${appayment.accessorial.notes}`
                            : appayment.notes}
                        </TableCell>
                        <TableCell align='right' style={getBorderStyle(i)}>
                          {appayment.move.lane.distance_miles + ' mi'}
                        </TableCell>
                        <TableCell align='right' style={getBorderStyle(i)}>
                          <Chip
                            size='small'
                            label={formatUSD(appayment.amount, { removeSign: true })}
                            icon={<Icon>attach_money</Icon>}
                            classes={{
                              label: cls.chipLabel,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
  return null;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  logoHeaderPrintOnly: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
  grid: {
    width: '100%',
    padding: theme.spacing(2),
  },
  earningsHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '180%',
  },
  reducedFont: {
    fontSize: '100%',
    '@media print': {
      fontSize: '90%',
    },
  },
  detailItem: {
    fontSize: '100%',
    '@media print': {
      fontSize: '90%',
    },
    fontWeight: 'bold',
    textAlign: 'left',
  },
  detailValue: {
    fontSize: '100%',
    '@media print': {
      fontSize: '90%',
    },
    textAlign: 'left',
    float: 'right',
  },
  horLine: {
    margin: theme.spacing(0.3),
    backgroundColor: 'black',
  },
  earningsTotal: {
    marginLeft: theme.spacing(1),
    fontSize: '225%',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  earningsSum: {
    marginRight: theme.spacing(1),
    fontSize: '225%',
    textAlign: 'left',
    float: 'right',
    fontWeight: 'bold',
  },

  line: {
    width: '100%',
    height: 1,
    background: theme.palette.divider,
  },

  chipLabel: {
    marginTop: 2,
    marginLeft: -4,
    fontSize: 14,
    color: theme.palette.text.primary,
  },

  paidIcon: {
    display: 'block',
    color: theme.palette.success.main,
  },
  unpaidIcon: {
    display: 'block',
    color: theme.palette.text.disabled,
  },
}));
