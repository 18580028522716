import React, { useContext } from 'react';
import GlobalContext from '../../GlobalContext';
import { Container } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import PageRoot from '../reusable/PageRoot';

import FaqSearch from './faq/FaqSearch';
import FaqTable from './faq/FaqTable';
import Loading from '../utils/Loading';
import StaticAlert from '../utils/StaticAlert';

////////// COMPONENT //////////
export default function Faq() {
  const { handlePage } = useContext(GlobalContext);
  handlePage();

  return (
    <>
      <FaqSearch />
      <Query query={GET_FAQS}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) {
            console.log(`Failed to load questions:`, error);
            return <StaticAlert type='error' message='Failed to load questions.' />;
          }
          if (data) {
            let faqs = data.faqs;

            return (
              <>
                {faqs && faqs.length > 0 && faqs !== null ? (
                  <PageRoot>
                    <Container maxWidth='md'>
                      <FaqTable faqs={faqs} />
                    </Container>
                  </PageRoot>
                ) : (
                  <StaticAlert type='warning' message='No questions found.' />
                )}
              </>
            );
          } else return <StaticAlert type='error' message='Failed to load data.' />;
        }}
      </Query>
    </>
  );
}

////////// GRAPHQL //////////
const GET_FAQS = gql`
  query get_faqs {
    faqs(where: { active: { _eq: true }, _or: [{ user: { _is_null: true } }, { user: { _eq: "driver" } }] }) {
      id
      active
      user
      question
      answer
      new_flag
    }
  }
`;
