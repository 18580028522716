import React from 'react';
import dayjs from 'dayjs';
import GlobalContext from '../../../GlobalContext';
import { makeStyles } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { Query } from 'react-apollo';

import sdk from '@hopdrive/sdk';
import useDriverPay from './useDriverPay';

import DefaultEmptyFallback from '../../reusable/Fallbacks/DefaultEmptyFallback';
import DefaultErrorFallback from '../../reusable/Fallbacks/DefaultErrorFallback';
import Toolbar from '../../reusable/Toolbar';

import DriverPayFilter from './DriverPayFilter';
import APDetailTable from './APDetailTable';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function DriverPay(props) {
  const ctx = React.useContext(GlobalContext);
  ctx.handlePage();
  const cls = useStyles();

  const GET_APPAYMENTS_BY_RANGE_AND_DRIVER_ID_NO_STATUS = sdk.wallet.GET_APPAYMENTS_BY_RANGE_AND_DRIVER_ID_NO_STATUS;
  const buildDriverPayObject = sdk.wallet.buildDriverPayObject;

  const { getDefaultRange, getDefaultStart, getDefaultEnd } = useDriverPay();

  // Filter State
  const [range, setRange] = React.useState(getDefaultRange());
  const [start, setStart] = React.useState(getDefaultStart());
  const [end, setEnd] = React.useState(getDefaultEnd());

  // Control range picker
  const handleRangeChange = value => {
    if (value) {
      localStorage.setItem(`driver-pay-range`, value);
      setRange(value);
    }
  };

  // Control start date
  const handleStartChange = value => {
    if (value) {
      localStorage.setItem(`driver-pay-start`, value);
      setStart(value);
    }
  };

  // Control end date
  const handleEndChange = value => {
    if (value) {
      localStorage.setItem(`driver-pay-end`, value);
      setEnd(value);
    }
  };

  // Handle clearing the filters stored in state
  const handleClearFilters = () => {
    localStorage.removeItem(`driver-pay-range`);
    localStorage.removeItem(`driver-pay-start`);
    localStorage.removeItem(`driver-pay-end`);

    setRange(`week`);
    setStart(dayjs().startOf(`week`).format());
    setEnd(dayjs().endOf(`week`).format());
  };

  // fetchPolicy: `network-only`,

  return (
    <>
      {ctx && ctx.userAuth && (
        <Query
          query={GET_APPAYMENTS_BY_RANGE_AND_DRIVER_ID_NO_STATUS}
          variables={{ start: start, end: end, driverId: ctx.driverId }}
        >
          {({ loading, error, data, refetch }) => {
            // Handle refetch callback to pass as props
            const handleRefetch = async () => {
              refetch();
            };

            if (loading) {
              return (
                <div className={cls.root}>
                  <Toolbar fullscreen title={`My Pay`} onClear={handleClearFilters} />
                  <DriverPayFilter
                    range={range}
                    start={start}
                    end={end}
                    onRangeChange={handleRangeChange}
                    onStartChange={handleStartChange}
                    onEndChange={handleEndChange}
                  />
                  <Loading position='fixed' />
                </div>
              );
            }

            if (error) {
              console.error(`Error fetching driver pay:`, error);
              Sentry.captureException(error);
              return (
                <div className={cls.root}>
                  <Toolbar fullscreen title={`My Pay`} onClear={handleClearFilters} refetch={handleRefetch} />
                  <DriverPayFilter
                    range={range}
                    start={start}
                    end={end}
                    onRangeChange={handleRangeChange}
                    onStartChange={handleStartChange}
                    onEndChange={handleEndChange}
                  />
                  <DefaultErrorFallback message='ERROR FETCHING PAY' />
                </div>
              );
            }
            if (!data || !data.appayments || !data.appayments.length) {
              return (
                <div className={cls.root}>
                  <Toolbar fullscreen title={`My Pay`} onClear={handleClearFilters} refetch={handleRefetch} />
                  <DriverPayFilter
                    range={range}
                    start={start}
                    end={end}
                    onRangeChange={handleRangeChange}
                    onStartChange={handleStartChange}
                    onEndChange={handleEndChange}
                  />
                  <DefaultEmptyFallback message='NO PAY WITHIN DATE RANGE' />
                </div>
              );
            }

            // Capture appayments
            const appayments = data.appayments;
            log && console.log(`AP Payments:`, appayments);

            //Filter out work that hasn't yet been completed
            let appaymentsFiltered = appayments.filter(appayment => 
              appayment.status !== 'estimated'
            )

            // Build driver pay array based on appayments
            const driverPay = buildDriverPayObject(appaymentsFiltered);
            log && console.log(`Driver Pay:`, driverPay);
            console.log(`Driver Pay:`, driverPay);

            // List of table-level actions
            const actions = [];

            return (
              <div className={cls.root}>
                <Toolbar
                  fullscreen
                  title={`My Pay`}
                  onClear={handleClearFilters}
                  refetch={handleRefetch}
                  actions={actions}
                />
                <DriverPayFilter
                  range={range}
                  start={start}
                  end={end}
                  onRangeChange={handleRangeChange}
                  onStartChange={handleStartChange}
                  onEndChange={handleEndChange}
                />
                <APDetailTable driverPay={driverPay} />
              </div>
            );
          }}
        </Query>
      )}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
  btnIconL: {
    marginTop: -2,
    marginRight: 8,
    fontSize: 16,
  },
}));
