import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import * as Workflows from '@hopdrive/workflows';
import Step from './Step';

export default function useWorkflow({ getAuthToken, onSubmit }) {
  const workflowStepsRef = useRef([]);
  const [invalidStepIds, setInvalidStepIds] = React.useState([]);

  return {
    workflowStepsRef,
    Workflow: ({ onPhotoCapture, onNameCapturedPhoto, workflowSteps, defaultStepValues }) => (
      <WorkflowImpl
        workflowStepsRef={workflowStepsRef}
        getAuthToken={getAuthToken}
        onPhotoCapture={onPhotoCapture}
        onNameCapturedPhoto={onNameCapturedPhoto}
        workflowSteps={workflowSteps}
        defaultStepValues={defaultStepValues}
        invalidStepIds={invalidStepIds}
      />
    ),
    submit: () => {
      console.log('Submitting workflow...');
      const validationResult = Workflows.validateSteps(workflowStepsRef.current, undefined);
      setInvalidStepIds(validationResult.invalidStepIds);
      if (onSubmit) onSubmit(validationResult);
      return validationResult;
    },
  };
}

export function WorkflowImpl({
  workflowStepsRef,
  workflowSteps,
  getAuthToken,
  onPhotoCapture,
  onNameCapturedPhoto,
  defaultStepValues,
  invalidStepIds,
}) {
  const cls = useStyles();
  const [isTakingPhoto, setIsTakingPhoto] = React.useState(false);
  const [workflowStepsState, setWorkflowStepsState] = React.useState();

  useEffect(() => {
    let tempWorkflowSteps = workflowSteps;
    Workflows.addParentStepIds(tempWorkflowSteps);
    Object.keys(defaultStepValues).forEach(stepId => {
      const step = Workflows.getStepByStepId(tempWorkflowSteps, stepId);
      if (step) {
        Workflows.setValueByStepId(tempWorkflowSteps, stepId, defaultStepValues[stepId]);
      }
    });
    workflowStepsRef.current = tempWorkflowSteps;
    setWorkflowStepsState(tempWorkflowSteps);
  }, [workflowSteps]);

  return (
    <>
      <div className={cls.main}>
        {workflowStepsState &&
          workflowStepsState.map((step, i) => {
            return (
              <Step
                key={`workflow-step-${step.id}`}
                index={i}
                workflowStepsRef={workflowStepsRef}
                getAuthToken={getAuthToken}
                isTakingPhoto={isTakingPhoto}
                setIsTakingPhoto={setIsTakingPhoto}
                onPhotoCapture={onPhotoCapture}
                onNameCapturedPhoto={onNameCapturedPhoto}
                invalidStepIds={invalidStepIds}
                step={step}
                inGroup={false}
              />
            );
          })}
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  main: {},
}));
