import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import FaqRow from './FaqRow';

////////// COMPONENT //////////
export default function FaqTable(props) {
  const cls = useStyles();
  const { faqs } = props;

  const [expanded, setExpanded] = useState(0);

  const handleExpanded = id => {
    if (id === expanded) setExpanded(0);
    else setExpanded(id);
  };

  // const handleSubmitNew = () => {
  //   console.log(`no`);
  // }

  return (
    <>
      <div className={cls.table}>
        {faqs && faqs.length > 0
          ? faqs.map(faq => <FaqRow key={faq.id} faq={faq} expanded={expanded} handleExpanded={handleExpanded} />)
          : null}
      </div>
      {/* <Button className={cls.btn} fullWidth variant="contained" color="primary" onClick={handleSubmitNew}>Submit&nbsp;a&nbsp;New&nbsp;Question</Button> */}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  table: {},
  btn: {
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
}));
