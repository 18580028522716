//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';

import MoveTileHangtagTooltip from './MoveTileHangtagTooltip';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTileHangtag({ hangtag }) {
  const cls = useStyles();

  const getHangtagStyle = () => {
    let styles = [cls.hangtag];
    if (hangtag.status === `assigned` || hangtag.status === `scanned`) styles.push(cls.hangtagProgress);
    if (hangtag.status === `completed`) styles.push(cls.hangtagCompleted);
    return clsx(styles);
  };

  const getHangtagTextStyle = () => {
    let styles = [cls.hangtagTxt];
    if (hangtag.rear_code.length >= 3) styles.push(cls.hangtagTxt3);
    if (hangtag.rear_code.length === 2) styles.push(cls.hangtagTxt2);
    if (hangtag.rear_code.length === 1) styles.push(cls.hangtagTxt1);
    return clsx(styles);
  };

  return (
    <MoveTileHangtagTooltip hangtag={hangtag}>
      <div className={getHangtagStyle()}>
        <div className={cls.hangtagPunch} />
        <Typography className={getHangtagTextStyle()}>{hangtag.rear_code}</Typography>
      </div>
    </MoveTileHangtagTooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  hangtag: {
    zIndex: 276,
    position: 'absolute',
    overflow: 'hidden',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: -8,
    left: '50%',
    transformOrigin: '50% 20%',
    width: 28,
    height: 40,
    // border: `2px solid ${theme.palette.primary.darker}`,
    borderRadius: 3,
    background: theme.palette.primary.main,
    boxShadow: '0 0 2px #00000080',
    cursor: 'pointer',
  },
  hangtagProgress: {
    animation: 'none',
  },
  hangtagCompleted: {
    animation: '$wiggle 5s ease-in-out infinite',
  },

  hangtagPunch: {
    position: 'absolute',
    top: '18%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 7,
    height: 7,
    borderRadius: 7,
    background: theme.palette.primary.darker,
  },

  hangtagTxt: {
    marginTop: 6,
    fontSize: 12,
    fontWeight: 900,
    color: theme.palette.text.contrast,
  },
  hangtagTxt1: {
    fontSize: 16,
  },
  hangtagTxt2: {
    fontSize: 14,
  },
  hangtagTxt3: {
    fontSize: 12,
  },

  '@keyframes wiggle': {
    '0%': {
      transform: 'translateX(-50%)',
    },
    '35%': {
      transform: 'translateX(-50%) rotate(0deg)',
    },
    '40%': {
      transform: 'translateX(-50%) rotate(8deg)',
    },
    '47.5%': {
      transform: 'translateX(-50%) rotate(-6deg)',
    },
    '52.5%': {
      transform: 'translateX(-50%) rotate(4deg)',
    },
    '60%': {
      transform: 'translateX(-50%) rotate(-2deg)',
    },
    '65%': {
      transform: 'translateX(-50%) rotate(0deg)',
    },
    '100%': {
      transform: 'translateX(-50%)',
    },
  },
}));
