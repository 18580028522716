import React, { useEffect } from 'react';
import { makeStyles, Typography, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import { stepTypes } from '../../utils/workflow';
import * as Workflows from '@hopdrive/workflows';
import StepControl from './StepControl';
import Group from './Group';

export default function Step({
  workflowStepsRef,
  getAuthToken,
  step,
  index,
  inGroup = false,
  isTakingPhoto = false,
  setIsTakingPhoto,
  onPhotoCapture,
  onNameCapturedPhoto,
  invalidStepIds = [],
}) {
  const cls = useStyles();

  const value = Workflows.getStepByStepId(workflowStepsRef.current, step.id)?.value;

  if (step.type === stepTypes.GROUP)
    return (
      <Group
        workflowStepsRef={workflowStepsRef}
        getAuthToken={getAuthToken}
        isTakingPhoto={isTakingPhoto}
        setIsTakingPhoto={setIsTakingPhoto}
        onPhotoCapture={onPhotoCapture}
        onNameCapturedPhoto={onNameCapturedPhoto}
        invalidStepIds={invalidStepIds}
        step={step}
      />
    );
  return (
    <>
      {!inGroup && (
        <>
          <Spacer size='lg' />

          <div className={cls.paper}>
            <StepControl
              workflowStepsRef={workflowStepsRef}
              getAuthToken={getAuthToken}
              isTakingPhoto={isTakingPhoto}
              setIsTakingPhoto={setIsTakingPhoto}
              onPhotoCapture={onPhotoCapture}
              onNameCapturedPhoto={onNameCapturedPhoto}
              step={step}
              index={index}
              value={value}
              isValid={!invalidStepIds?.includes(step?.id)}
              inGroup={inGroup}
            />
          </div>
        </>
      )}

      {inGroup && (
        <StepControl
          workflowStepsRef={workflowStepsRef}
          getAuthToken={getAuthToken}
          isTakingPhoto={isTakingPhoto}
          setIsTakingPhoto={setIsTakingPhoto}
          onPhotoCapture={onPhotoCapture}
          onNameCapturedPhoto={onNameCapturedPhoto}
          step={step}
          index={index}
          value={value}
          isValid={!invalidStepIds?.includes(step?.id)}
          inGroup={inGroup}
        />
      )}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  paperTitleTxt: {
    marginBottom: theme.spacing(1),
    fontSize: 18,
    fontWeight: 600,
  },
  paperKeyTxt: {
    lineHeight: 1.333,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  cameraButton: {
    position: 'absolute',
    right: 15,
    top: -10,
    height: 54,
    borderRadius: 100,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: 15,
    boxShadow: theme.shadow.harsh,
  },
}));
