import React from 'react';
import GlobalContext from '../../../GlobalContext';
import { Container } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { useTools } from '../../../hooks/useTools';

import PageRoot from '../../reusable/PageRoot';
import StaticAlert from '../../utils/StaticAlert';

import WalletCreate from './WalletCreate';
import WalletManagement from './WalletManagement';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function Wallet(props) {
  const { driverId } = React.useContext(GlobalContext);
  const { spreadDriverObj } = useTools();

  return (
    <PageRoot>
      <Container maxWidth='md'>
        {driverId ? (
          <Query query={GET_DRIVER} variables={{ driverId: driverId }}>
            {({ loading, error, data, refetch }) => {
              // LOADING STATE //
              if (loading) return <Loading position='relative' />;

              // ERROR STATE //
              if (error) {
                console.error('Failed to load driver info for wallet:', error);
                return <StaticAlert type='error' message='Failed to load driver info for wallet.' />;
              }

              // EMPTY STATE //
              if (!data || !data.drivers || !data.drivers.length) {
                return <StaticAlert type='info' message='No driver info available.' />;
              }

              // DATA STATE //
              const refetchDriver = () => refetch();

              let driver = data.drivers[0];
              driver = spreadDriverObj(driver);
              log && console.log(`Driver:`, driver);
              return (
                <>
                  {!driver.wallet_token || !driver.bank_account_token ? (
                    <WalletCreate driver={driver} refetchDriver={refetchDriver} />
                  ) : null}
                  {driver.wallet_token && driver.bank_account_token ? (
                    <WalletManagement driver={driver} refetchDriver={refetchDriver} />
                  ) : null}
                </>
              );
            }}
          </Query>
        ) : (
          <></>
        )}
      </Container>
    </PageRoot>
  );
}

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const GET_DRIVER = gql`
  query driver_wallet_getDriver($driverId: bigint!) {
    drivers(where: { id: { _eq: $driverId } }) {
      id
      bank_account_token
      config
      phone_type
      plate_id
      region_id
      status
      tax_class
      user_id
      vehicle_color
      vehicle_license_plate
      vehicle_license_plate_state
      vehicle_make
      vehicle_model
      vehicle_vin
      vehicle_year
      verification
      wallet_token
      created_at
      updated_at
      driverdetail {
        driver_id
        first_name
        last_name
        social_security
      }
      user {
        id
        active
        display_name
        email
        phone
      }
    }
  }
`;
