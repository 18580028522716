//////////////////////// DEPENDENCIES ////////////////////////

import dayjs from 'dayjs';

//////////////////////// COMPONENT ////////////////////////

export default function useDriverPay() {
  // Get local storage items
  const getDefaultRange = () => {
    const localRange = localStorage.getItem(`driver-pay-range`);
    if (localRange) return localRange;
    else return `week`;
  };
  const getDefaultStart = () => {
    const localRange = localStorage.getItem(`driver-pay-range`);
    const localStart = localStorage.getItem(`driver-pay-start`);

    if (!localRange) return dayjs().startOf(`week`).format();
    else if (localRange !== `custom`) return dayjs().startOf(localRange).format();
    else return dayjs(localStart).format();
  };
  const getDefaultEnd = () => {
    const localRange = localStorage.getItem(`driver-pay-range`);
    const localEnd = localStorage.getItem(`driver-pay-end`);

    if (!localRange) return dayjs().endOf(`week`).format();
    else if (localRange !== `custom`) return dayjs().endOf(localRange).format();
    else return dayjs(localEnd).format();
  };

  const getDateString = (range, activeRange, start, end) => {
    let str = null;

    // if (range === `week`) {
    //   if (range === activeRange) str = `${dayjs(start).format(`MM/DD/YYYY`)} - ${dayjs(end).format(`MM/DD/YYYY`)}`;
    //   else str = `${dayjs().startOf(`week`).format(`MM/DD/YYYY`)} - ${dayjs().endOf(`week`).format(`MM/DD/YYYY`)}`;
    // }

    // if (range === `month`) {
    //   if (range === activeRange) str = dayjs(start).format(`MMMM YYYY`);
    //   else str = dayjs().format(`MMMM YYYY`);
    // }

    // if (range === `year`) {
    //   if (range === activeRange) str = dayjs(start).format(`YYYY`);
    //   else str = dayjs().format(`YYYY`);
    // }

    // if (range === `custom`) str = `Use Pickers`;

    // if (str) return `(${str})`;
    return ``;
  };

  // Return additional logic
  return {
    getDefaultRange,
    getDefaultStart,
    getDefaultEnd,
    getDateString,
  };
}
