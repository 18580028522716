import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function Loading() {
  return (
    <CircularProgress
      disableShrink
      style={{
        display: 'block',
        margin: 'auto',
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
      }}
    />
  );
}
