//////////////////////// DEPENDENCIES ////////////////////////

import React, { useState, useContext } from 'react';
import {
  makeStyles,
  Typography,
  Container,
  TextField,
  Link,
  CssBaseline,
  InputAdornment,
  Icon,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import PasswordReset from './PasswordReset';
import PageRoot from '../reusable/PageRoot';
import GlobalContext from '../../GlobalContext';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

//////////////////////// COMPONENT ////////////////////////

function FirebaseLogin(props) {
  const firebaseAuth = getAuth();
  const auth0 = useAuth0();
  const cls = useStyles();
  const history = useHistory();
  const ctx = useContext(GlobalContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);

  onAuthStateChanged(firebaseAuth, user => {
    if (user) {
      props.history.push('/');
    }
  });

  React.useEffect(() => {
    ctx.handlePage();
  }, []);

  const handleEnter = event => {
    if (event && event.keyCode && event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = async e => {
    if (!email) {
      setErrorMessage('Please enter an email and password.');
      return;
    }
    if (!password) {
      setErrorMessage('Please enter an email and password.');
      return;
    }

    try {
      let loginRes = await signInWithEmailAndPassword(firebaseAuth, email, password);
      if (loginRes.user) {
        history.push('/');
      }
    } catch (err) {
      console.log('err', err.code, err.message);
      if (err.code.includes('password') || err.code.includes('user-not-found')) {
        setErrorMessage('Wrong email or password.');
      } else if (err.code.includes('invalid-email')) {
        setErrorMessage('Please enter a valid email and try again.');
      } else setErrorMessage('An error occurred while attempting to sign in.');
    }
  };

  return (
    <>
      <PageRoot>
        <CssBaseline />

        <Container className={cls.container} component='main'>
          {showResetPassword ? (
            <PasswordReset open={showResetPassword} setOpen={setShowResetPassword} email={email} setEmail={setEmail} />
          ) : (
            <div>
              <Spacer size='xl' />

              <Typography className={cls.titleTxt}>Sign in to continue</Typography>

              <Spacer size='lg' />

              <TextField
                required
                fullWidth
                autoFocus
                id='email'
                name='email'
                autoComplete='email'
                label='Email'
                placeholder='Enter email...'
                variant='outlined'
                size='small'
                value={email}
                onChange={e => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        email
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />

              <Spacer />

              <TextField
                required
                fullWidth
                id='password'
                name='password'
                type='password'
                label='Password'
                placeholder='Enter password...'
                variant='outlined'
                size='small'
                value={password}
                onChange={e => setPassword(e.target.value)}
                onKeyDown={handleEnter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        lock
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />

              <Spacer size='lg' />

              <Button fullWidth type='submit' color='primary' size='large' onClick={() => handleSubmit()}>
                Sign in
              </Button>

              {errorMessage ? (
                <>
                  <Spacer size='xs' />
                  <Typography className={cls.errorTxt}>{errorMessage}</Typography>
                  <Spacer size='xs' />
                </>
              ) : null}

              <Spacer size='xs' />

              <div className={cls.reset}>
                <Link
                  className={cls.resetTxt}
                  color='secondary'
                  target='_blank noreferrer'
                  onClick={() => {
                    setErrorMessage(null);
                    setShowResetPassword(true);
                  }}
                >
                  Request or reset password
                </Link>
              </div>

              <Spacer size='lg' />

              <div className={cls.auth0}>
                <Link
                  className={cls.auth0Txt}
                  color='secondary'
                  target='_blank noreferrer'
                  onClick={() => auth0.loginWithRedirect({ appState: { targetUrl: window.location.pathname } })}
                >
                  Still on the Social Auto Transport app? <br></br> Click here to sign in.
                </Link>
              </div>
            </div>
          )}
        </Container>
      </PageRoot>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 480,
  },
  titleTxt: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
  },
  errorTxt: {
    textAlign: 'center',
    fontSize: 18,
    color: theme.palette.error.main,
  },
  reset: {
    width: '100%',
    textAlign: 'center',
  },
  resetTxt: {
    fontSize: 14,
    cursor: 'pointer',
  },
  auth0: {
    width: '100%',
    textAlign: 'center',
  },
  auth0Txt: {
    fontSize: 16,
    cursor: 'pointer',
    textAlign: 'center',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default FirebaseLogin;
