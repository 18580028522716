import React, { useEffect } from 'react';
import { stepTypes } from '../../utils/workflow';
import TextInput from './StepRenderers/TextInput';
import PhotoCapture from './StepRenderers/PhotoCapture';
import MultiPhotoCapture from './StepRenderers/MultiPhotoCapture';
import SignatureCapture from './StepRenderers/SignatureCapture';
import YearInput from './StepRenderers/YearInput';

export default function StepControl({
  workflowStepsRef,
  getAuthToken,
  step,
  index,
  value,
  isValid,
  inGroup,
  isTakingPhoto = false,
  setIsTakingPhoto,
  onPhotoCapture,
  onNameCapturedPhoto,
}) {
  switch (step.type) {
    case stepTypes.TEXT:
      return (
        <TextInput
          workflowStepsRef={workflowStepsRef}
          step={step}
          index={index}
          value={value}
          isValid={isValid}
          inGroup={inGroup}
        />
      );
    case stepTypes.PHOTO:
      return (
        <PhotoCapture
          workflowStepsRef={workflowStepsRef}
          getAuthToken={getAuthToken}
          isTakingPhoto={isTakingPhoto}
          setIsTakingPhoto={setIsTakingPhoto}
          onPhotoCapture={onPhotoCapture}
          onNameCapturedPhoto={onNameCapturedPhoto}
          step={step}
          index={index}
          value={value}
          isValid={isValid}
          inGroup={inGroup}
        />
      );
    case stepTypes.PHOTOS:
      return (
        <MultiPhotoCapture
          workflowStepsRef={workflowStepsRef}
          getAuthToken={getAuthToken}
          isTakingPhoto={isTakingPhoto}
          setIsTakingPhoto={setIsTakingPhoto}
          onPhotoCapture={onPhotoCapture}
          onNameCapturedPhoto={onNameCapturedPhoto}
          step={step}
          index={index}
          value={value}
          isValid={isValid}
          inGroup={inGroup}
        />
      );
    case stepTypes.SIGNATURE:
      return (
        <SignatureCapture
          workflowStepsRef={workflowStepsRef}
          getAuthToken={getAuthToken}
          onPhotoCapture={onPhotoCapture}
          onNameCapturedPhoto={onNameCapturedPhoto}
          step={step}
          index={index}
          value={value}
          isValid={isValid}
          inGroup={inGroup}
        />
      );
    case stepTypes.YEAR:
      return (
        <YearInput
          workflowStepsRef={workflowStepsRef}
          value={value}
          step={step}
          index={index}
          isValid={isValid}
          inGroup={inGroup}
        />
      );
    default:
      return (
        <TextInput
          workflowStepsRef={workflowStepsRef}
          step={step}
          index={index}
          value={value}
          isValid={isValid}
          inGroup={inGroup}
        />
      );
  }
}
