import React, { useContext, useState, useEffect, useCallback } from 'react';
import gql from 'graphql-tag';
import GlobalContext from '../../../GlobalContext';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Button } from '@material-ui/core';

let log = false;

export default function ScheduleBody(props) {
  const cls = useStyles();
  const ctx = useContext(GlobalContext);

  const [selectedSchedule, setSelectedSchedule] = useState(null);
  // const [ready, setReady] = useState(false)

  const { driverId, selectedWeek } = props;

  //Function creates schedule if none exists
  let createNewSchedule = useCallback(
    (driver_id, expiration_date) => {
      log && console.log('CREATE', selectedWeek);
      ctx.apolloClient
        .mutate({
          mutation: CREATE_SCHEDULE,
          variables: {
            schedule_obj: {
              driver_id: driver_id,
              expiration_date: expiration_date,
              type: selectedWeek,
              createdat: 'now()',
              updatedat: 'now()',
            },
          },
        })
        .then(res => {
          log && console.log('Create Schedule - Hasura response (res.data):', res.data);
          if (res.data && res.data.insert_driverschedules && res.data.insert_driverschedules.returning) {
            setSelectedSchedule(res.data.insert_driverschedules.returning[0]);
            // setReady(true)
          } else console.log(res.data.errors[0]);
        });
    },
    [ctx.apolloClient, selectedWeek]
  );

  //Set or create schedule if driver or schedule is changed
  useEffect(() => {
    log && console.log('WEEK', selectedWeek);
    log && console.log('SCHEDULE', props.schedule);
    if (props.schedule === null) {
      let expirationDate = moment().startOf('week').add(2, 'weeks').format('YYYY-MM-DDTHH:mm:ssZ');
      createNewSchedule(driverId, expirationDate);
    } else {
      setSelectedSchedule(props.schedule);
      // setReady(true)
    }
    // setSelectedSchedule(props.schedule)
    // setReady(true)
  }, [props.schedule, selectedWeek, createNewSchedule, driverId]);

  useEffect(() => {
    log && console.log('Selected Schedule', selectedSchedule);
  }, [selectedSchedule]);

  let genWorkHours = (startHour, endHour) => {
    let hourArr = [];
    for (var i = startHour; i <= endHour; i++) {
      //NOTE: for driver portal change this to be just the hour
      hourArr.push(moment(i, 'H').format('h'));
    }
    return hourArr;
  };
  let workHours = genWorkHours(7, 21);

  let addAvailability = (start, end, day) => {
    log && console.log('add availability');
    ctx.apolloClient
      .mutate({
        mutation: ADD_SCHEDULE_DETAIL,
        variables: {
          start: start,
          end: end,
          day: day,
          schedule_id: selectedSchedule.id,
          expiration_date: moment().startOf('week').add(2, 'weeks').format('YYYY-MM-DDTHH:mm:ssZ'), //TODO: Check that the Z(timezone) is not messing things up
        },
      })
      .then(res => {
        log && console.log('Add Detail - Hasura response (res.data):', res.data);
      });
  };

  let removeAvailability = (start, end, day) => {
    log && console.log('remove availability');
    ctx.apolloClient
      .mutate({
        mutation: DELETE_SCHEDULE_DETAIL,
        variables: {
          start: start,
          end: end,
          day: day,
          schedule_id: selectedSchedule.id,
          expiration_date: moment().startOf('week').add(2, 'weeks').format('YYYY-MM-DDTHH:mm:ssZ'), //TODO: Check that the Z(timezone) is not messing things up
        },
      })
      .then(res => {
        log && console.log('Delete Detail - Hasura response (res.data):', res.data);
      });
  };
  let findDetail = (selectedSchedule, dayNum, startTime) => {
    if (selectedSchedule && selectedSchedule.details) {
      log &&
        console.log(
          'Details Exist',
          selectedSchedule.details,
          selectedSchedule.details.find(d => d.day === dayNum),
          dayNum,
          selectedSchedule.details
            .filter(d => d.day === dayNum)
            .map(d => d.block_start_time)
            .includes(startTime),
          startTime
        );
      let todaysDetails = selectedSchedule.details.filter(d => d.day === dayNum);
      let todaysStartTimes = todaysDetails.map(d => d.block_start_time);
      let blockExists = todaysStartTimes.includes(startTime);
      return blockExists;
    } else {
      return false;
    }
  };

  let AvailabilityButton = (detailExists = false, dayNumber, blockType) => {
    const [available, setAvailable] = useState(detailExists);
    let handleChange = () => {
      log && console.log('HANDLE CHANGE');
      if (!available) {
        if (blockType === 'morning') {
          addAvailability(7, 13, dayNumber);
          setAvailable(true);
        } else if (blockType === 'evening') {
          addAvailability(13, 21, dayNumber);
          setAvailable(true);
        }
      } else {
        if (blockType === 'morning') {
          removeAvailability(7, 13, dayNumber);
          setAvailable(false);
        } else if (blockType === 'evening') {
          removeAvailability(13, 21, dayNumber);
          setAvailable(false);
        }
      }
    };
    return (
      <Button
        className={cls.btn}
        onClick={() => handleChange()}
        fullWidth
        variant={available ? 'contained' : 'outlined'}
        color='primary'
      >
        {available ? `Available ${moment(dayNumber, 'e').format('ddd')} ${blockType}` : 'Add Availability'}
      </Button>
    );
  };
  let ScheduleHeader = props => {
    return (
      <div className={cls.headerRow}>
        <div></div>
        {workHours.map((n, i) => {
          return (
            <div key={`hour${n}(${i})`} className={cls.hour}>
              {n}
            </div>
          );
        })}
      </div>
    );
  };
  let ScheduleRow = props => {
    const [morningBlockExists, setMorningBlockExists] = useState(findDetail(props.schedule, props.dayNumber, 7));
    const [eveningBlockExists, setEvenngBlockExists] = useState(findDetail(props.schedule, props.dayNumber, 13));
    useEffect(() => {
      log && console.log('SETTING THE BLOCKS');
      setMorningBlockExists(findDetail(props.schedule, props.dayNumber, 7));
      setEvenngBlockExists(findDetail(props.schedule, props.dayNumber, 13));
    }, [props.dayNumber, props.schedule]);
    return (
      <div className={cls.row} style={{ gridRowStart: props.dayNumber + 2 }}>
        <div className={cls.day} style={{ lineHeight: 2 }}>
          {moment(props.dayNumber, 'e').format('dd')}
        </div>
        <div className={cls.shiftBlock} style={{ gridColumn: '2/span 7' }}>
          {AvailabilityButton(morningBlockExists, props.dayNumber, 'morning')}
        </div>
        <div className={cls.shiftBlock} style={{ gridColumn: '9/span 8' }}>
          {AvailabilityButton(eveningBlockExists, props.dayNumber, 'evening')}
        </div>
      </div>
    );
  };

  return (
    <div className={cls.container}>
      <>
        <ScheduleHeader />
        <ScheduleRow schedule={selectedSchedule} dayNumber={0} /> {/*Sunday*/}
        <ScheduleRow schedule={selectedSchedule} dayNumber={1} /> {/*Monday*/}
        <ScheduleRow schedule={selectedSchedule} dayNumber={2} /> {/*Tuesday*/}
        <ScheduleRow schedule={selectedSchedule} dayNumber={3} /> {/*Wednesday*/}
        <ScheduleRow schedule={selectedSchedule} dayNumber={4} /> {/*Thursday*/}
        <ScheduleRow schedule={selectedSchedule} dayNumber={5} /> {/*Friday*/}
        <ScheduleRow schedule={selectedSchedule} dayNumber={6} /> {/*Saturday*/}
      </>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  //TODO: Name grid rows and columns
  container: {
    color: theme.palette.text.primary,
    display: 'grid',
    height: '100vh',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    gridTemplateRows: '2%, repeat(7, 14%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  row: {
    height: '100%',
    display: 'inline-grid',
    gridTemplateColumns: '10% repeat(15, 6%)',
    gridRowEnd: 'span 1',
    gridColumnStart: 1,
    gridColumnEnd: 'span 1',
  },
  //TODO- make header row sticky
  headerRow: {
    height: '100%',
    display: 'inline-grid',
    gridTemplateColumns: '10% repeat(15, 6%)',
    gridRowStart: 1,
    gridRowEnd: 'span 1',
    backgroundColor: theme.palette.background.paper,
    gridColumnStart: 1,
    gridColumnEnd: 'span 1',
  },
  //TODO- make day col sticky
  day: {
    paddingTop: '50%',
    paddingRight: '5%',
    gridColumnStart: 1,
    gridColumnEnd: 'span 1',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  shiftBlock: {
    // display: "grid",
    // gridColumnStart: 2,
    // borderRight: "1px solid black",
    gridColumnEnd: 'span 7',
    placeSelf: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  availabilityButton: {
    labelStyle: { fontSize: 14 },
  },
  hour: {
    gridColumnEnd: 'span 1',
    placeSelf: 'center',
    fontSize: 14,
    fontWeight: 'bold',
  },
  btn: {
    height: '100%',
    border: '1px solid #fff',
    borderRadius: 0,
    lineHeight: 1.333,
    fontSize: 16,
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lgView: {
    display: 'none',
  },
  smView: {
    display: 'inline-block',
  },
}));

const CREATE_SCHEDULE = gql`
  mutation create_schedule($schedule_obj: driverschedules_insert_input!) {
    insert_driverschedules(
      objects: [$schedule_obj]
      on_conflict: { constraint: driverschedules_driver_id_type_key, update_columns: [updatedat, expiration_date] }
    ) {
      affected_rows
      returning {
        id
        details {
          id
          block_end_time
          block_start_time
          createdat
          day
          driver_schedule_id
          updatedat
        }
        driver_id
        expiration_date
        type
        updatedat
        createdat
      }
    }
  }
`;

const DELETE_SCHEDULE_DETAIL = gql`
  mutation DELETE_DETAIL($start: Int!, $end: Int!, $day: Int!, $schedule_id: bigint!, $expiration_date: timestamptz!) {
    delete_driverscheduledetails(
      where: {
        block_start_time: { _eq: $start }
        block_end_time: { _eq: $end }
        day: { _eq: $day }
        driver_schedule_id: { _eq: $schedule_id }
      }
    ) {
      affected_rows
      returning {
        block_end_time
        block_start_time
        day
        driver_schedule_id
      }
    }
    update_driverschedules(where: { id: { _eq: $schedule_id } }, _set: { expiration_date: $expiration_date }) {
      affected_rows
      returning {
        id
        expiration_date
      }
    }
  }
`;

const ADD_SCHEDULE_DETAIL = gql`
  mutation ADD_DETAIL($start: Int!, $end: Int!, $day: Int!, $schedule_id: bigint!, $expiration_date: timestamptz!) {
    insert_driverscheduledetails(
      objects: {
        block_end_time: $end
        block_start_time: $start
        createdat: "now()"
        day: $day
        driver_schedule_id: $schedule_id
        updatedat: "now()"
      }
      on_conflict: {
        constraint: driverscheduledetails_driver_schedule_id_day_block_start_ti_key
        update_columns: updatedat
      }
    ) {
      affected_rows
      returning {
        block_end_time
        block_start_time
        createdat
        day
        driver_schedule_id
        id
        updatedat
      }
    }
    update_driverschedules(where: { id: { _eq: $schedule_id } }, _set: { expiration_date: $expiration_date }) {
      affected_rows
      returning {
        id
        expiration_date
      }
    }
  }
`;
