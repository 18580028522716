import axios, { AxiosProgressEvent } from 'axios';
import { Buffer } from 'buffer';
import {
  S3_SIGNED_URL_FETCH_URL,
  S3_SIGNED_URL_FETCH_TIMEOUT_SEC,
  FILE_UPLOAD_TIMEOUT_SEC,
  DEFAULT_DOWNLOAD_BLOB,
} from './constants';

export const urlExists = async url => {
  try {
    await axios.head(url);
    //console.log(`${url} HEAD exists`);
    return true;
  } catch (error) {
    console.error(`${url} HEAD is missing`);
    return false;
  }
};

export const getS3PresignedUrl = async (bucketName, token, filePath, fileName, fileType = 'image/jpeg') => {
  if (!bucketName || bucketName === '') throw new Error(`No S3 bucket provided`);
  if (!fileName || fileName === '') throw new Error(`No file name provided`);
  if (!fileType || fileType === '') throw new Error(`No file type provided`);

  if (!token) throw new Error(`No user auth token found`);

  const bucketRequest = {
    bucketName,
    filePath,
    fileName,
    fileType,
  };

  //console.log(`Fetching S3 presigned url...\n`, JSON.stringify({ S3_SIGNED_URL_FETCH_URL, bucketRequest }, null, 2));

  try {
    const signedUrlRes = await axios.post(S3_SIGNED_URL_FETCH_URL, bucketRequest, {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      timeout: S3_SIGNED_URL_FETCH_TIMEOUT_SEC * 1000,
    });

    const { uploadURL } = signedUrlRes.data;

    //console.log(`Retreived S3 upload url: ${uploadURL}`);

    return uploadURL;
  } catch (error) {
    console.error(`Error fetching S3 presigned url: ${error}`);
  }
};

export const measureDownloadSpeed = async (imageURIParam = undefined) => {
  const imageURI = imageURIParam ? imageURIParam : DEFAULT_DOWNLOAD_BLOB;

  const downloadSizeInBits = 12391632; // 1.5 MB | 1,548,954 bytes | 12,391,632 bits
  const metric = 'MBps';

  const startTime = new Date().getTime();
  try {
    const res = await axios.get(imageURI, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
      responseType: 'blob',
      responseEncoding: 'binary',
    });
    const endTime = new Date().getTime();
    const duration = (endTime - startTime) / 1000;
    const speed = Math.floor(downloadSizeInBits / (1024 * 1024 * duration));
    //console.log(`Measured download speed: ${speed} ${metric}`);
    return { metric, speed };
  } catch (error) {
    console.error(`Error measuring download speed: ${error}`);
    return;
  }
};

const onUploadProgress = (progressEvent, startTime, filename, onComplete) => {
  const { loaded, total } = progressEvent;
  let percent = Math.floor((loaded * 100) / total);
  if (percent < 100) {
    //console.log(`Uploading ${filename}... ${percent}% (${loaded}/${total} bytes)`);
  } else {
    const sizeInBits = total * 8;
    const endTime = new Date().getTime();
    const duration = (endTime - startTime) / 1000;
    const speed = Math.floor(sizeInBits / (1024 * 1024 * duration));
    // console.log(
    //   `Finished uploading ${filename} (${loaded}/${total} bytes)
    //   It took ${duration > 1000 ? `${duration / 1000} s` : `${duration} ms`} at ${speed} Mbps.`
    // );
    if (onComplete)
      try {
        onComplete(speed);
      } catch (error) {}
  }
};

export const getBase64FromFile = file => {
  return new Promise(resolve => {
    let baseURL = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result.split(',')[1];
      console.log(baseURL);
      resolve(baseURL);
    };
  });
};

export const uploadBase64File = async (base64, filename, targetUrl, headers, progressCb) => {
  const buffer = await Buffer.from(base64, 'base64');
  const startTime = new Date().getTime();
  let uploadSpeedMbps = 0;
  const response = await axios.put(targetUrl, buffer, {
    headers,
    timeout: FILE_UPLOAD_TIMEOUT_SEC * 1000,
    onUploadProgress: progressEvent => {
      if (progressCb) {
        const { loaded, total } = progressEvent;
        let progress = Math.floor((loaded * 100) / total);
        progressCb(progress);
      }
      onUploadProgress(progressEvent, startTime, filename, speed => (uploadSpeedMbps = speed));
    },
  });

  return { response, uploadSpeedMbps };
};
