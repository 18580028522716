import dayjs from 'dayjs';
// import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

//////////////////////////////////////// HOOK ////////////////////////////////////////

export default function useValidation() {
  /** Validate a date of birth day value */
  const validateDobDay = (str = null) => {
    if (str && typeof str === `string`) {
      const day = Number(str);
      if (day && day >= 1 && day <= 31) return true;
    }
    return false;
  };

  /** Validate a date of birth month value */
  const validateDobMonth = (str = null) => {
    if (str && typeof str === `string`) {
      const month = Number(str);
      if (month && month >= 1 && month <= 12) return true;
    }
    return false;
  };

  /** Validate a date of birth year value */
  const validateDobYear = (str = null) => {
    if (str && typeof str === `string`) {
      const year = Number(str);
      if (year && year >= dayjs().subtract(150, `year`).format(`YYYY`) && year <= dayjs().format(`YYYY`)) return true;
    }
    return false;
  };

  /** Validate an email address */
  const validateEmail = (str = null) => {
    if (str && typeof str === `string`) {
      const match = str.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (match) return true;
    }
    return false;
  };

  /** Validate a phone number */
  const validatePhone = (str = null) => {
    if (str && typeof str === `string`) {
      const cleaned = str.replace(/\D/g, '');
      const match = cleaned.match(/^(\d{1})?(\d{3})(\d{3})(\d{4})$/);
      if (match) return true;
    }
    return false;
  };

  /** Validate a social security number */
  const validateSSN = (str = null) => {
    if (str && typeof str === `string`) {
      const cleaned = str.replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})-?(\d{2})-?(\d{4})$/);
      if (match) return true;
    }
    return false;
  };

  /** Validate a number value */
  const validateNumber = (str = null) => {
    if (str) {
      const cleaned = `${str}`.replace(/[,]/g, '');
      const isNum = /^\d+$/.test(cleaned);
      if (isNum) return true;
    }
    return false;
  };

  // Return hook logic
  return {
    validateDobDay,
    validateDobMonth,
    validateDobYear,
    validateEmail,
    validatePhone,
    validateSSN,
    validateNumber,
  };
}
