import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import { useWallet } from './useWallet';

import Divide from '../../reusable/Divide';
import WalletBadge from './WalletBadge';
import PayoutsTable from './PayoutsTable';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function WalletManagement({ driver, refetchDriver }) {
  const cls = useStyles();

  const { getWalletInfo } = useWallet();

  const [loading, setLoading] = React.useState(true);
  const [wallet, setWallet] = React.useState(null);
  const [bankAccount, setBankAccount] = React.useState(null);

  React.useEffect(() => {
    const fetchWalletInfo = async () => {
      const variables = {
        walletToken: driver.wallet_token,
        bankAccountToken: driver.bank_account_token,
      };
      const res = await getWalletInfo(variables);
      if (res && res.success) {
        setWallet(res.data.wallet);
        setBankAccount(res.data.bankAccount);
        setLoading(false);
      } else {
        toast.error(`Failed to retrieve wallet info!`);
        setLoading(false);
      }
    };
    fetchWalletInfo();
  }, [driver]);

  return (
    <>
      <Divide>Manage Your Wallet</Divide>

      {loading ? <Loading position='relative' /> : null}

      {wallet && bankAccount ? (
        <div className={cls.badge}>
          <WalletBadge driver={driver} wallet={wallet} bankAccount={bankAccount} refetchDriver={refetchDriver} />
        </div>
      ) : null}

      <Divide
        spacer
        tip={`Don't worry if this is empty. This only shows records from our new payouts system (which will be officially launched in January 2023).`}
      >
        View Your Recent Payouts
      </Divide>

      <PayoutsTable driver={driver} />
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  badge: {
    position: 'relative',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
