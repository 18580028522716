import React, { useEffect } from 'react';
import { makeStyles, Typography, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import * as Workflows from '@hopdrive/workflows';

export default function TextInput({ workflowStepsRef, step, index, value, isValid, inGroup }) {
  const cls = useStyles();

  useEffect(() => {
    if (!step) return;
    //console.log(`TextInput step ${step?.label}:`, { workflowStepsRef, step, index, value, isValid, inGroup });
  }, [step]);

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  return (
    <>
      {inGroup && index > 0 ? <Spacer size='lg' /> : null}

      <Typography className={isValid ? cls.headTxt : cls.headTxtError}>
        {step?.label}
        <span className={cls.required}>{step?.required ? `*` : ``}</span>
      </Typography>

      <Typography className={cls.subheadTxt}>{step?.description}</Typography>

      <input
        className={isValid ? cls.input : cls.inputError}
        id={step?.id}
        name={step?.id}
        defaultValue={value}
        type={step?.config?.keyboard?.includes(`number`) ? `number` : `text`}
        inputMode={step?.config?.keyboard?.includes(`number`) ? `numeric` : `text`}
        onChange={handleInputChange(value => {
          Workflows.setValueByStepId(workflowStepsRef.current, step.id, value);
        })}
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    minHeight: 32,
    padding: '4px 8px',
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
    '&:focus': {
      padding: '3px 7px',
      border: `2px solid ${theme.palette.text.primary}`,
    },
  },
  headTxt: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  headTxtError: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subheadTxt: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  inputError: {
    width: '100%',
    minHeight: 32,
    padding: '3px 7px',
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
  },
  required: {
    display: `inline-block`,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
}));
