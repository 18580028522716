import React, { useContext, useReducer } from 'react';
import { withRouter } from 'react-router';
import GlobalContext from '../../GlobalContext';

import { useTheme, makeStyles, Container, Grid, Typography, IconButton, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import Divide from '../reusable/Divide';
import { useTools } from '../hooks/useTools';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loading from '../utils/Loading';

import MoveStatusTracker from '../reusable/MoveStatusTracker';
import MoveDetailsInfo from './moveDetails/MoveDetailsInfo';
import MoveDetailsLane from './moveDetails/MoveDetailsLane';
import MoveDetailsLocation from './moveDetails/MoveDetailsLocation';
import MoveDetailsAP from './moveDetails/MoveDetailsAP';
import MoveOffer from './Moves/MoveOffer';

const log = false;

////////// COMPONENT //////////
function MoveDetails(props) {
  const { handlePage } = useContext(GlobalContext);
  handlePage();
  const theme = useTheme();
  const cls = useStyles();
  const forceRender = useReducer(num => num + 1, 0)[1];

  const { checkMoveCompleted } = useTools();

  const moveId = props.match.params.id;

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Query query={GET_MOVE} variables={{ moveId: moveId }} fetchPolicy='network-only'>
            {({ loading, error, data }) => {
              if (loading) return <Loading fixed />;
              if (error) {
                console.log(`Failed to retrieve move:`, error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>ERROR FINDING MOVE RECORD</Typography>
                  </div>
                );
              }
              if (data && data.moves && data.moves.length > 0) {
                const move = data.moves[0];
                log && console.log(`Move Details:`, move);

                // Set actions
                const laneActions = [];

                if (move.driver_status != 'accepted') {
                  return <MoveOffer move={move} rerenderParent={forceRender} />;
                }

                if (move.cancel_status != null) {
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>MOVE WAS CANCELLED</Typography>
                    </div>
                  );
                }

                return (
                  <>
                    <Grid container alignItems='center'>
                      <Grid item>
                        <IconButton className={cls.iconBtn} onClick={() => goBack()}>
                          <Icon>arrow_back</Icon>
                        </IconButton>
                      </Grid>

                      <Grid item>
                        <Typography className={cls.head}>Move Details</Typography>
                      </Grid>

                      <Grid item xs />
                    </Grid>

                    {!checkMoveCompleted(move) && !move.cancel_status ? (
                      <>
                        <div style={{ width: '100%', height: theme.spacing(2) }} />

                        <Button
                          fullWidth
                          color='tertiary'
                          size='large'
                          onClick={() => props.history.push(`/moves/${move.id}/workflow`)}
                        >
                          Launch Manual Workflow
                        </Button>
                      </>
                    ) : null}

                    <div style={{ width: '100%', height: theme.spacing(3) }} />

                    <MoveStatusTracker move={move} size='small' />

                    <div style={{ width: '100%', height: theme.spacing(3) }} />

                    <MoveDetailsInfo move={move} />

                    <Divide spacer tip='View the lane this move is associated with.' actions={laneActions}>
                      Lane
                    </Divide>

                    <MoveDetailsLane move={move} />

                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <MoveDetailsLocation type='pickup' move={move} />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <MoveDetailsLocation type='delivery' move={move} />
                      </Grid>
                    </Grid>

                    <Divide spacer tip='View what the driver is being payed for this move.'>
                      Driver Pay
                    </Divide>

                    <MoveDetailsAP move={move} />
                  </>
                );
              } else
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>NO MOVE RECORD FOUND</Typography>
                  </div>
                );
            }}
          </Query>
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  iconBtn: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

////////// GRAPHQL //////////
const GET_MOVE = gql`
  query get_moves($moveId: bigint!) {
    moves(where: { id: { _eq: $moveId }, active: { _eq: 1 } }) {
      id
      cancel_status
      class
      consumer_at_pickup
      consumer_name
      consumer_phone
      consumer_pickup
      deliver_by
      delivery_arrived
      delivery_started
      delivery_stop_id
      delivery_successful
      delivery_time
      driver_id
      driver_name
      driver_status
      manual_flag
      move_details
      move_type
      pickup_arrived
      pickup_started
      pickup_stop_id
      pickup_successful
      pickup_time
      plan_id
      ready_by
      reference_num
      return_ride_id
      ride_type
      status
      trip_id
      vehicle_color
      vehicle_image
      vehicle_make
      vehicle_model
      vehicle_odometer
      vehicle_stock
      vehicle_vin
      vehicle_year
      accessorials {
        id
        ap_amount
        code
        notes
        status
      }
      appayments {
        id
        amount
        notes
        status
        type
        accessorial {
          id
          code
          notes
        }
        move {
          id
          move_type
        }
      }
      customer {
        id
        name
      }
      driver {
        id
        user {
          id
          avatar_url
          display_name
        }
      }
      lane {
        id
        average_drive_speed_mph
        description
        distance_miles
        duration_sec
        delivery {
          id
          address
          name
          type
        }
        pickup {
          id
          address
          name
          type
        }
      }
      vehiclephotos {
        id
        name
        step_id
        url
      }
    }
  }
`;

////////// EXPORT //////////
export default withRouter(MoveDetails);
