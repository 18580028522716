// When copying workflows to admin:
// Replace GlobalContext with DataProvider (useData)
// Change useValidation, useFormat and useTools to "../../" instead of "../../../"
// Change edit mode state to false by default
// Add editMode and setEditMode prop to WorkflowBar in each form (uncomment)

import React from 'react';
import { makeStyles } from '@material-ui/core';

import FormPersonalInfo from './FormPersonalInfo';
import FormAddressInfo from './FormAddressInfo';
import FormVehicleInfo from './FormVehicleInfo';
import FormLicenseInfo from './FormLicenseInfo';
import FormInsuranceInfo from './FormInsuranceInfo';

import DefaultWorkflow from './DefaultWorkflow';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function Workflow({ driver, flowGroup, flowSection, onClose, refetch }) {
  const cls = useStyles();

  const handleClose = output => {
    if (onClose) onClose(output);
  };

  if (flowSection.id === `personal-information`)
    return (
      <div className={cls.workflow}>
        <FormPersonalInfo
          driver={driver}
          flowGroup={flowGroup}
          flowSection={flowSection}
          onClose={handleClose}
          refetch={refetch}
        />
      </div>
    );

  if (flowSection.id === `address-information`)
    return (
      <div className={cls.workflow}>
        <FormAddressInfo
          driver={driver}
          flowGroup={flowGroup}
          flowSection={flowSection}
          onClose={handleClose}
          refetch={refetch}
        />
      </div>
    );

  if (flowSection.id === `vehicle-information`)
    return (
      <div className={cls.workflow}>
        <FormVehicleInfo
          driver={driver}
          flowGroup={flowGroup}
          flowSection={flowSection}
          onClose={handleClose}
          refetch={refetch}
        />
      </div>
    );

  if (flowSection.id === `drivers-license-information`)
    return (
      <div className={cls.workflow}>
        <FormLicenseInfo
          driver={driver}
          flowGroup={flowGroup}
          flowSection={flowSection}
          onClose={handleClose}
          refetch={refetch}
        />
      </div>
    );

  if (flowSection.id === `insurance-information`)
    return (
      <div className={cls.workflow}>
        <FormInsuranceInfo
          driver={driver}
          flowGroup={flowGroup}
          flowSection={flowSection}
          onClose={handleClose}
          refetch={refetch}
        />
      </div>
    );

  return (
    <div className={cls.workflow}>
      <DefaultWorkflow flowGroup={flowGroup} flowSection={flowSection} onClose={handleClose} />
    </div>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  workflow: {},
}));
