export const ENV = process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev';

// Photos
export const S3_SIGNED_URL_FETCH_URL = `/.netlify/functions/app-get-s3-signed-url`;
export const S3_SIGNED_URL_FETCH_TIMEOUT_SEC = 30;
export const S3_VEHICLE_PHOTOS_BUCKET_NAME = `vehicle-photos.hopdrive.com`;

// Network
export const FILE_UPLOAD_TIMEOUT_SEC = 30;
export const DEFAULT_DOWNLOAD_BLOB = `https://s3.us-east-1.amazonaws.com/vehicle-photos.hopdrive.com/speed-test-1.5mb.jpg`;
