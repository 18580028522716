import React from 'react';

import useOnboarding from './useOnboarding';

import WorkflowBar from './WorkflowBar';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function FormAddressInfo({ flowGroup, flowSection, onClose }) {
  const { proceedToPrevWorkflow, proceedToNextWorkflow } = useOnboarding();

  // List of button actions in the workflow bar
  const actions = [
    {
      label: `Back`,
      icon: `arrow_back`,
      color: `secondary`,
      tip: `Jump to the previous section.`,
      handler: () => proceedToPrevWorkflow(flowGroup, flowSection.id, onClose),
    },
    {
      label: `Forward`,
      icon: `arrow_forward`,
      color: `primary`,
      tip: `Jump to the next section.`,
      handler: () => proceedToNextWorkflow(flowGroup, flowSection.id, onClose),
    },
  ];

  return (
    <WorkflowBar groupTitle={flowGroup.label} sectionTitle={flowSection.label} actions={actions} onClose={onClose} />
  );
}
