import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import GlobalContext from '../GlobalContext';

import {
  makeStyles,
  SwipeableDrawer,
  IconButton,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Icon,
} from '@material-ui/core';

////////// COMPONENT //////////
function NavMenu(props) {
  const { handlePage } = useContext(GlobalContext);
  const cls = useStyles();

  const handleLink = route => {
    props.handleClose();
    props.history.push(route);
    handlePage();
  };

  return (
    <SwipeableDrawer anchor='left' open={props.open} onClose={props.handleClose} onOpen={props.handleOpen}>
      <div className={cls.menuIcon}>
        <IconButton className={cls.menuBtn} onClick={props.handleClose}>
          <Icon>chevron_left</Icon>
        </IconButton>
      </div>

      <Divider />

      <List>
        <ListItem button onClick={() => handleLink(`/`)}>
          <ListItemIcon>
            <Icon>dashboard</Icon>
          </ListItemIcon>
          <ListItemText className={cls.listItem}>My Dashboard</ListItemText>
        </ListItem>

        <ListItem button onClick={() => handleLink(`/moves`)}>
          <ListItemIcon>
            <Icon>drive_eta</Icon>
          </ListItemIcon>
          <ListItemText className={cls.listItem}>My Moves</ListItemText>
        </ListItem>

        <ListItem button onClick={() => handleLink(`/pay`)}>
          <ListItemIcon>
            <Icon>paid</Icon>
          </ListItemIcon>
          <ListItemText className={cls.listItem}>My Pay</ListItemText>
        </ListItem>

        <ListItem button onClick={() => handleLink(`/schedule`)}>
          <ListItemIcon>
            <Icon>date_range</Icon>
          </ListItemIcon>
          <ListItemText className={cls.listItem}>My Schedule</ListItemText>
        </ListItem>

        <ListItem button onClick={() => handleLink(`/wallet`)}>
          <ListItemIcon>
            <Icon>wallet</Icon>
          </ListItemIcon>
          <ListItemText className={cls.listItem}>My Wallet</ListItemText>
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem button onClick={() => handleLink(`/contact`)}>
          <ListItemIcon>
            <Icon>mail</Icon>
          </ListItemIcon>
          <ListItemText className={cls.listItem}>Contact</ListItemText>
        </ListItem>

        <ListItem button onClick={() => handleLink(`/faq`)}>
          <ListItemIcon>
            <Icon>help</Icon>
          </ListItemIcon>
          <ListItemText className={cls.listItem}>FAQ</ListItemText>
        </ListItem>
      </List>

      {/* <Divider />

      <List>
        <ListItem button onClick={() => handleLink(`/onboarding`)}>
          <ListItemIcon>
            <Icon>tag</Icon>
          </ListItemIcon>
          <ListItemText className={cls.listItem}>Onboarding</ListItemText>
        </ListItem>
      </List> */}
    </SwipeableDrawer>
  );
}
export default withRouter(NavMenu);

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  menuIcon: {
    position: 'relative',
    width: '100%',
  },
  menuBtn: {
    float: 'right',
    margin: theme.spacing(0.5),
  },
  listItem: {
    marginLeft: '-8px',
    marginRight: '48px',
  },
}));
