import React from 'react';
import { makeStyles } from '@material-ui/core';

////////// COMPONENT //////////
export default function PageRoot(props) {
  const cls = useStyles();
  const { type } = props;
  return (
    <>
      <div className={type ? cls[type] : cls.standard}>{props.children}</div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  standard: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
