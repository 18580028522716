import gql from 'graphql-tag';
import * as Workflows from '@hopdrive/workflows';
import { stepTypes } from './workflow';

export const stages = Object.freeze({
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
});

export const statuses = Object.freeze({
  DISPATCHED: 'dispatched',
  PICKUP_STARTED: 'pickup started',
  PICKUP_ARRIVED: 'pickup arrived',
  PICKUP_SUCCESSFUL: 'pickup successful',
  DELIVERY_STARTED: 'delivery started',
  DELIVERY_ARRIVED: 'delivery arrived',
  DELIVERY_SUCCESSFUL: 'delivery successful',
  CANCELED: 'canceled',
});

export const getNextStatus = status => {
  switch (status) {
    case statuses.DISPATCHED:
      return statuses.PICKUP_STARTED;
    case statuses.PICKUP_STARTED:
      return statuses.PICKUP_ARRIVED;
    case statuses.PICKUP_ARRIVED:
      return statuses.PICKUP_SUCCESSFUL;
    case statuses.PICKUP_SUCCESSFUL:
      return statuses.DELIVERY_STARTED;
    case statuses.DELIVERY_STARTED:
      return statuses.DELIVERY_ARRIVED;
    case statuses.DELIVERY_ARRIVED:
      return statuses.DELIVERY_SUCCESSFUL;
    case statuses.DELIVERY_SUCCESSFUL:
      return statuses.DISPATCHED;
    default:
      return statuses.DISPATCHED;
  }
};

export const getStatusButtonText = status => {
  switch (status) {
    case statuses.DISPATCHED:
      return `Start Pickup`;
    case statuses.PICKUP_STARTED:
      return `Arrive`;
    case statuses.PICKUP_ARRIVED:
      return `Complete Pickup Inspection`;
    case statuses.PICKUP_SUCCESSFUL:
      return `Start Delivery`;
    case statuses.DELIVERY_STARTED:
      return `Arrive`;
    case statuses.DELIVERY_ARRIVED:
      return `Complete Delivery Inspection`;
    default:
      return `Start Over`;
  }
};

export const getStage = status => {
  switch (status) {
    case statuses.DISPATCHED:
      return stages.PICKUP;
    case statuses.PICKUP_STARTED:
      return stages.PICKUP;
    case statuses.PICKUP_ARRIVED:
      return stages.PICKUP;
    case statuses.PICKUP_SUCCESSFUL:
      return stages.DELIVERY;
    case statuses.DELIVERY_STARTED:
      return stages.DELIVERY;
    case statuses.DELIVERY_ARRIVED:
      return stages.DELIVERY;
    default:
      return stages.DELIVERY;
  }
};

export const cleanseWorkflowData = (steps, workflowData) => {
  if (!workflowData) return {};
  const specialStepIds = [
    'vehicle-vin',
    'vehicle-year',
    'vehicle-make',
    'vehicle-model',
    'vehicle-color',
    'vehicle-stock',
    'signature',
  ];
  let newWorkflowData = {};
  Object.keys(workflowData).forEach(stepId => {
    const step = Workflows.getStepByStepId(steps, stepId);
    if (step?.type === stepTypes.PHOTO) return;
    if (specialStepIds.includes(stepId)) return;
    newWorkflowData[stepId] = workflowData[stepId];
  });
  return newWorkflowData;
};

export async function updateMoveWithWorkflowOutput({ mutateFn, move, steps }) {
  if (!mutateFn) throw new Error('Missing apollo mutate function');
  if (!move) throw new Error('Missing move');
  if (!steps) throw new Error('Missing workflowSteps');

  //console.log(`Updating move: ${JSON.stringify(move, null, 2)}`);

  // Treat the vehicle attributes as a special case
  const vin = Workflows.getValueByStepId(steps, 'vehicle-vin');
  const year = Workflows.getValueByStepId(steps, 'vehicle-year');
  const make = Workflows.getValueByStepId(steps, 'vehicle-make');
  const model = Workflows.getValueByStepId(steps, 'vehicle-model');
  const color = Workflows.getValueByStepId(steps, 'vehicle-color');
  const stock = Workflows.getValueByStepId(steps, 'vehicle-stock');

  // Build a workflow_data
  const workflowOutput = Workflows.buildWorkflowOutput(steps);
  const workflowOutputCleaned = cleanseWorkflowData(steps, workflowOutput);
  const workflow_data = {
    ...move[`${getStage(move.status)}_workflow_data`],
    ...workflowOutputCleaned,
  };

  let resJson;
  try {
    const query = `
        mutation updateMoveWithWorkflowOutput(
          $id: bigint!
          $vin: String!
          $year: String!
          $make: String!
          $model: String!
          $color: String!
          $stock: String!
          $workflow_data: jsonb!
        ) {
          update_moves(
            where: { id: { _eq: $id } }
            _set: { vehicle_vin: $vin, vehicle_year: $year, vehicle_make: $make, vehicle_model: $model, vehicle_color: $color, vehicle_stock: $stock, ${getStage(
              move.status
            )}_workflow_data: $workflow_data }
          ) {
            affected_rows
          }
        }
      `;
    const variables = {
      id: move.id,
      vin,
      year,
      make,
      model,
      color,
      stock,
      workflow_data,
    };
    //console.log(`Mutating the move with ${query} and ${JSON.stringify(variables, null, 2)}`);
    resJson = await mutateFn({
      mutation: gql`
        ${query}
      `,
      variables,
    });
  } catch (error) {
    console.error(`Error updating move: ${error}`, {
      id: move.id,
      vin,
      year,
      make,
      model,
      color,
      stock,
      workflow_data,
    });
    return false;
  }

  //console.log(`Updated move: ${JSON.stringify(resJson, null, 2)}`);

  return resJson?.data?.update_moves?.affected_rows > 0;
}

export async function updateVehiclePhotoOnMove({
  mutateFn,
  move_id,
  step_id,
  name,
  url,
  workflow_id,
  status,
  location,
  createdBy,
}) {
  if (!mutateFn) throw new Error('Missing apollo mutate function');
  if (!move_id) throw new Error('Missing move_id');
  if (!step_id) throw new Error('Missing step_id');
  if (!name) throw new Error('Missing name');
  if (!url) throw new Error('Missing url');
  if (!createdBy) throw new Error('Missing created by');
  if (!status) throw new Error('Missing status');

  let resJson;
  try {
    resJson = await mutateFn({
      mutation: gql`
        mutation updateVehiclePhotoOnMove(
          $step_id: String!
          $url: String!
          $createdBy: String!
          $move_id: bigint!
          $workflow_id: Int!
          $name: String!
          $status: String!
          $location: geography!
        ) {
          insert_vehiclephotos(
            objects: {
              move_id: $move_id
              step_id: $step_id
              name: $name
              url: $url
              workflow_id: $workflow_id
              status: $status
              location: $location
              createdby: $createdBy
              createdat: "now()"
            }
            on_conflict: {
              constraint: vehiclephotos_name_move_id_workflow_id_key
              update_columns: [step_id, url, status, createdby, location]
            }
          ) {
            affected_rows
            returning {
              id
              move_id
              step_id
              name
              url
              workflow_id
              status
              location
            }
          }
        }
      `,
      variables: {
        move_id,
        step_id,
        name,
        url,
        createdBy,
        workflow_id,
        status: 'done', // TODO: This should be from an enum somewhere
        location: {
          type: 'Point',
          coordinates: [0, 0], //[currentLocation?.latitude, currentLocation?.longitude], // TODO: We need to get actual location
        },
      },
    });
  } catch (error) {
    console.error(`Error updating vehicle photo on move: ${error}`);
    return false;
  }

  //console.log(`Updated vehicle photo on move: ${JSON.stringify(resJson, null, 2)}`);

  return resJson?.data?.insert_vehiclephotos?.returning[0]?.url === url;
}
