//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TableRow, TableCell } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function PayoutsTableRow({ row, index }) {
  const payout = row.payout || {};

  const cls = useStyles();

  // Set a list of cells to render the row
  const rowCells = [
    {
      value: row.created_at || `-`,
      align: `left`,
    },
    {
      value: row.transfer_token || `-`,
      align: `left`,
    },
    {
      value: row.type || `-`,
      align: `left`,
    },
    {
      value: row.status || `-`,
      align: `left`,
    },
    {
      value: row.total || `-`,
      align: `right`,
    },
  ];

  return (
    <>
      <TableRow key={`payout-row-${index}`} className={index % 2 ? cls.rowEven : cls.rowOdd}>
        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => {
              if (!cell.hide)
                return (
                  <TableCell
                    key={`atr-row-${payout.id}-col-${i}`}
                    align={cell.align || `left`}
                    onClick={cell.onClick || null}
                  >
                    {cell.value || null}
                  </TableCell>
                );
              else return null;
            })
          : null}
      </TableRow>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
}));
