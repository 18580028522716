const helpers = {
  /**
   * Some addresses do not have whitespace after commas and cause wrapping issues on small screens. This function will add a single space after any commas without one.
   * @param {String} address Takes in an address string.
   * @returns {String} Returns an address string with added white spaces where needed .
   */
  handleCommaWhitespace: (address) => address.replace(/,(?=\S)|:/g, ", "),
  /**
   * Parses the lane data from a move and returns the approriate description, leaving out consumer info as needed.
   * @param {Object} move Takes in the full move object with nested lane and customer objects.
   * @param {Boolean} [log = false] Optional boolean to enable logging in the console, defaults to false if not provided.
   * @returns {String} Returns a string lane description depending on what info should be available to the user.
   */
  handleLaneDescription: (move, log = false) => {
    // log && console.log("handleLaneDescription for move:", move)
    let laneDescription;
    try {
      if (
        // Ensure the nescessary nested objects and fields exist
        move.lane &&
        move.lane.description &&
        move.lane.pickup &&
        move.lane.delivery &&
        move.lane.pickup.name &&
        move.lane.delivery.name
      ) {
        if (
          (move.consumer_name || move.consumer_phone) &&
          ["delivery successful", "canceled", "cancelled"].indexOf(
            move.status
          ) >= 0
        ) {
          // Lane contains a consumer address
          laneDescription = `Consumer lane`;
        } else {
          // Lane does not contain a consumer address
          laneDescription = move.lane.description;
        }
      } else laneDescription = "Consumer Lane";
    } catch (err) {
      log && console.error("Failed to parse lane description!", err);
      laneDescription = "Unknown Lane";
    }
    log && console.log(`Returning description: '${laneDescription}'`);
    return laneDescription;
  },
  /**
   * Removes any consumer info from a lane's nested location objects
   * @param {Object} move Takes in the full move object with nested lane and customer objects.
   * @param {Boolean} [log = false] Optional boolean to enable logging in the console, defaults to false if not provided.
   * @returns {String} Returns a string lane description depending on what info should be available to the user.
   */
  handleNestedLocationObjects: (move, log = false) => {
    log && console.log("handleLaneDescription for move:", move);
    let lane = move.lane;
    try {
      if (
        // Ensure the nescessary nested objects and fields exist
        lane &&
        lane.pickup &&
        lane.delivery &&
        lane.pickup.name &&
        lane.pickup.address &&
        lane.delivery.name &&
        lane.delivery.address
      ) {
        if (
          (move.consumer_name || move.consumer_phone) &&
          ["delivery successful", "canceled", "cancelled"].indexOf(
            move.status
          ) >= 0
        ) {
          lane.pickup.name = "Consumer Pickup";
          lane.pickup.address = "Info expired";
          lane.delivery.name = "Consumer Delivery";
          lane.delivery.address = "Info expired";
        } else {
          // Lane does not contain a consumer address
          // Do nothing (placeholder)
        }
      }
    } catch (err) {
      log && console.error("Failed to parse lane!", err);
      return move.lane;
    }
    log && console.log("Returning lane:", lane);
    return lane;
  },
};
export default helpers;
