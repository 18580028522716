//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { TableBody } from '@material-ui/core';
import { getComparator, stableSort } from '../../utils/tableSort';

import PayoutsTableRow from './PayoutsTableRow';

//////////////////////// COMPONENT ////////////////////////

export default function PayoutsTableBody({ rows, order, orderBy }) {
  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy)).map((row, i) => {
        return (
          <React.Fragment key={`payouts-row-${i}`}>
            <PayoutsTableRow row={row} index={i} />
          </React.Fragment>
        );
      })}
    </TableBody>
  );
}
