import React, { useEffect } from 'react';
import {
  makeStyles,
  Typography,
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  NativeSelect,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import * as Workflows from '@hopdrive/workflows';

export default function YearInput({ workflowStepsRef, step, index, value, isValid, inGroup }) {
  const cls = useStyles();

  const [year, setYear] = React.useState(value);

  useEffect(() => {
    if (!year) return;
    Workflows.setValueByStepId(workflowStepsRef.current, step.id, year);
  }, [year]);

  const handleChange = event => {
    setYear(event.target.value);
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  const earliestYear = currentYear + (step?.config?.min || -50);
  const latestYear = currentYear + (step?.config?.max || 2);
  for (let i = earliestYear; i <= latestYear; i++) {
    years.push(i);
  }

  return (
    <>
      {inGroup && index > 0 ? <Spacer size='lg' /> : null}

      <Typography className={isValid ? cls.headTxt : cls.headTxtError}>
        {step?.label}
        <span className={cls.required}>{step?.required ? `*` : ``}</span>
      </Typography>

      <Typography className={cls.subheadTxt}>{step?.description}</Typography>

      <select id={`select-${step?.id}`} value={year} onChange={handleChange} className={cls.input}>
        {years.map(year => (
          <option key={`year-${year}`} value={year}>
            {year}
          </option>
        ))}
      </select>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    width: '100%',
    minHeight: 32,
    padding: '4px 8px',
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
    '&:focus': {
      padding: '3px 7px',
      border: `2px solid ${theme.palette.text.primary}`,
    },
  },
  headTxt: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  headTxtError: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subheadTxt: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  inputError: {
    width: '100%',
    minHeight: 32,
    padding: '3px 7px',
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
  },
  required: {
    display: `inline-block`,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
}));
