import React, { useContext } from 'react';
import dayjs from 'dayjs';
import sdk from '@hopdrive/sdk';
import GlobalContext from '../../GlobalContext';
import { makeStyles } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loading from '../utils/Loading';
import StaticAlert from '../utils/StaticAlert';
import DashboardContent from './dashboard/DashboardContent';
import { useTools } from '../../hooks/useTools';

const log = false;

////////// COMPONENT //////////
export default function Dashboard() {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { getDriverInitials } = useTools();

  const driverId = ctx.driverId;
  const driverName = ctx.userAuth && (ctx.userAuth.nickname || ctx.userAuth.displayName);
  const driverPic = ctx.userAuth && (ctx.userAuth.picture || ctx.userAuth.photoURL);

  let appaymentsFiltered

  return (
    <>
      <div className={cls.backdrop} />
      {ctx && ctx.userAuth && driverId && (
        <Query
          query={sdk.wallet.GET_APPAYMENTS_BY_RANGE_AND_DRIVER_ID_NO_STATUS}
          variables={{
            start: dayjs().startOf(`year`).format(),
            end: dayjs().endOf(`year`).format(),
            driverId: driverId,
          }}
          fetchPolicy='network-only'
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) {
              console.error('Failed to load dashboard info:', error);
              return <StaticAlert type='error' message='Failed to load dashboard info.' />;
            }
            if (data) {
              const appayments = data.appayments;

            //Filter out work that hasn't yet been completed
            appaymentsFiltered = appayments.filter(appayment => 
              appayment.status !== 'estimated'
            )

              const driverPay = sdk.wallet.buildDriverPayObject(appaymentsFiltered);
              log && console.log(`Driver Pay:`, driverPay);

              const timer = Date.now();

              const getPayments = (payments, start, end) => {
                return payments.filter(p => dayjs(p.move.pickup_time) >= start && dayjs(p.move.pickup_time) <= end);
              };
              const getPay = payments => {
                const pay =
                  payments.length > 0
                    ? payments
                        .map(p => p.amount)
                        .reduce((total, currentValue) => total + currentValue)
                        .toFixed(2)
                    : `0.00`;
                return `$${pay}`;
              };
              const getMoves = payments => {
                let moves = [];
                if (payments.length > 0) {
                  payments.forEach(p => {
                    if (!moves.includes(p.move.id)) {
                      moves.push(p.move.id);
                    }
                  });
                }
                return moves.length;
              };
              const getTime = payments => {
                const ms =
                  payments.length > 0
                    ? parseInt(
                        payments
                          .map(p => p.move.lane.duration_sec * 1000)
                          .reduce((total, currentValue) => total + currentValue)
                          .toFixed(0)
                      )
                    : 0;
                return dayjs.utc(dayjs.duration(ms).asMilliseconds()).format(`HH:mm`);
              };
              const getDistance = payments => {
                const distance =
                  payments.length > 0
                    ? payments
                        .map(p => p.move.lane.distance_miles)
                        .reduce((total, currentValue) => total + currentValue)
                        .toFixed(0)
                    : 0;
                return `${distance} mi`;
              };
              const getACH = payments => {
                if (payments.length > 0 && payments[0].ach_transaction_id !== null)
                  return `#${payments[0].ach_transaction_id}`;
                else return `N/A`;
              };
              const getFavLoc = payments => {
                if (payments.length >= 0) {
                  let locations = [];
                  payments.forEach(p => {
                    if (p.move.lane && p.move.lane.description) {
                      const locs = p.move.lane.description.split(` to `);
                      locations.push(locs[0]);
                      locations.push(locs[1]);
                    } else {
                      locations.push('Consumer Pickup');
                      locations.push('Consumer Delivery');
                    }
                  });

                  let mapping = {};
                  let count = 0;
                  let mode;
                  locations.forEach((l, i) => {
                    mapping[i] = (mapping[i] || 0) + 1;
                    if (count < mapping[i]) {
                      count = mapping[i];
                      mode = l;
                    }
                  });

                  return mode;
                } else return `N/A`;
              };
              const getFavLane = payments => {
                if (payments.length >= 0) {
                  let lanes = [];
                  payments.forEach(p => {
                    lanes.push(
                      p.move.lane && p.move.lane.description
                        ? p.move.lane.description
                        : 'Consumer Pickup to Consumer Delivery'
                    );
                  });

                  let mapping = {};
                  let count = 0;
                  let mode;
                  lanes.forEach((l, i) => {
                    mapping[i] = (mapping[i] || 0) + 1;
                    if (count < mapping[i]) {
                      count = mapping[i];
                      mode = l;
                    }
                  });

                  return mode;
                } else return `N/A`;
              };

              var driver = {};

              driver.id = driverId;
              driver.name = driverName;
              driver.pic = driverPic || null;
              driver.initials = getDriverInitials(driver);

              driver.twPayments = getPayments(appaymentsFiltered, dayjs().startOf(`week`), dayjs().endOf(`week`));
              driver.twPay = getPay(driver.twPayments);
              driver.twMoves = getMoves(driver.twPayments);
              driver.twTime = getTime(driver.twPayments);
              driver.twDistance = getDistance(driver.twPayments);

              driver.lwPayments = getPayments(
                appaymentsFiltered,
                dayjs().startOf(`week`).subtract(1, `week`),
                dayjs().endOf(`week`).subtract(1, `week`)
              );
              driver.lwPay = getPay(driver.lwPayments);
              driver.lwMoves = getMoves(driver.lwPayments);
              driver.lwTime = getTime(driver.lwPayments);
              driver.lwDistance = getDistance(driver.lwPayments);
              driver.ach = getACH(driver.lwPayments);

              driver.ytdPayments = data.appayments;
              driver.ytdPay = getPay(appaymentsFiltered);
              driver.ytdMoves = getMoves(appaymentsFiltered);
              driver.ytdTime = getTime(appaymentsFiltered);
              driver.ytdDistance = getDistance(appaymentsFiltered);
              driver.ytdLoc = getFavLoc(appaymentsFiltered);
              driver.ytdLane1 = appaymentsFiltered.length > 0 ? getFavLane(appaymentsFiltered).split(` to `)[0] : '';
              driver.ytdLane2 = appaymentsFiltered.length > 0 ? getFavLane(appaymentsFiltered).split(` to `)[1] : '';

              log && console.log(`Driver Info:`, driver);
              log && console.log(`Driver Info Time: ${Date.now() - timer} ms`);

              return <DashboardContent driver={driver} />;
            }
          }}
        </Query>
      )}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: '-1',
    position: 'absolute',
    width: '100%',
    height: '416px',
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
    [theme.breakpoints.down('sm')]: {
      height: '368px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '312px',
    },
  },
}));

////////// GRAPHQL //////////
const GET_DASHBOARD_INFO = gql`
  query get_dashboard_info {
    appayments(where: { amount: { _gt: 0 } }, order_by: { id: desc }) {
      id
      ach_transaction_id
      amount
      driver_id
      status
      driver {
        id
        user {
          id
        }
      }
      move {
        consumer_pickup
        consumer_name
        consumer_phone
        consumer_at_pickup
        return_ride_id
        id
        active
        status
        driver_name
        customer {
          id
          name
        }
        pickup_time
        delivery_time
        class
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_year
        lane {
          id
          duration_sec
          distance_miles
          description
          average_drive_speed_mph
          driver_return_pay
          pickup {
            id
            name
            type
            address
          }
          delivery {
            id
            name
            type
            address
          }
        }
      }
    }
  }
`;
