//////////////////////// DEPENDENCIES ////////////////////////
import React from 'react';
import { makeStyles, Grid, Tooltip, Typography, useTheme } from '@material-ui/core';
import { useTools } from '../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

function MoveStatusTracker(props) {
  const cls = useStyles();
  const theme = useTheme();
  const { getFormattedCombinedStatusFromMove } = useTools();

  const { move, size } = props;

  let cancelStatus = move && move.cancel_status ? move.cancel_status : null;
  let status = move && move.status ? move.status : null;

  let step = 0;
  let pulse = true;

  if (status === `dispatched`) {
    step = 1;
    pulse = false;
  } else if (status === `pickup started`) {
    step = 2;
    pulse = true;
  } else if (status === `pickup arrived`) {
    step = 2;
    pulse = true;
  } else if (status === `pickup successful`) {
    step = 2;
    pulse = false;
  } else if (status === `delivery started`) {
    step = 3;
    pulse = true;
  } else if (status === `delivery arrived`) {
    step = 3;
    pulse = true;
  } else if (status === `delivery successful`) {
    step = 3;
    pulse = false;
  } else {
    step = 1;
    pulse = true;
  }

  if (cancelStatus) {
    pulse = false;
  }

  let t1Hue = step === 0 || step === 1 ? theme.palette.primary.main : theme.palette.primary.main;
  let t2Hue =
    step === 0 || step === 2
      ? theme.palette.primary.main
      : step === 3
      ? theme.palette.primary.main
      : theme.palette.fade[3];
  let t3Hue = step === 0 || step === 3 ? theme.palette.primary.main : theme.palette.fade[3];
  let t1Pulse = !pulse || (step !== 0 && step !== 1) ? `none` : `pulse 1s infinite`;
  let t2Pulse = !pulse || (step !== 0 && step !== 2) ? `none` : `pulse 1s infinite`;
  let t3Pulse = !pulse || (step !== 0 && step !== 3) ? `none` : `pulse 1s infinite`;

  return (
    <Tooltip placement='top' title={getFormattedCombinedStatusFromMove(move)}>
      {size === `small` ? (
        <div className={cls.sm_tracker}>
          <Grid container spacing={0} wrap='nowrap' style={{ height: '40px' }}>
            <Grid item xs={2}>
              <div
                className={cls.sm_step1}
                style={{ background: t1Hue, boxShadow: `0 0 0 0 ${t1Hue}`, animation: t1Pulse }}
              >
                <Typography className={cls.sm_stepTxt} style={{ color: '#fff' }}>
                  PLAN
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div
                className={cls.sm_step2}
                style={{ background: t2Hue, boxShadow: `0 0 0 0 ${t2Hue}`, animation: t2Pulse }}
              >
                <Typography className={cls.sm_stepTxt} style={{ color: '#fff' }}>
                  PICKUP
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div
                className={cls.sm_step3}
                style={{ background: t3Hue, boxShadow: `0 0 0 0 ${t3Hue}`, animation: t3Pulse }}
              >
                <Typography className={cls.sm_stepTxt} style={{ color: '#fff' }}>
                  DELIVERY
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={cls.lg_tracker}>
          <Grid container spacing={0} wrap='nowrap' style={{ height: '64px' }}>
            <Grid item xs={2}>
              <div
                className={cls.lg_step1}
                style={{ background: t1Hue, boxShadow: `0 0 0 0 ${t1Hue}`, animation: t1Pulse }}
              >
                <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>
                  PLAN
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div
                className={cls.lg_step2}
                style={{ background: t2Hue, boxShadow: `0 0 0 0 ${t2Hue}`, animation: t2Pulse }}
              >
                <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>
                  PICKUP
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div
                className={cls.lg_step3}
                style={{ background: t3Hue, boxShadow: `0 0 0 0 ${t3Hue}`, animation: t3Pulse }}
              >
                <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>
                  DELIVERY
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Tooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  // Small Classes
  sm_step1: {
    position: 'relative',
    height: '100%',
    borderRadius: '20px 0 0 20px',
    marginRight: theme.spacing(1),
    background: theme.palette.fade[4],
    '&::after': {
      content: `content`,
      position: 'absolute',
      right: 0,
      background: theme.palette.secondary.light,
    },
  },
  sm_step2: {
    position: 'relative',
    height: '100%',
    background: theme.palette.secondary.light,
  },
  sm_step3: {
    position: 'relative',
    height: '100%',
    borderRadius: '0 20px 20px 0',
    marginLeft: theme.spacing(1),
    background: theme.palette.secondary.light,
  },
  sm_stepTxt: {
    position: 'absolute',
    top: '35%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },

  // Large Classes
  lg_step1: {
    position: 'relative',
    height: '100%',
    borderRadius: '48px 0 0 48px',
    marginRight: theme.spacing(1.5),
    background: theme.palette.fade[4],
    '&::after': {
      content: `content`,
      position: 'absolute',
      right: 0,
      background: theme.palette.fade[4],
    },
  },
  lg_step2: {
    position: 'relative',
    height: '100%',
    background: theme.palette.fade[4],
  },
  lg_step3: {
    position: 'relative',
    height: '100%',
    borderRadius: '0 48px 48px 0',
    marginLeft: theme.spacing(1.5),
    background: theme.palette.fade[4],
  },
  lg_stepTxt: {
    position: 'absolute',
    top: '32.5%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveStatusTracker;
