import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Grid, Typography, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import useFormat from '../../../hooks/useFormat';
import useValidation from '../../../hooks/useValidation';
import { useWallet } from './useWallet';

// Fake Account Num: 123456789
// Fake Routing Num: 222222226

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function WalletCreate({ driver, refetchDriver }) {
  const cls = useStyles();

  const { formatAccountNumber, formatSSN } = useFormat();
  const { validateSSN } = useValidation();
  const { runDirectDepositSetup } = useWallet();

  const [isSaving, setIsSaving] = React.useState(false);
  const [validation, setValidation] = React.useState({});

  const [accountName, setAccountName] = React.useState(``);
  const [accountNum, setAccountNum] = React.useState(``);
  const [accountNumConfirm, setAccountNumConfirm] = React.useState(``);
  const [accountType, setAccountType] = React.useState(`checking`);
  const [routingNum, setRoutingNum] = React.useState(``);
  const [routingNumConfirm, setRoutingNumConfirm] = React.useState(``);
  const [ssn, setSsn] = React.useState(``);

  const [showSsn, setShowSsn] = React.useState(false);

  // Checks if all inputs are valid
  const handleValidateForm = () => {
    let valid = true;

    const newValidation = {
      accountName: accountName && accountName.length >= 3 ? true : false,
      accountNum: accountNum && accountNum.length >= 5 ? true : false,
      accountNumConfirm: accountNum === accountNumConfirm ? true : false,
      accountType: accountType !== `none` ? true : false,
      routingNum: routingNum && routingNum.length >= 9 ? true : false,
      routingNumConfirm: routingNum === routingNumConfirm ? true : false,
      ssn: validateSSN(ssn),
    };
    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return valid;
  };

  // Detect changes to the driver and update the form accordingly
  React.useEffect(() => {
    if (driver) {
      setSsn(driver.social_security || ``);
    }
  }, [driver]);

  // Handle basic input field changes
  const handleInputChange = setHandler => event => {
    const val = event.target.value;
    if (setHandler) setHandler(val);
  };

  // Handle wallet and bank account creation
  const handleRunDirectDepositSetup = async () => {
    const valid = handleValidateForm();
    if (valid) {
      const variables = {
        driverId: driver.id,
        accountName,
        accountNum: formatAccountNumber(accountNum),
        accountType,
        email: driver.email,
        firstName: driver.first_name,
        lastName: driver.last_name,
        routingNum: formatAccountNumber(routingNum),
        ssn: formatSSN(ssn),
        walletToken: driver.wallet_token,
      };
      const res = await runDirectDepositSetup(variables);
      if (res && res.success) {
        toast.success(`Successfully created wallet!`, { autoClose: 2500 });
        if (refetchDriver) refetchDriver();
      } else toast.error(`Failed to create wallet!`);
    } else toast.error(`Failed to save. Please check the form for errors.`);

    setIsSaving(false);
  };

  return (
    <>
      <Typography className={cls.titleTxt}>Create Your Wallet</Typography>

      <Spacer size='sm' />

      <Typography className={cls.subtitleTxt}>
        Your wallet is the easiest way for you to get paid. Set up your direct deposit and receive your pay as soon as
        it's&nbsp;ready.
      </Typography>

      <Spacer size='lg' />

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            required
            fullWidth
            label='Wallet Name'
            placeholder='Enter a custom wallet name...'
            variant='outlined'
            size='small'
            value={accountName}
            onChange={handleInputChange(setAccountName)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    label
                  </Icon>
                </InputAdornment>
              ),
            }}
            error={validation.accountName === false}
            helperText={validation.accountName === false ? `Wallet name is invalid` : null}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            required
            select
            fullWidth
            label='Account Type'
            placeholder='Select your account type...'
            variant='outlined'
            size='small'
            value={accountType}
            onChange={handleInputChange(setAccountType)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    account_balance_wallet
                  </Icon>
                </InputAdornment>
              ),
            }}
            error={validation.accountType === false}
            helperText={validation.accountType === false ? `Account type is invalid` : null}
          >
            <MenuItem value='checking'>Checking</MenuItem>
            <MenuItem value='savings'>Savings</MenuItem>
          </TextField>
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            required
            fullWidth
            label='Social Security (XXX-XX-XXXX)'
            placeholder='Enter your full social security number...'
            type={showSsn ? `text` : `password`}
            variant='outlined'
            size='small'
            value={ssn}
            onChange={handleInputChange(setSsn)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    fingerprint
                  </Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  style={{ verticalAlign: 'top', cursor: `pointer` }}
                  position='end'
                  onClick={() => setShowSsn(!showSsn)}
                >
                  <Icon color='disabled' fontSize='small'>
                    {showSsn ? `visibility` : `visibility_off`}
                  </Icon>
                </InputAdornment>
              ),
            }}
            error={validation.ssn === false}
            helperText={validation.ssn === false ? `Social security number is invalid` : null}
          />
        </Grid>
      </Grid>

      <Spacer size='lg' />

      <Typography className={cls.sectionTxt}>Account Number Entry</Typography>

      <TextField
        required
        fullWidth
        label='Account Number'
        placeholder='Enter your account number...'
        variant='outlined'
        size='small'
        value={accountNum}
        onChange={handleInputChange(setAccountNum)}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                numbers
              </Icon>
            </InputAdornment>
          ),
        }}
        error={validation.accountNum === false}
        helperText={validation.accountNum === false ? `Account number is invalid` : null}
      />

      <Spacer />

      <TextField
        required
        fullWidth
        label='Confirm Account Number'
        placeholder='Confirm your account number...'
        variant='outlined'
        size='small'
        value={accountNumConfirm}
        onChange={handleInputChange(setAccountNumConfirm)}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                numbers
              </Icon>
            </InputAdornment>
          ),
        }}
        error={validation.accountNumConfirm === false}
        helperText={validation.accountNumConfirm === false ? `Account numbers do not match` : null}
      />

      <Spacer size='lg' />

      <Typography className={cls.sectionTxt}>Routing Number Entry</Typography>

      <TextField
        required
        fullWidth
        label='Routing Number'
        placeholder='Enter your routing number...'
        variant='outlined'
        size='small'
        value={routingNum}
        onChange={handleInputChange(setRoutingNum)}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                account_balance
              </Icon>
            </InputAdornment>
          ),
        }}
        error={validation.routingNum === false}
        helperText={validation.routingNum === false ? `Routing number is invalid` : null}
      />

      <Spacer />

      <TextField
        required
        fullWidth
        label='Confirm Routing Number'
        placeholder='Confirm your routing number...'
        variant='outlined'
        size='small'
        value={routingNumConfirm}
        onChange={handleInputChange(setRoutingNumConfirm)}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                account_balance
              </Icon>
            </InputAdornment>
          ),
        }}
        error={validation.routingNumConfirm === false}
        helperText={validation.routingNumConfirm === false ? `Routing numbers do not match` : null}
      />

      <Spacer size='lg' />

      <Grid container spacing={2}>
        <Grid item xs />
        <Grid item>
          <Button
            loading={isSaving}
            disabled={isSaving}
            fullWidth
            color='primary'
            onClick={() => {
              setIsSaving(true);
              handleRunDirectDepositSetup();
            }}
          >
            <Icon className={cls.btnIconL}>wallet</Icon>
            Create Wallet
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
  },
  subtitleTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionTxt: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 500,
  },
  selectPlaceholder: {
    color: theme.palette.text.secondary,
  },
  btnIconL: {
    marginTop: -2,
    marginRight: 8,
    fontSize: 16,
  },
  btnIconR: {
    marginTop: -2,
    marginLeft: 8,
    fontSize: 16,
  },
}));
