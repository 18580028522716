import React, { useEffect } from 'react';
import { makeStyles, Typography, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import * as Workflows from '@hopdrive/workflows';
import CameraView from '../CameraView/CameraView';
import Photo from '../Photo';

export default function PhotoCapture({
  workflowStepsRef,
  step,
  index,
  value,
  isValid,
  inGroup,
  isTakingPhoto = false,
  setIsTakingPhoto,
  onPhotoCapture,
  onNameCapturedPhoto,
  getAuthToken,
}) {
  const cls = useStyles();

  useEffect(() => {
    if (!step) return;
    // console.log(`PhotoCapture ${step?.label}:`, {
    //   workflowStepsRef,
    //   step,
    //   index,
    //   value,
    //   inGroup,
    //   isTakingPhoto,
    //   isValid,
    // });
  }, [step]);

  const handlePhotoCapture = async base64Image => {
    if (onPhotoCapture) onPhotoCapture(step, base64Image);
  };

  return (
    <>
      {inGroup && index > 0 ? <Spacer size='lg' /> : null}

      {isTakingPhoto && (
        <CameraView stepName={step?.id} onCapture={handlePhotoCapture} onClose={() => setIsTakingPhoto(false)} />
      )}

      {/* {!inGroup && (
          <div className={cls.cameraButton}>
            <Icon>photo_camera</Icon>
          </div>
        )} */}

      <Typography className={isValid ? cls.headTxt : cls.headTxtError}>
        {step.label}
        <span className={cls.required}>{step.required ? `*` : ``}</span>
      </Typography>

      <Typography className={cls.subheadTxt}>{step.description}</Typography>

      <Photo
        onPhotoCapture={onPhotoCapture}
        onNameCapturedPhoto={onNameCapturedPhoto}
        workflowStepsRef={workflowStepsRef}
        step={step}
        url={value}
        getAuthToken={getAuthToken}
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    minHeight: 32,
    padding: '4px 8px',
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
    '&:focus': {
      padding: '3px 7px',
      border: `2px solid ${theme.palette.text.primary}`,
    },
  },
  headTxt: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  headTxtError: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subheadTxt: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  inputError: {
    width: '100%',
    minHeight: 32,
    padding: '3px 7px',
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: 4,
    margin: 0,
    color: theme.palette.text.primary,
    background: 'transparent',
    outline: 'none',
  },
  required: {
    display: `inline-block`,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  cameraButton: {
    position: 'absolute',
    right: 15,
    top: -10,
    height: 54,
    borderRadius: 100,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: 15,
    boxShadow: theme.shadow.harsh,
  },
}));
