//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { useTheme, makeStyles, Typography, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../../hooks/useTools';
import MoveTileHangtag from '../../reusable/MoveTileHangtag';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTile({ move }) {
  const theme = useTheme();
  const cls = useStyles();

  const { getFormattedVehicleFromMove, formatUSD } = useTools();

  const getMoveColor = move => {
    if (move.consumer_type === `customer` || move.consumer_type === `loaner`) return theme.palette.concierge.main;
    return theme.palette.ops.main;
  };

  const getMoveIcon = move => {
    if (move.consumer_type === `customer`) return `person`;
    if (move.consumer_type === `loaner`) return `emoji_transportation`;
    return `drive_eta`;
  };

  return (
    <div className={cls.move}>
      {move.hangtags && move.hangtags.length && move.hangtags[0].type === `yard` ? (
        <MoveTileHangtag hangtag={move.hangtags[0]} />
      ) : null}

      <div className={cls.detailsLeft}>
        <div className={cls.flex}>
          <Icon className={cls.icon} style={{ color: getMoveColor(move) }}>
            {getMoveIcon(move)}
          </Icon>
          <Typography className={cls.moveIdTxt}>#{move.id}</Typography>
        </div>

        <Spacer size='xs' />

        <Typography className={cls.vehicleTxt}>{getFormattedVehicleFromMove(move)}</Typography>

        <Spacer size='xs' />

        <Typography className={cls.locationTxt}>{getPropValue(move, `lane.pickup.name`)}</Typography>
        <Typography className={cls.locationTxt}>to {getPropValue(move, `lane.delivery.name`)}</Typography>

        <Spacer size='xs' />

        <div className={cls.status}>
          <Typography className={cls.statusTxt}>{move.status}</Typography>
        </div>
      </div>

      <div className={cls.detailsRight}>
        <Typography className={cls.timeTxt} style={{ color: getMoveColor(move) }}>
          {dayjs(move.pickup_time).format(`MMM D h:mm A`)}
        </Typography>

        <Spacer />

        <Typography className={cls.additionalTxt}>{getPropValue(move, `lane.distance_miles`) || 0} mi</Typography>

        <Typography className={cls.additionalTxt}>
          {formatUSD(getPropValue(move, `totalAPPaymentAmounts.aggregate.sum.amount`))}
        </Typography>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  move: {
    position: 'relative',
    display: 'flex',
    padding: theme.spacing(1.5),
    background: theme.palette.background.paper,
    cursor: 'pointer',
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
  },

  detailsLeft: {
    flex: 9,
    marginRight: theme.spacing(1.5),
    textAlign: 'left',
  },
  detailsRight: {
    flex: 3,
    textAlign: 'right',
  },

  icon: {
    fontSize: 32,
    marginRight: theme.spacing(1),
  },
  moveIdTxt: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
  },

  vehicleTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 500,
  },

  locationTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  timeTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 600,
  },

  additionalTxt: {
    lineHeight: 1.5,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  status: {
    width: 'fit-content',
    padding: theme.spacing(0.5),
    background: theme.palette.text.disabled,
  },
  statusTxt: {
    marginTop: 1,
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.contrast,
  },
}));
