import React, { useEffect } from 'react';

export const CapturedPhotoThumbs = ({ images, imageWidth, bottom = 15, right = 15 }) => {
  return (
    <div id='CapturedPhotoThumbs' style={{ position: 'absolute', bottom: `${bottom}px`, left: `85px` }}>
      {images.map((imageData, index) => {
        return (
          imageData.length > 10 && (
            <img
              key={index}
              src={imageData}
              width={imageWidth}
              alt='NoImage'
              style={{
                position: 'absolute',
                zIndex: 10 + index,
                bottom: `${bottom}px`,
                right: `${right - index * 80}px`,
                border: '2px solid #fff',
                borderRadius: '6px',
                //boxShadow: '0 0 5px #fff',
              }}
            />
          )
        );
      })}
    </div>
  );
};
