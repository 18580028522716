import { useTools } from './useTools';

//////////////////////////////////////// HOOK ////////////////////////////////////////

export default function useFormat() {
  const tools = useTools();

  /** Trim and optionally remove spaces from a string */
  const formatString = (str, options = {}) => {
    const { removeSpaces = false, capAll = false, capEach = false } = options;
    let newStr = str;
    if (str && str.length) {
      newStr = newStr.trim();
      if (removeSpaces) newStr = `${newStr}`.replace(/\s/g, '');
      if (capAll) newStr = tools.capAll(newStr);
      if (capEach) newStr = tools.capEach(newStr);
      return newStr;
    }
    return str;
  };

  /** Cleanse and format a monetary value from a number or string number */
  const formatUSD = (value = null, options = {}) => {
    const { returnNull = false, removeSign = false, removeCommas = false, removeCents = false } = options;

    // Check for value and return null
    if (!value && returnNull) return null;

    // Remove spaces and commas so the value can be converted to a number
    // Build the cleansed monetary string from the numeric value
    const replacedValue = `${value}`.replace(/[,]/g, ``);
    const numValue = Number(replacedValue) || 0;
    let cleansedValue = '$' + numValue.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, `$1,`);

    // Additional options
    if (removeSign) cleansedValue = cleansedValue.replace(/[$]/g, ``);
    if (removeCommas) cleansedValue = cleansedValue.replace(/[,]/g, ``);
    if (removeCents) cleansedValue = cleansedValue.split(`.`)[0];

    // Return cleansed monetary value
    return cleansedValue;
  };

  /** Cleanse and format a string number into an actual number */
  const formatNumber = (str = null, options = {}) => {
    if (str) {
      const replacedStr = `${str}`.replace(/[,?!:;`'"/_@#$%^&*+={}()<>]/g, ``);
      const numValue = Number(replacedStr) || 0;
      return numValue;
    }
    return 0;
  };

  /** Cleanse and format a phone number from a string or number */
  const formatPhone = (str = null, options = {}) => {
    const { removeInternationalCode = false } = options;

    if (str) {
      let cleansedStr = `${str}`.replace(/\D/g, '');
      let match = cleansedStr.match(/^(\d{1})?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? `+${match[1]} ` : ``;
        return [removeInternationalCode ? `` : intlCode, '(', match[2], ')\xa0', match[3], '-\u2060', match[4]].join(
          ''
        );
      }
    }
    return str;
  };

  /** Cleanse and format a string number into an actual number */
  const formatAccountNumber = (str = null, options = {}) => {
    if (str) {
      const replacedStr = `${str}`.replace(/[,?!:;`'"/_@#$%^&*+={}()<>]/g, ``);
      const numValue = replacedStr || ``;
      return numValue;
    }
    return ``;
  };

  /** Cleanse and format a social security number from a string or number */
  const formatSSN = (str = null, options = {}) => {
    const { removeDashes = false } = options;

    if (str) {
      const cleansedStr = `${str}`.replace(/\D/g, '');
      const match = cleansedStr.match(/^(\d{3})-?(\d{2})-?(\d{4})$/);
      if (match) {
        const joiner = removeDashes ? `` : `-`;
        return [match[1], match[2], match[3]].join(joiner);
      }
    }
    return str;
  };

  // Return hook logic
  return {
    formatString,
    formatUSD,
    formatNumber,
    formatPhone,
    formatAccountNumber,
    formatSSN,
  };
}
